<template>
    <Auth title="Документы" :tabs="tabs" :settings="$route.params.id">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disableFilter="true"
                    create-button-label="Создать договор"
                    @moduleGet="moduleGet"
                    @openCreateValueDrawer="openCreateAndEditDialog"
                >
                    <template v-slot:afterSearchAndFilter>
                        <el-select
                            v-model="filter.status.value"
                            :placeholder="this.filter.status.placeholder"
                            size="large"
                            class="filterSelect"
                            style="width: 180px"
                            @change="moduleGet"
                            clearable
                        >
                            <el-option
                                v-for="item in documentStatuses"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                        <TableIntegrationFilter
                            v-model="filter.integrations.value"
                            @moduleGet="moduleGet"
                            :table="true"
                        />
                        <FormSelectCompanies
                            @click="openCompaniesFilterDialog"
                            @clear="clearCompaniesFilter"
                            :companies="companiesCheckboxesActive"
                        />
                        <ResetFormButton
                            :isVisible="isResetButtonActive"
                            @resetFilter="resetFilters"
                        />
                    </template>
                </TableHeadBlock>

                <MobileTableFramework
                    v-for="(item, index) in values"
                    :key="index"
                >
                    <template v-slot:body>
                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.name"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Редактировать"
                            @action="openCreateAndEditDialog(item.id)"
                        />
                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    :data="values"
                    v-if="!mobile"
                    class="w-100 mt-15"
                >
                    <el-table-column prop="id" label="#" width="100" sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral200"
                            >#{{ formatNumber(scope.row.id) }}</span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="200"
                        prop="name"
                        label="Название"
                        sortable
                    >
                        <template v-slot="scope">
                            <div
                                class="group-orders-status-text group-orders-status-text-small mb-5"
                                :class="
                                    'group-orders-status-text-' +
                                    scope.row?.status
                                "
                            >
                                {{
                                    getInfo(scope.row?.status, documentStatuses)
                                }}
                            </div>
                            <div class="textSM textMedium neutral900 maxLines3">
                                <Truncate
                                    :input-text="
                                        scope.row.name +
                                        (scope.row.doc
                                            ? ' - ' + scope.row.doc
                                            : '')
                                    "
                                />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="date" label="Дата" width="120">
                        <template v-slot="scope">
                            <span class="textSM textRegular neutral400">{{
                                    scope.row.date
                                        ? getDateFormDB(scope.row.date, "date")
                                        : "-"
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="company_id" label="">
                        <template #header>
                            <span>Контрагенты</span>
                        </template>
                        <template v-slot="scope">
                            <span
                                class="textMedium neutral700 cutOverflow maxLines3 counterparty-row"
                            >{{
                                    scope.row.company_id
                                        ? scope.row.company?.name
                                        : "-"
                                }}</span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column prop="total" label="Сумма" width="200">
                        <template v-slot="scope">
                            <span class="textMedium primary600 cutOverflow">{{
                                    scope.row.total
                                        ? formatNumber(scope.row.total) + " руб."
                                        : "-"
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration_unit_id"
                        label="Интеграция"
                        width="190"
                    >
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <IntegrationLogo
                                    :integration-unit-id="
                                        scope.row.integration_unit_id
                                    "
                                />
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200"
                                >-</span
                                >
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="" width="180">
                        <template v-slot="scope">
                            <div>
                                <el-button
                                    size="small"
                                    @click="openDetailsDialog(scope.row.id)"
                                >Подробнее
                                </el-button>
                                <el-button
                                    size="small"
                                    @click="remove(scope.row.id)"
                                    :icon="icons.delete"
                                />
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>

        <el-dialog
        title=""
        :width="mobile ? '100%' : '80%'"
        :destroy-on-close="true"
        v-model="detailsDialog"
        @close="handleDetailsDialogClose">
            <CRMContractDetails
                @remove="remove"
                @update="moduleGet"
                @edit="openCreateAndEditDialog"
                :id="selectedContractId"
            />
        </el-dialog>

        <CRMCreateAndEditContractDialog
            v-model="createAndEditDialog"
            v-if="createAndEditDialog"
            @update="moduleGet"
            @close="handleCreateDialogClose"
            :id="selectedContractId"
        />

        <ModalWithScroll
            @update-data="handleUpdate"
            @close="() => this.blockScrollWhileModalOpened(false)"
            :companies-model="companiesCheckboxesActive"
            v-model="companiesFilterDialog"
        />
    </Auth>
</template>

<script>
import Auth from "../layouts/Auth";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import tableMixin from "@/mixins/tableMixin";
import MobileTableFramework from "@/views/components/Mobile/Table/MobileTableFramework";
import MobileTableHR from "@/views/components/Mobile/Table/MobileTableHR";
import MobileTableInfo from "@/views/components/Mobile/Table/MobileTableInfo";
import MobileTableButton from "@/views/components/Mobile/Table/MobileTableButton";
import TablePagination from "@/views/components/Table/TablePagination";
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import iconsMixin from "@/mixins/iconsMixin";
import CRMTabs from "@/mixins/tabs/CRMTabs";
import documentStatusMixin from "@/mixins/documentStatusMixin";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import Truncate from "@/views/components/Truncate.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";
import ModalWithScroll from "@/views/components/ModalWithScroll";
import ResetFormButton from "@/views/components/Form/ResetFormButton";
import FormSelectCompanies from "@/views/components/Form/FormSelectCompanies";
import CRMCreateAndEditContractDialog from "@/views/crm/components/CRMCreateAndEditContractDialog.vue";
import CRMContractDetails from "./components/CRMContractDetails.vue";

export default {
    name: "CRMContracts",
    data() {
        return {
            loading: true,
            loadingButton: false,
            createAndEditDialog: false,
            detailsDialog: false,
            valueLength: 0,
            filtered: [],
            filter: {
                companies: {
                    placeholder: "Контрагент",
                    value: null,
                },
                type: {
                    placeholder: "Тип документа",
                    value: null,
                },
                status: {
                    placeholder: "Статус",
                    value: null,
                },
                integrations: {
                    placeholder: "Интеграция",
                    value: null,
                },
            },
            search: "",
            full_price: 0,
            companiesFilterDialog: false,
            selectedContractId: null,
            companiesCheckboxesActive: [], // все выбранные контрагенты
            users: null,
        };
    },
    components: {
        Truncate,
        IntegrationLogo,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        Auth,
        TableIntegrationFilter,
        ModalWithScroll,
        ResetFormButton,
        FormSelectCompanies,
        CRMCreateAndEditContractDialog,
        CRMContractDetails
    },
    mixins: [
        mobileCheckMixin,
        tableMixin,
        functionsMixin,
        iconsMixin,
        CRMTabs,
        documentStatusMixin,
    ],
    inject: ["api"],
    created() {
        this.moduleGet();
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: "getCompanies",
            user: "getUser",
        }),
        isResetButtonActive() {
            return (
                this.filter.companies.value.length ||
                this.filter.type.value ||
                this.filter.status.value ||
                this.filter.integrations.value ||
                this.companiesCheckboxesActive.length ||
                !!this.search
            );
        },
    },
    methods: {
        moduleGet() {
            this.loading = true;
            this.loadingButton = true;
            httpService()
                .post(this.api.document.getAll, {
                    paginate: true,
                    perPage: this.pageSize,
                    page: this.page,
                    search: this.search,
                    companies: this.filter.companies.value,
                    status: this.filter.status.value,
                    integrationUnitId: this.filter.integrations.value,
                    type: "contract",
                })
                .then((response) => {
                    let data = response.data;
                    if (data.success) {
                        this.loading = false;
                        this.loadingButton = false;
                        this.values = data.items.data;
                        this.valueLength = data.items.total;
                    }
                })
                .catch(() => {
                    this.$message({
                        message: "Ошибка ответа от сервера",
                        showClose: true,
                        type: "error",
                    });
                    this.$router.push({name: "logout"});
                });
        },
        remove(id) {
            this.$confirm(
                "Вы действительно удалить документ?",
                "Подтвердите удаление",
                {
                    confirmButtonText: "Удалить",
                    cancelButtonText: "Отмена",
                    type: "warning",
                }
            ).then(() => {
                this.loading = true;

                httpService()
                    .post(this.api.document.remove + "/" + id)
                    .then((response) => {
                        let data = response.data;

                        if (data.success) {
                            this.detailsDialog = false;
                            this.$message({
                                message: "Документ удален",
                                showClose: true,
                                type: "success",
                            });
                            this.moduleGet();
                            this.selectedContractId = null;
                        }
                    })
                    .catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: "error",
                        });
                        this.loading = false;
                    });
                this.createAndEditDialog = false;
                this.detailsDialog = false;
            });
        },
        openDetailsDialog(id) {
            this.loadingButton = false
            this.createAndEditDialog = false
            this.selectedContractId = id
            this.detailsDialog = true
        },
        setPage(val) {
            // переключает страницу пагинации
            this.page = val;
        },
        openCreateAndEditDialog() {
            this.detailsDialog = false
            this.createAndEditDialog = true;
        },
        clearCompaniesFilter() {
            this.companiesCheckboxesActive = [];
        },
        openCompaniesFilterDialog() {
            this.companiesFilterDialog = true;
            this.blockScrollWhileModalOpened(true);
        },
        handleUpdate(data) {
            this.companiesCheckboxesActive = data;
        },
        resetFilters() {
            this.filter.companies.value = null;
            this.filter.type.value = null;
            this.filter.status.value = null;
            this.filter.integrations.value = null;
            this.companiesFilterDialog = false;
            this.companiesCheckboxesActive = [];
            this.search = "";
        },
        handleCreateDialogClose() {
            this.createAndEditDialog = false
            this.selectedContractId = null
        },
        handleDetailsDialogClose() {
            this.selectedContractId = null
        }
    },
    watch: {
        companiesCheckboxesActive: {
            handler: function () {
                this.filter.companies.value = this.companiesCheckboxesActive
                    .length
                    ? this.companiesCheckboxesActive.map((el) => el.id)
                    : [];
                this.moduleGet();
            },
            deep: true,
            immediate: true,
        },
        pageSize() {
            this.moduleGet();
        },
        page() {
            this.moduleGet();
        },
        search() {
            this.moduleGet();
        },
        createAndEditDialog: {
            handler: function (val) {
                this.blockScrollWhileModalOpened(val);
            },
        },
        detailsDialog: {
            handler: function (val) {
                this.blockScrollWhileModalOpened(val);
            },
        },
        companiesFilterDialog: {
            handler: function (val) {
                this.blockScrollWhileModalOpened(val);
            },
        },
    },
};
</script>

<style scoped>
.integrationLogos svg {
    height: 20px;
    position: relative;
    top: 3px;
}

.counterparty-row {
    line-height: 1.2;
}
</style>
