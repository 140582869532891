<template>
    <div class="d-flex d-flex-end gap-8" style="width: 171px;">
        <el-tooltip :content="tooltip" placement="top" :disabled="!tooltip">
            <div v-if="order.doc" class="d-flex-end f-direction-column gap-4 w-fit textXS neutral200">
                    <span>В АСУ - <span :class="[docColor]">#{{ order.doc }}</span></span>
                <div>
                    <template v-if="order.status === 'new'">
                        <div class="group-orders-status-text group-orders-status-text-queue">
                            Очередь
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="order.status === 'loading'" class="d-flex-end">
                            <SmallLoader v-if="order.doc" class="mr-5" style="width: 20px; height: 20px"/>
                            <span class="group-orders-status-text group-orders-status-text-unconfirmed">
                                Загрузка
                            </span>
                        </div>
                        <template v-else>
                            <span class="group-orders-status-text group-orders-status-text-done">
                                Выполнена
                            </span>
                        </template>
                    </template>
                </div>
            </div>
            <div v-else class="d-flex-end f-direction-column w-fit gap-4 textXS neutral200">
                Не отправлена в АСУ
                <div class="group-orders-status-text group-orders-status-text-queue">
                    Очередь
                </div>
            </div>
        </el-tooltip>
        <el-button
            @click="() => this.$emit('printOrder', order)" v-if="!mobile && operatorPrint && order.confirm"
            class="ci-btn operatorOrderPrintButton">
            <PrinterIcon />
        </el-button>
    </div>
</template>

<script>
import SmallLoader from "@/views/components/Loaders/SmallLoader.vue";
import PrinterIcon from "@/views/components/Icons/PrinterIcon.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "OperatorTTC",
    props: ['order', 'operatorPrint'],
    components: {SmallLoader, PrinterIcon},
    mixins: [mobileCheckMixin],
    data() {
        return {}
    },
    created() {
    },
    computed: {
        tooltip() {
            let content = ''
            if(!this.order.confirm
            || this.order.status === 'done'
            || this.order.status === 'delivery') {
                return content
            }
            if(this.order.status === 'loading') {
                content = 'Ожидание завершения от АСУ'
            }
            else if(!this.order.doc) {
                content = 'Идет отправка данных'
            } else if(this.order.doc) {
                content = 'Ожидание начала загрузки'
            }
        return content
        },
        docColor() {
            const {status} = this.order;
                return status !== 'new' && status !== 'loading' ? 'success400'
                : status === 'loading' ? 'primary600' : 'neutral200'
        }
    },
}
</script>

<style scoped>

</style>
