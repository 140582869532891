<template>
     <div v-loading="loading">
        <div v-if="details">
            <div class="d-flex-full-stroke">
                <h2 class="neutral900 mr-15 mb-0 mt-0">{{ details.name }}</h2>
                <el-button style="font-size: 20px" @click="$emit('edit', details.id)" :loading="loadingButton" type="text" :icon="icons.edit"
                           size="default"/>
            </div>
            <div class="crm-contract-details__info">
                <div class='d-flex gap-4'>
                    <p class="m-0 textSM textRegular neutral300 crm-contract-details__info-title">Номер договора:</p>
                    <p class="m-0 textSM textRegular neutral700">{{ details.doc ?? '-' }}</p>
                </div>
                <div class='d-flex gap-4'>
                    <p class="m-0 textSM textRegular neutral300 crm-contract-details__info-title">Контрагент:</p>
                    <p class="m-0 textSM textRegular neutral700">{{ details.company?.name ?? 'не указан' }}</p>
                </div>
                <div class='d-flex gap-4'>
                    <p class="m-0 textSM textRegular neutral300 crm-contract-details__info-title">Статус:</p>
                    <article
                        :class="'group-orders-status-text group-orders-status-text-small' + ' group-orders-status-text-' + details?.status">
                        {{ getInfo(details?.status, documentStatuses) }}
                    </article>
                </div>
                <div class='d-flex gap-4'>
                    <p class="m-0 textSM textRegular neutral300 crm-contract-details__info-title">Поставщик:</p>
                    <p class="m-0 textSM textRegular neutral700">{{ sellerCompany ?? 'не указан' }}</p>
                </div>
                <div class='d-flex gap-4'>
                    <p class="m-0 textSM textRegular neutral300 crm-contract-details__info-title">Дата договора:</p>
                    <p class="m-0 textSM textRegular neutral700">{{ details.date ? getDateMoment(details.date, "DD.MM.yyyy") : 'не указана' }}</p>
                </div>
                <div class='d-flex gap-4'>
                    <p class="m-0 textSM textRegular neutral300 crm-contract-details__info-title">Ответственный менеджер:</p>
                    <p class="m-0 textSM textRegular neutral700">{{ details.manager?.name ?? 'не указан' }}</p>
                </div>
            </div>
            <div class="crmContractTable">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-search="true"
                    :disable-update-button="true"
                    :disable-create-button="true"
                />

                <el-tabs v-if="isTabsNeeded" v-model="tab">
                    <el-tab-pane v-if="details.document_goods.length" label="Продукция" name="document_goods" />
                    <el-tab-pane v-if="details.addresses.length" label="Объекты" name="addresses" />
                    <el-tab-pane v-if="tariffsByType.mix.length" label="Доставка" name="mix" />
                    <el-tab-pane v-if="tariffsByType.downtime.length" label="Простой" name="downtime" />
                    <el-tab-pane v-if="tariffsByType.pump.length" label="Насос" name="pump" />
                </el-tabs>

                <CRMTabsDetailsTable :data="displayData" :tab="tab" :tableLabel="tableLabel" />

                <!-- <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                /> -->
            </div>

            <div class="d-flex-full w-100 mt-30 mb-15 crm-buttons">
                <!-- <div class="textSM textMedium neutral900">
                    Итого: <span class="primary600">{{ formatNumber(full_price) }} руб.</span>
                </div> -->

                <div v-if="details.status === 'not-confirmed'">
                    <el-button @click="changeStatus('confirmed')" :icon="icons.check" type="success">Подтвердить</el-button>
                    <el-button @click="$emit('remove', details.id)" :icon="icons.delete"/>
                </div>
                <div v-else-if="details.status === 'confirmed'">
                    <el-button @click="changeStatus('archived')" :icon="icons.remove">В архив</el-button>
                    <el-button @click="changeStatus('done')" :icon="icons.check" type="success">Завершить</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import documentStatusMixin from "@/mixins/documentStatusMixin";
import {httpService} from "@/services/http.service";
import iconsMixin from "@/mixins/iconsMixin";
import tableMixin from "@/mixins/tableMixin";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import CRMTabsDetailsTable from "@/views/crm/components/CRMTabsDetailsTable.vue";

export default {
    name: "CRMContractDetails",
    components: {TableHeadBlock, CRMTabsDetailsTable},
    props: ['id'],
    mixins: [functionsMixin, documentStatusMixin, iconsMixin, tableMixin],
    data() {
        return {
            tab: 'document_goods',
            loading: false,
            loadingButton: false,
            details: {
                id: null,
                doc: null,
                name: null,
                company_id: null,
                date: null,
                integration_unit_id: null,
                goods: [],
                type: 'contract',
                status: null,
                vat_in_price: false,
                document_goods: [],
                service_tariffs: [],
                addresses: [],
            },
            full_price: 0
        }
    },
    computed: {
        isTabsNeeded() {
            const arraysToCheck = [
                this.details.document_goods,
                this.details.addresses,
                this.tariffsByType.mix,
                this.tariffsByType.downtime,
                this.tariffsByType.pump
            ];
            return arraysToCheck.filter(array => array.length > 0).length >= 2;
        },

        tableLabel() {
            const labels = {
                document_goods: 'Продукция',
                addresses: 'Объекты',
                mix: 'Тарифы на доставку',
                downtime: 'Тарифы на простой',
                pump: 'Тарифы на насос'
            };
            return labels[this.tab] || '';
        },
        sellerCompany() {
            return this.getData(this.details.seller_company_id, 'company')
        },
        tariffsByType() {
            return {
                mix: this.details.service_tariffs.filter(tariff => tariff.type === 'mix'),
                downtime: this.details.service_tariffs.filter(tariff => tariff.type === 'downtime'),
                pump: this.details.service_tariffs.filter(tariff => tariff.type === 'pump')
            }
        }
    },
    methods: {
        changeStatus(status) {
            this.loading = true
            httpService().post(this.api.document.changeStatus, {
                id: this.id,
                status: status
            }).then((response) => {
                let data = response.data
                if (data.success) {
                    this.getContract()
                    this.$message({
                        message: 'Статус обновлен',
                        showClose: true,
                        type: 'success'
                    })
                    this.$emit('update')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getContract() {
            this.loading = true
            httpService().post(this.api.document.getOne + '/' + this.id).then((response) => {
                let data = response.data
                if (data.success) {
                    this.loading = false
                    const { services } = data.document
                    this.details = {
                        ...data.document,
                        service_tariffs: services,
                        document_goods: data.document.document_goods.map((item) => ({...item, name: item.good.name}) )
                    };
                    this.tab = this.getTab()
                    this.calculateFullPriceInDetails()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        filterValues() {
            switch (this.tab) {
              case 'document_goods':
                this.filtered = this.details.document_goods;
                break;
              case 'addresses':
                this.filtered = this.details.addresses;
                break;
              default:
                this.filtered = this.tariffsByType[this.tab];
            }

            this.valueLength = this.filtered?.length ?? []
            // return this.filtered?.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
            return this.filtered
        },
        calculateFullPriceInDetails() {
            if (this.details.document_goods) {
                this.full_price = 0
                for (let i = 0; i < this.details.document_goods.length; i++) {
                    this.full_price += Math.abs(this.details.document_goods[i].full_price)
                }
            }
        },
        getTab() {
            const { document_goods, addresses } = this.details

            return document_goods.length
            ? 'document_goods' : addresses.length
            ? 'addresses' : this.tariffsByType.mix.length
            ? 'mix' : this.tariffsByType.downtime.length
            ? 'downtime' : 'pump'
        }
    },
    watch: {
        id: {
            immediate: true,
            handler() {
                if (this.id) {
                    this.getContract()
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/sass/variables";

.crmContractTable {
    &:deep(.searchNew) {
        padding-bottom: 8px;
        max-width: 220px !important;
        width: 220px !important;
    }
}.el-tab-pane {
  transition: none;
}

.crm-contract-details__info {
    margin-block: 20px 28px;
    display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px 12px;

    &-title {
        width: 130px;
    }

    span {
        line-height: 1;
        display: inline-block;
        margin-block: auto;
    }
}
</style>
