<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.85115 11.3376C4.8049 12.8911 2.48532 12.8914 2.43907 11.3376C2.48507 9.78094 4.8049 9.78045 4.85115 11.3376ZM9.99707 11.3376C9.95032 12.8918 7.63374 12.8904 7.58724 11.3376C7.63349 9.78216 9.94982 9.77972 9.99707 11.3376Z"
            fill="#505968"
        />
        <path
            d="M4.15824 8.76483C4.22329 8.76464 4.28562 8.7393 4.33162 8.69435C4.37762 8.64941 4.40355 8.5885 4.40375 8.52493V6.64774C4.40362 6.58413 4.37771 6.52316 4.33171 6.47816C4.2857 6.43315 4.22333 6.40778 4.15824 6.40759H1.80516L1.97467 5.97103C2.03315 5.82444 2.1354 5.69844 2.26813 5.60939C2.40086 5.52033 2.55794 5.47235 2.71894 5.47168H5.37903C5.48355 5.47168 5.58705 5.4918 5.68362 5.53089C5.78018 5.56999 5.86792 5.62729 5.94182 5.69952C6.01571 5.77175 6.07432 5.85751 6.1143 5.95188C6.15428 6.04625 6.17484 6.14739 6.1748 6.24953V11.3378C6.17467 11.4014 6.14877 11.4624 6.10277 11.5074C6.05676 11.5524 5.99439 11.5777 5.9293 11.5779H5.32253C5.33586 11.4986 5.34246 11.4182 5.34228 11.3378C5.22227 8.98373 1.73066 9.23438 1.96717 11.5779C1.84998 11.5977 1.72981 11.5926 1.61485 11.5628C1.49988 11.5331 1.39283 11.4795 1.301 11.4057C1.20917 11.3318 1.1347 11.2395 1.0827 11.135C1.03069 11.0305 1.00236 10.9163 0.999636 10.8001V8.76483H4.15824Z"
            fill="#505968"
        />
        <path
            d="M3.91311 8.2846H1.07852C1.08064 8.27387 1.08391 8.26339 1.08827 8.25333L1.61879 6.8877H3.91311V8.2846ZM13.3574 11.3376C13.3267 12.9041 10.9253 12.8689 10.9493 11.3056C11.0043 9.77727 13.3339 9.81367 13.3574 11.3376Z"
            fill="#505968"
        />
        <path
            d="M6.76969 5.05195L8.71725 3.64796C8.72975 3.68632 10.3141 8.45308 10.3853 8.6666C10.4278 8.67002 10.8628 8.6666 10.9036 8.66904C10.7783 8.28598 9.27302 3.77158 9.17677 3.475L13.2959 3.00448C13.4587 2.98638 13.623 3.02349 13.7611 3.10959C13.8993 3.19568 14.003 3.32555 14.0549 3.47744L14.9662 6.21848C15.0159 6.37119 15.0106 6.53594 14.9512 6.68529C14.8917 6.83465 14.7817 6.95959 14.6394 7.03932L14.1042 7.33224V8.66904H14.5512C14.6162 8.66924 14.6786 8.69457 14.7246 8.73952C14.7706 8.78447 14.7965 8.84538 14.7967 8.90894V10.858C14.7962 11.0488 14.7184 11.2316 14.5803 11.3666C14.4423 11.5015 14.2552 11.5776 14.0599 11.5782H13.8289C13.8423 11.4988 13.8489 11.4185 13.8487 11.338C13.8019 9.35064 10.9403 9.07238 10.4886 11.0021C10.4686 11.1125 10.4761 11.134 10.4566 11.0094C10.3692 10.586 10.1161 10.2125 9.7512 9.96873C9.38627 9.72492 8.93834 9.62997 8.50282 9.70412C8.0673 9.77826 7.67862 10.0156 7.41958 10.3657C7.16053 10.7157 7.05158 11.1508 7.11595 11.5782H6.62218C6.70044 11.6053 6.65719 8.75064 6.66644 8.66904H7.46221C7.45971 8.66416 7.45721 8.65683 7.45471 8.65219L6.66644 6.27613L6.51643 5.82491C6.46892 5.68651 6.46786 5.5369 6.51341 5.39787C6.55897 5.25884 6.64875 5.13765 6.76969 5.05195ZM13.6129 8.66904V7.60097L11.6673 8.66904H13.6129Z"
            fill="#505968"
        />
    </svg>
</template>

<script>
export default {
    name: "MixerCarIcon",
};
</script>

<style scoped></style>
