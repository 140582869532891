<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.3511 12.3333L8.5776 2.33333C8.321 1.88889 7.6795 1.88889 7.4229 2.33333L1.64939 12.3333C1.39279 12.7778 1.71354 13.3333 2.22674 13.3333H13.7737C14.2869 13.3333 14.6077 12.7778 14.3511 12.3333Z"
            stroke="#F74539"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M7.9668 10.667H8.03346V10.7337H7.9668V10.667Z"
            stroke="#F74539"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8 6V8.66667"
            stroke="#F74539"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: "TriangleWarningIcon",
};
</script>
