<template>
    <div v-if="loading" class="w-100 text-center pt-30 pb-30" :style="[
        { 'min-height': windowWidth < 1500 ? '160px' : '240px' }
    ]">
        <ReportLoader/>

        <h3>Загрузка данных о заказе</h3>
        <p class="textXS textRegular neutral300">Пожалуйста, подождите...</p>
    </div>
    <div v-else>
        <el-scrollbar :height="height">
            <el-timeline style="max-width: 98%; padding-left: 3px;">
                <el-timeline-item class="timeline-item"
                                  v-for="(log, index) in logs"
                                  :key="index"
                                  :icon="log.icon"
                                  :color="log.color"
                                  :timestamp="log.timestamp"
                                  placement="top"
                >

                    <MixOrderGroupEditLog
                        v-if="log.event === 'edit'" :log="log" :company-id="companyId" :tz="tz"
                    />

                    <MixOrderGroupSpecGoodAddedLog
                        v-else-if="log.event === 'specGiven'" :log="log"
                    />

                    <MixOrderGroupNewMixOrder
                        v-else-if="log.event === 'newMixOrder'" :log="log"
                    />

                    <MixOrderGroupBasicLog v-else :log="log"/>
                </el-timeline-item>
            </el-timeline>
        </el-scrollbar>
    </div>
</template>

<script>
import moment from "moment";
import iconsMixin from "@/mixins/iconsMixin";
import {httpService} from "@/services/http.service";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import functionsMixin from "@/mixins/functionsMixin";
import MixOrderGroupBasicLog
    from "@/views/components/MixOrderGroup/components/mixOrderGroupLogs/MixOrderGroupBasicLog.vue";
import MixOrderGroupSpecGoodAddedLog
    from "@/views/components/MixOrderGroup/components/mixOrderGroupLogs/MixOrderGroupSpecGoodAddedLog.vue";
import MixOrderGroupEditLog
    from "@/views/components/MixOrderGroup/components/mixOrderGroupLogs/MixOrderGroupEditLog.vue";
import MixOrderGroupNewMixOrder
    from "@/views/components/MixOrderGroup/components/mixOrderGroupLogs/MixOrderGroupNewMixOrder.vue";
export default {
    name: "MixOrderGroupLogs",
    components: {
        MixOrderGroupBasicLog,
        MixOrderGroupSpecGoodAddedLog,
        MixOrderGroupEditLog,
        MixOrderGroupNewMixOrder,
        ReportLoader
    },
    mixins: [iconsMixin, functionsMixin],
    props: ['groupId', 'tab', 'companyId', 'tz'],
    inject: ['api'],
    data() {
        return {
            logs: [],
            loading: true
        }
    },
    created() {
    },
    methods: {
        getLogs() {
            this.loading = true
            httpService().post(this.api.mixOrderGroupsLogs.index, {
                mix_order_group_id: this.groupId,
            }).then((response) => {
                this.logs = []
                let data = response.data

                if (data.success) {
                    const sortedLogs = [...data.logs].sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
                    const logs = [];

                    sortedLogs.forEach(item => {
                        let color = '#8a909a'
                        let icon = null
                        let timestamp = moment(item.created_at).format('DD.MM HH:mm');

                        if (item.author === 'algorithm') {
                            timestamp += ' - Система'
                        } else {
                            timestamp += ' - ' + item.author
                        }

                        const colors = {
                            green: '#009272',
                            red: '#f8655b',
                            yellow: '#FAAD14',
                            gray: '#8a909a',
                            blue: '#1984e6'
                        }

                        switch (item.event) {
                            case 'create':
                                color = colors.gray;
                                icon = this.icons.check;
                                break;
                            case 'work':
                            case 'confirmed':
                                color = colors.green;
                                icon = this.icons.check;
                                break;
                            case 'done':
                            case 'completed':
                                color = colors.green;
                                icon = this.icons.check;
                                break;
                            case 'failed':
                                color = colors.red;
                                icon = this.icons.close;
                                break;
                            case 'updated':
                                color = colors.yellow;
                                icon = this.icons.edit;
                                break;
                            case 'pause':
                                color = colors.yellow;
                                icon = this.icons.pause;
                                break;
                            case 'distribute':
                                color = colors.blue;
                                icon = this.icons.switch;
                                break;
                            case 'specGiven':
                                color = colors.blue;
                                icon = this.icons.filter;
                                break;
                            case 'newMixOrder':
                                color = colors.blue;
                                icon = this.icons.plus;
                                break;
                        }

                        let log = {
                            color: color,
                            timestamp: timestamp,
                            content: item,
                            event: item.event,
                            icon: icon
                        }

                        logs.push(log)
                    })
                    this.logs = logs;
                    this.loading = false
                }
            }).catch(() => {
                this.loading = false
            }).finally(() => {
                this.loading = false
            })
        }
    },
    watch: {
        tab() {
            if (this.tab === 'log' && this.logs.length === 0) {
                this.getLogs()
            }
        }
    }
}
</script>

<style scoped>
.timeline-item {
    &:deep(.el-timeline-item__timestamp.is-top) {
        padding-top: 4px !important;
    }
}
</style>
