export default {
    data() {
        return {
            intervalTypes: new Map([
                ['withInterval', 'Интервал на разгрузку'],
                ['withoutInterval', 'Без интервала на разгрузку'],
                ['withIntervalClient', 'Интервал от клиента'],
                ['intensity', 'Интенсивность']
            ])
        }
    }
}
