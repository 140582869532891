<template>
    <template v-if="loadingData">
        <TableDataLoader text="тарифов"/>
    </template>
    <template v-else>
        <TableHeadBlock v-model="search"
                        :loading="loading"
                        :disable-filter="true"
                        :disable-update-button="true"
                        :disable-create-button="disableCreateButton"
                        create-button-label="Прикрепить тариф"
                        @moduleGet="getData()"
                        @openCreateValueDrawer="() => createDialog = true"/>
        <el-table empty-text="Информация не найдена"
                  class="mt-15 w-100 defaultTableHeight"
                  v-loading="loadingTable"
                  @current-change="handleCurrentChange"
                  :data="displayData">
            <el-table-column prop="id"
                             label="#"
                             width="100"
                             class-name="pointer"
                             sortable>
                <template v-slot="scope">
                            <span class="textSM textMedium"
                                  :class="[{ neutral200: scope.row.id !== itemId }, { primary600: scope.row.id === itemId }]">
                                #{{ formatNumber(scope.row.id) }}
                            </span>
                </template>
            </el-table-column>
            <el-table-column prop="name"
                             label="Название"
                             class-name="pointer"
                             sortable>
                <template v-slot="scope">
                    <span class="textSM textMedium primary600 cutOverflow">{{ scope.row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="integration"
                             label="Тип"
                             class-name="pointer"
                             width="220px">
                <template v-slot="scope">
                    <template v-if="scope.row.depends_on">
                        <span
                            class="textRegular textSM neutral400">{{
                                getInfo(scope.row.depends_on, dependsTypes)
                            }}</span>
                    </template>
                    <template v-else>
                        <span class="textRegular textSM neutral200">-</span>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <TablePagination v-model="pageSize"
                         :total="valueLength"
                         :loading="loading"
                         @action="setPage"/>
    </template>
    <el-dialog
    v-model="createDialog"
    :append-to-body="true"
    title="Новый тариф"
    :width="mobile ? '100%' : '80%'"
    :destroy-on-close="true"
>
    <ServiceTariffCreateDialog
        :defaultType="type"
        @create="handleAddServiceTariff"
    />
</el-dialog>
</template>

<script>
import {httpService} from "@/services/http.service";

import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import ServiceTariffCreateDialog from "@/views/settings/serviceTariffs/ServiceTariffCreateDialog";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import serviceInfo from "@/mixins/serviceInfo";

export default {
    name: 'SelectTariffModal',
    props: ['modelValue', 'type', 'addedTariffs', 'items', 'disableCreateButton'],
    components: {
        TableDataLoader, TableHeadBlock, TablePagination, ServiceTariffCreateDialog
    },
    mixins: [tableMixin, functionsMixin, serviceInfo],
    inject: ['api'],
    data() {
        return {
            loadingData: false,
            createDialog: false,
            search: '',
            loading: false,
            loadingTable: false,
            pageSize: 10,
            itemId: null,
        };
    },
    methods: {
        getData() {
            this.loadingTable = true
            httpService().post(this.api.serviceTariff.getAll, {
                paginate: false,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                type: this.type,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.loadingButton = false

                    this.values = data.items;
                    this.valueLength = data.items.length

                    if (this.addedTariffs && this.addedTariffs?.length) {
                        // исключаем выбранные варинты
                        const filtered = data.items.filter(item =>
                            !this.addedTariffs.some(tariff => item.id === tariff.id)
                        );
                        this.values = filtered;
                        this.valueLength = filtered.length
                    }
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            }).finally(() => {
                this.loadingTable = false
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.itemId = val.id;
            this.$emit('select', val)
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            }

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleAddServiceTariff(output) {
            this.createDialog = false;
            this.handleCurrentChange(output);
            console.log(output)
            console.log( this.createDialog)
        }
    },
    watch: {
        pageSize() {
            if (!this.items || !this.items.length) {
                this.getData()
            }
        },
        page() {
            if (!this.items || !this.items.length) {
                this.getData()
            }
        },
        search() {
            if (!this.items || !this.items.length) {
                this.getData()
            }
        },
        items: {
            handler(val) {
                if (val && val.length) {
                    this.values = val;
                    this.valueLength = val.length
                }
            },
            deep: true,
            immediate: true
        },
        values(val) {
            this.$emit('update:items', val)
        }
    },
    created() {
        if (!this.items || !this.items.length) {
            this.getData()
        }
    }
};
</script>

<style>
    .select-tariff-modal .el-overlay {
        background: rgba(0, 0, 0, 0) !important;
    }
</style>
