<template>
    <div class="pt-12 d-flex align-center gap-8">
        <Warning width="16" height="16" />
        <p class="m-0 textMD textMedium neutral700">{{ displayText }}</p>
    </div>
</template>

<script>
import { Warning } from "@element-plus/icons-vue";
import { mapState } from "pinia";
import { useCommonStore } from "@/store/common";

export default {
    name: "IntegrationWarningBlock",
    components: { Warning },
    props: ["integrationUnitId", "valueType"],
    computed: {
        ...mapState(useCommonStore, {
            integrations: "getIntegrations",
        }),
        displayText() {
            const integration = this.findIntegration();
            return `${this.getValueName()} ${this.getIntegrationName(
                integration
            )}`;
        },
    },
    methods: {
        findIntegration() {
            return (
                this.integrations.find(
                    (integration) => integration.id === this.integrationUnitId
                ) || {}
            );
        },
        getValueName() {
            const valueTypes = {
                mix_good: "Показаны только рецепты",
                mix: "Показаны только рецепты",
                good: "Показана только продукция",
                spec_good: "Показаны только спецификации",
                company: "Показаны только контрагенты",
            };
            return valueTypes[this.valueType] || "";
        },
        getIntegrationName(integration) {
            const integrationKeys = {
                SmartMix: `для ${integration.account_module?.name || ""}`,
                "1C": "из 1C",
            };
            return integrationKeys[integration.integration?.key] || "";
        },
    },
};
</script>
