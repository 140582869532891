<template>
    <div>
        <div class="textMedium textSM neutral900 mb-10">К заказу прикреплена спецификация</div>
        <div>
            <div class="neutral900 textRegular textXS mb-5">
                <span class="neutral300 mr-5">Спецификация: "{{ specGoodName }}"</span>
            </div>
        </div>
    </div>
</template>

<script>

import MixOrderInfo from "@/mixins/mixOrderInfo";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "MixOrderGroupSpecGoodAddedLog",
    props: ['log'],
    mixins: [MixOrderInfo, functionsMixin],
    computed: {
        specGoodName() {
            return JSON.parse(this.log.content.info).goodName || '';
        }
    }
}
</script>

<style scoped>

</style>
