<template>
    <article :class="['label', color ? `label_color_${color}` : '', { 'label_rounded': isRounded }]">
        <component class="label__icon" :is="icon"></component>
        <p v-if="text" class="label__text text-center">{{ text }}</p>
    </article>
</template>

<script>
export default {
    name: "Label",
    props: ['text', 'color', 'icon', 'isRounded'],
};
</script>
