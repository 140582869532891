<template>
    <div style="width: 160px;" class="d-flex-end f-direction-column">
        <el-tooltip :content="tooltip" placement="top" :disabled="!tooltip">
            <div class="d-flex-end f-direction-column gap-4 w-fit ml-auto">
                <template v-if="order.status === 'new'">
                    <el-tooltip effect="dark" placement="top"
                                :disabled="checkActionButton(order.date)">
                        <template #content>
                            До времени начала отгрузки более 2-х часов<br/><br/>
                            Для запуска в работу свяжитесь с диспетчером<br/>или обновите
                            страницу
                        </template>
                        <div class="d-flex d-flex-end gap-8">
                            <el-button
                                class="operatorOrderButton ml-0"
                                type="primary"
                                :icon="icons.videoPlay"
                                v-if="order.confirm"
                                :disabled="!checkActionButton(order.date) || !order.confirm"
                                @click="this.$emit('openMixOrderAction', order)"
                            />
                            <el-button @click="() => this.$emit('printOrder', order)" v-if="!mobile && operatorPrint && order.confirm"
                                class="ci-btn operatorOrderPrintButton" :disabled="!order.confirm">
                                <PrinterIcon />
                            </el-button>
                        </div>
                    </el-tooltip>
                </template>
                <div class="d-flex d-flex-end gap-8" v-else>
                    <div class="d-flex f-direction-column gap-4 d-flex-end">
                        <span class="d-flex textXS neutral200 flex-end" v-if="order.doc">В АСУ - #{{ order.doc }}</span>
                        <span class="d-flex align-center textXS neutral200" v-else>В АСУ - <SmallLoader style="width: 16px; height: 16px" class="ml-10"/></span>
                            <div v-if="order.status === 'loading'" class="d-flex-end gap-4 align-center">
                                <SmallLoader v-if="order.doc" style="width: 20px; height: 20px"/>
                                <span class="group-orders-status-text group-orders-status-text-unconfirmed">
                                    Загрузка
                                </span>
                            </div>
                            <template v-else>
                                <span class="group-orders-status-text group-orders-status-text-done">
                                    Выполнена
                                </span>
                            </template>
                        </div>
                    <el-button @click="() => this.$emit('printOrder', order)" v-if="!mobile && operatorPrint && order.confirm"
                        class="ci-btn operatorOrderPrintButton">
                        <PrinterIcon />
                    </el-button>
                </div>
            </div>
        </el-tooltip>
    </div>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin'
import SmallLoader from "@/views/components/Loaders/SmallLoader.vue";
import moment from "moment";
import PrinterIcon from "@/views/components/Icons/PrinterIcon.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "OperatorMeka",
    props: ['order', 'operatorCheckTimeStart', 'operatorPrint'],
    components: {SmallLoader, PrinterIcon},
    mixins: [iconsMixin, mobileCheckMixin],
    data() {
        return {}
    },
    computed: {
        tooltip() {
            let content = ''
            if (this.order.status === 'loading') {
                content = 'Ожидание завершения от АСУ'
            }
            return content
        }
    },
    methods: {
        checkActionButton(timestamp) {
            if (!this.operatorCheckTimeStart) {
                const date = moment.unix(timestamp);
                const currentMoment = moment();

                let diffInMinutes = currentMoment.diff(date, 'minutes')
                diffInMinutes = -diffInMinutes
                return diffInMinutes < 120;
            }

            return true
        },
    }
}
</script>

<style scoped>

</style>
