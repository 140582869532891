<template>
    <svg version="1.1"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         height="16"
         x="0"
         y="0"
         viewBox="0 0 512 512"
         style=" position: relative; top: 3px; enable-background:new 0 0 512 512"
         xml:space="preserve"
         class="">
        <g>
            <circle cx="256"
                    cy="256"
                    r="235"
                    fill="#ed2f2f"
                    transform="rotate(-45 255.972 256.066)"
                    opacity="1"
                    data-original="#ed2f2f"
                    class=""></circle>
            <path fill="#ffffff"
                  fill-rule="evenodd"
                  d="M256 351.464c13.177 0 23.858 10.682 23.858 23.859S269.176 399.181 256 399.181s-23.859-10.682-23.859-23.858 10.682-23.859 23.859-23.859zm0-238.645c10.854 0 19.734 8.88 19.734 19.734v162.85c0 10.854-8.88 19.734-19.734 19.734-10.854 0-19.734-8.88-19.734-19.734v-162.85c0-10.854 8.88-19.734 19.734-19.734z"
                  opacity="1"
                  data-original="#ffffff"
                  class=""></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: "RedWarningIcon"
}
</script>
