<template>
    <div>
        <SelfGantt
            :from="orders.dateFrom"
            :to="orders.dateTo"
            :data="orders.groups"
            :timezone="orders.timezone"
        />
    </div>
</template>
<script>
import SelfGantt from "@/views/components/SelfGantt";
import {httpService} from "@/services/http.service";
import {useEchoStore} from "@/store/Echo";
import {mapStores} from "pinia";

export default {
    name: 'MixOrderGroupBigTimelineOrdersNew',
    components: {
        SelfGantt,
    },
    props: ['date'],
    inject: [
        'api'
    ],
    data() {
        return {
            orders: [],
        }
    },
    computed: {
        ...mapStores(useEchoStore),
    },
    beforeUnmount() {
        // window.Echo.leave(`laravel_database_ChannelMixOrderCreateNew`);
        // window.Echo.leave(`laravel_database_ChannelMixOrderDeletedNew`);
        // window.Echo.leave(`laravel_database_ChannelMixOrderUpdateNew`);
         window.Echo.leave(`laravel_database_ChannelDispatchOrderGroup`);
    },
    async created() {
        window.Echo.channel('laravel_database_ChannelDispatchOrderGroup')
            .listen(`.Algorithm.${this.$route.params.id}`, () => {
                this.getOrders()
            })
            .listen(`.Create.${this.$route.params.id}`, () => {
                this.getOrders()
            })
            .listen(`.Update.${this.$route.params.id}`, () => {
                this.getOrders()
            })
            .listen(`.Delete.${this.$route.params.id}`, () => {
                this.getOrders()
            });
        await this.getOrders()
    },
    methods: {
        async getOrders() {
            const {data} = await httpService().post(this.api.dispatch.orders.bigGant, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    date: this.date,
            })

            this.orders = data.orders
        }
    },
    watch: {
        'date': {
            handler: function () {
                this.getOrders()
            },
            deep: false,
            immediate: false
        },
    }
}
</script>
<style scoped>

</style>
