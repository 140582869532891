<template>
    <div class="d-flex d-flex-end gap-8 align-center" style="width: 135px" :class="[{'align-self-center': order.status === 'done' && !operatorPrint}]">
        <el-tooltip effect="dark" placement="top"
                    :disabled="checkActionButton(order.date)">
            <template #content>
                До времени начала отгрузки более 2-х часов<br/><br/>
                Для запуска в работу свяжитесь с диспетчером<br/>или обновите
                страницу
            </template>
            <el-button
                class="operatorOrderButton ml-0"
                v-if="order.confirm && (order.status === 'new' || order.status === 'loading')"
                :disabled="!checkActionButton(order.date)"
                :type="order.status === 'new' ? 'primary' : 'success'"
                :icon="order.status === 'new' ? icons.videoPlay : icons.check"
                @click="this.$emit('openMixOrderAction',order)"
            />
        </el-tooltip>
        <span
            v-if="order.status !== 'new' && order.status !== 'loading'"
            class="group-orders-status-text group-orders-status-text-done">
            Выполнена
        </span>
        <el-button @click="() => this.$emit('printOrder', order)" v-if="!mobile && operatorPrint && order.confirm"
            class="ci-btn operatorOrderPrintButton">
            <PrinterIcon />
        </el-button>
    </div>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin'
import moment from "moment/moment";
import PrinterIcon from "@/views/components/Icons/PrinterIcon.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "OperatorBaseFactory",
    props: ['order', 'operatorCheckTimeStart', 'operatorPrint'],
    components: {PrinterIcon},
    mixins: [iconsMixin, mobileCheckMixin],
    data() {
        return {}
    },
    created() {
    },
    methods: {
        checkActionButton(timestamp) {
            if (!this.operatorCheckTimeStart) {
                const date = moment.unix(timestamp);
                const currentMoment = moment();

                let diffInMinutes = currentMoment.diff(date, 'minutes')
                diffInMinutes = -diffInMinutes
                return diffInMinutes < 120;
            }

            return true
        },
    },
}
</script>
