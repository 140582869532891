<template>
    <transition-group name="event-notification-fade" tag="div">
        <EventItem
            v-for="event in events"
            :key="event.uuid"
            :event="event"
            :class="['event-notification', event.customClass]"
            :style="getPositionStyle(event)"
            role="alert"
            @mouseenter="clearTimer(event)"
            @mouseleave="startTimer(event)"
            :mix-order-groups="mixOrderGroups"
            @updateEvents="handleUpdateEvents"
        />
    </transition-group>
</template>

<script>
import EventItem from "@/views/components/Events/EventItem.vue";
import mobileCheckMixin from '@/mixins/mobileCheckMixin'

export default {
    name: 'EventNotification',
    props: ['mixOrderGroups'],
    data() {
        return {
            visible: false,
            id: `event_${Math.random().toString(36).substr(2, 10)}`,
            title: '',
            customClass: '',
            dangerouslyUseHTMLString: false,
            duration: 5000,
            position: 'top-right',
            offset: 112,
            spacing: 32,
            showClose: true,
            zIndex: 2000,
            timer: null,
            events: [],
        };
    },
    components: {EventItem},
    mixins: [mobileCheckMixin],
    computed: {
        horizontalClass() {
            return this.position.includes('right') ? 'right' : 'left';
        },
        verticalProperty() {
            return this.position.includes('top') ? 'top' : 'bottom';
        },
        positionStyle() {
            return {
                [this.verticalProperty]: `${this.offset}px`,
                zIndex: this.zIndex,
            };
        },
    },
    methods: {
        startTimer(event) {
            if (event?.duration > 0) {
                this.clearTimer(event);
                event.timer = setTimeout(() => {
                    this.close(event.id);
                }, event.duration);
            }
        },
        clearTimer(event) {
            if (event?.timer) {
                clearTimeout(event.timer);
                event.timer = null;
            }
        },
        close(id) {
            const index = this.events.findIndex((item) => item.id === id);
            if (index !== -1) {
                this.events.splice(index, 1);
            }
        },
        getPositionStyle(item) {
            const index = this.events.findIndex((n) => n.uuid === item.uuid);
            const inset = this.windowWidth < 440 ? 32 : 50;
            return {
                top: `${this.offset + index * (this.spacing + inset)}px`,
                zIndex: 2000,
            }
        },
        addNotification(event) {
            const el = {
                ...event.event,
                duration: this.duration,
                uuid: `event_${Math.random().toString(36).substr(2, 10)}`
            };

            setTimeout(() => {
                this.events.push(el);
            }, 50);

            setTimeout(() => {
                this.startTimer(el);
            }, 100)
        },
        handleUpdateEvents(mixOrderGroup, mixOrderId) {
            this.$emit('updateEvents', mixOrderGroup, mixOrderId);
        }
    },
    mounted() {
        this.startTimer();
        this.visible = true;
    },
    beforeUnmount() {
        this.clearTimer();
    },
};
</script>

<style scoped>
.event-notification {
    position: fixed;
    display: flex;
    align-items: center;
    right: 25px;
    border-radius: 4px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    opacity: 1;
    border-radius: 8px;
    overflow: hidden;
    visibility: visible;
    transform: translateY(0);
    transition: all .5s ease;;
    width: 456px;

    @media screen and (max-width: 440px) {
        width: 456px;
        max-width: calc(100vw - 24px);
        padding: 8px;
        right: 12px;
    }
}

.event-notification__group {
    display: flex;
    flex-direction: column;
}

.event-notification__close-btn {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    line-height: 1;
}


.event-notification-fade-enter {
    opacity: 0;
    transform: translateY(-10px);
}

.event-notification-fade-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

.event-notification-fade-enter-active {
    transform: translateY(-10px);
    opacity: 0;
}

.event-notification-fade-leave-active {
    opacity: 0;
    transform: translateY(10px);
}
</style>
