<template>
    <div class="event-modal-tabs">
        <!-- <article class="event-modal-tabs__radio">
            <el-radio-group class="event-modal-tabs__radio-group" v-model="selectedTab">
                <el-radio-button class="event-modal-tabs__radio-button textXS" label="new" :disabled="!newEventsAmount">
                    Новые {{newEventsAmount ? `(+${newEventsAmount})` : ""}}
                </el-radio-button>
                <el-radio-button
                    class="event-modal-tabs__radio-button textXS"
                    label="all"
                    active>
                    Все события
                </el-radio-button>
            </el-radio-group>
            <el-tooltip :disabled="!newEventsAmount" content="Прочитать все">
                <button :disabled="!newEventsAmount" class="event-modal-tabs__read-all-btn pointer" @click="() => $emit('readAllEvents')">
                    <DoubleCheckIcon width="20" height="20" :currentColor="true"/>
                </button>
            </el-tooltip>
        </article> -->
        <article class="event-modal-tabs__button">
            <div v-if="newEventsAmount" class="event-modal-tabs__button-amount textSM bg-danger200 white">{{ windowWidth > 440 ? "+" + newEventsAmount : '' }}</div>
            <el-button class="event-modal-tabs__button-button d-flex gap-10 align-center text-500 pointer"
                :class="{'event-modal-tabs__button-button--active': dialog}"
                       @click="$emit('clickOpenDialogButton')">
                <el-icon class="el-icon--left">
                    <BellIcon class="primary600" :currentColor="true" width="24" height="24"/>
                </el-icon>
                {{ windowWidth > 1023 ? "Список событий" : null }}
                <el-icon v-if="windowWidth > 1023" class="el-icon--right">
                    <CaretDown width="24" height="24"/>
                </el-icon>
            </el-button>
        </article>
    </div>
</template>

<script>
import CloseIconSM from "@/views/components/Icons/CloseIconSM";
import BellIcon from "@/views/components/Icons/BellIcon";
import CaretDown from "@/assets/icons/CaretDown";
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
// import DoubleCheckIcon from "@/views/components/Icons/DoubleCheckIcon.vue";

export default {
    name: "EventButtons",
    components: {
        BellIcon, CaretDown,
        // DoubleCheckIcon
    },
    props: ['modelValue', 'accountEvents', 'dialog'],
    mixins: [mobileCheckMixin],
    data() {
        return {
            selectedTab: "new",
            closeIcon: CloseIconSM,
        };
    },
    created() {
    },
    computed: {
        newEventsAmount() {
            return this.accountEvents.filter((e) => e.status === "new").length;
        },
    },
    methods: {},
    watch: {
        'modelValue': {
            handler() {
                this.$emit('update:modelValue', this.selectedTab)
            }
        },
        selectedTab() {
            this.$emit('update:modelValue', this.selectedTab)
        },
        'newEventsAmount': {
            handler: function (val) {
                if (!val) {
                    this.selectedTab = 'all';
                } else this.selectedTab = 'new';
            },
            immediate: true
        }
    }
};
</script>
