<template>
<div class="serviceTariffChild">
    <el-row :gutter="10">
        <el-col :sm="24">
            <p class="textXS textRegular neutral400 mt-0 mb-5">Настройки стоимости:</p>
            <el-row :gutter="15">
                <el-col :sm="6">
                    <FormSelect
                        v-model="item.price_type"
                        label="Тип оплаты"
                        placeholder="Выберите"
                        :items="priceTypes"
                    />
                </el-col>
                <el-col :sm="6" v-if="item.price_type !== 'free'">
                    <FormNumber
                        v-model="item.price"
                        :label="item.price_type === 'by_all' ? 'Цена (за всё., без НДС)' : 'Цена (за ед., без НДС)'"
                        placeholder="0"
                        :precision="2"
                    />
                </el-col>
                <el-col :sm="6" v-if="item.price_type !== 'free'">
                    <el-form-item>
                        <template #label>
                            % НДС (<el-checkbox class="vatCheckbox" v-model="item.vat_in_price" label="Вкл. в цену" size="small" />)
                        </template>
                        <el-input
                            class="custom-input"
                            v-model="item.vat"
                            type="number"
                            placeholder="0"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="6" v-if="item.price_type !== 'free'">
                    <FormNumber
                        v-model="item.full_price"
                        label="Итоговая стоимость"
                        placeholder="0"
                        :precision="2"
                    />
                </el-col>
            </el-row>
        </el-col>
    </el-row>

    <el-row>
        <el-col :sm="24">
            <el-row :gutter="15">

                <el-col :sm="12" v-if="type === 'mix'">
                    <p class="textXS textRegular neutral400 mt-0 mb-5">Ограничения по расстоянию:</p>

                    <el-row :gutter="15">
                        <el-col :sm="12">
                            <FormNumber
                                v-model="item.distance_from"
                                label="Расстояние ОТ (км)"
                                placeholder="0"
                                :precision="2"
                            />
                        </el-col>
                        <el-col :sm="12">
                            <FormNumber
                                v-model="item.distance_to"
                                label="Расстояние ДО (км)"
                                placeholder="0"
                                :precision="2"
                            />
                        </el-col>
                    </el-row>

                </el-col>

                <el-col :sm="12" v-if="type === 'downtime'">
                    <p class="textXS textRegular neutral400 mt-0 mb-5">Ограничения по времени:</p>

                    <el-row :gutter="15">
                        <el-col :sm="12">
                            <FormNumber
                                v-model="item.minutes_min"
                                label="Время ОТ (мин)"
                                placeholder="0"
                                :precision="2"
                            />
                        </el-col>
                        <el-col :sm="12">
                            <FormNumber
                                v-model="item.minutes_max"
                                label="Время ДО (мин)"
                                placeholder="0"
                                :precision="2"
                            />
                        </el-col>
                    </el-row>

                </el-col>
                <el-col :sm="12">

                    <p class="textXS textRegular neutral400 mt-0 mb-5">Ограничения по объему:</p>

                    <el-row :gutter="15">
                        <el-col :sm="12">
                            <FormNumber
                                v-model="item.volume_min"
                                label="Объем бетона ОТ (куб.м.)"
                                placeholder="0"
                                :precision="2"
                            />
                        </el-col>
                        <el-col :sm="12">
                            <FormNumber
                                v-model="item.volume_max"
                                label="Объем бетона ДО (куб.м.)"
                                placeholder="0"
                                :precision="2"
                            />
                        </el-col>
                    </el-row>

                </el-col>
            </el-row>
        </el-col>
    </el-row>
</div>
</template>

<script>
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";

export default {
    name: "ServiceTariffChild",
    components: {FormNumber, FormSelect},
    props: ['child', 'priceTypes', 'type'],
    data () {
        return {
            item: {
                id: null,
                name: null,
                vat: 0,
                price: null,
                full_price: null,
                price_type: null,
                volume_min: null,
                volume_max: null,
                distance_from: null,
                distance_to: null,
                minutes_min: null,
                minutes_max: null,
            }
        }
    },
    created() {
        this.item = this.child
    },
    watch: {
        'item': {
            handler: function () {

                if (this.item) {
                    let vat = this.item.vat ? Math.abs(this.item.vat) : 0
                    let vat_in_price = this.item.vat_in_price ?? false
                    let price = Math.abs(this.item.price)
                    let full_price = price

                    if (vat === 0) {
                        full_price = price; // Если налог равен 0, то итоговая стоимость равна цене
                    }

                    if (vat_in_price) {
                        full_price = price; // Если налог включен в стоимость, то итоговая стоимость равна цене
                    } else {
                        full_price = price + (price * (vat / 100)); // Если налог не включен, то добавляем налог к цене
                    }

                    this.item.full_price = full_price
                }
                this.$emit('update:modelValue', this.item)
            },
            deep: true,
            immediate: true
        },
        'child': {
            handler: function () {
                this.item = this.child
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped lang="scss">
.serviceTariffChild {
    &:deep(.el-form-item) {
        margin-bottom: 12px !important;
    }
}
</style>

