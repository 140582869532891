<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.3341 3.9998C11.1573 3.9998 10.9877 4.07004 10.8627 4.19506C10.7377 4.32008 10.6674 4.48965 10.6674 4.66646V9.72646L5.14077 4.19313C5.01524 4.0676 4.84498 3.99707 4.66744 3.99707C4.48991 3.99707 4.31964 4.0676 4.19411 4.19313C4.06857 4.31867 3.99805 4.48893 3.99805 4.66646C3.99805 4.844 4.06857 5.01426 4.19411 5.1398L9.72744 10.6665H4.66744C4.49063 10.6665 4.32106 10.7367 4.19604 10.8617C4.07101 10.9868 4.00077 11.1563 4.00077 11.3331C4.00077 11.5099 4.07101 11.6795 4.19604 11.8045C4.32106 11.9296 4.49063 11.9998 4.66744 11.9998H11.3341C11.4212 11.9987 11.5073 11.9806 11.5874 11.9465C11.7503 11.8788 11.8798 11.7494 11.9474 11.5865C11.9816 11.5063 11.9997 11.4202 12.0008 11.3331V4.66646C12.0008 4.48965 11.9305 4.32008 11.8055 4.19506C11.6805 4.07004 11.5109 3.9998 11.3341 3.9998Z"
            fill="#1984E6"
        />
    </svg>
</template>

<script>
export default {
    name: "ArrowRightDownIcon",
};
</script>

<style scoped>
svg {
    &:deep(path) {
        fill: currentColor;
    }
}
</style>
