<template>
    <div :class="['mix-driver-task-timeline', { 'mix-driver-task-timeline--completed': isProcessCompleted }]">
        <p :class="['mix-driver-task-timeline__timer text-center text-500 leading-24 pb-4', isProcessCompleted ? 'danger300' : 'primary600']">
            <el-text class="textSM">{{ `${timerCaption} - ` }}</el-text>
            <CountdownTimer :status="status" :endTime="endTime" :completed="isProcessCompleted" :now="now"/>
        </p>
        <el-progress
            :percentage="percentage"
            :show-text="false"
            :color="'#1B91FD'"
        />
    </div>
</template>

<script>
import functionsMixin from "../../../../mixins/functionsMixin";
import CountdownTimer from './CountdownTimer.vue'
import moment from "moment";

export default {
    name: 'MixDriverTaskTimeline',
    props: ['order', 'endTime', 'status'],
    mixins: [functionsMixin],
    components: {CountdownTimer},
    data() {
        return {
            statuses: [
                {
                    id: 0,
                    key: 'loading',
                    name: 'Загрузка'
                },
                {
                    id: 10,
                    key: 'delivery',
                    name: 'Доставка'
                },
                {
                    id: 20,
                    key: 'object',
                    name: 'На объекте'
                },
                {
                    id: 40,
                    key: 'return',
                    name: 'Возвращается'
                },
                {
                    id: 50,
                    key: 'done',
                    name: 'Конец'
                }
            ],
            now: moment().utc(),
            currentOrder: this.order,
            isProcessCompleted: false,
        }
    },
    created() {
        this.runTimeline();
    },
    beforeUnmount: function () {
    },
    methods: {
        current() {
            return this.currentOrder.status
        },
        time(status) {
            let time = null

            switch (status) {
                case 'loading':
                    time = this.currentOrder.load_at ?? null
                    break
                case 'delivery':
                    time = this.currentOrder.arrive_at ?? null
                    break
                case 'object':
                    time = this.currentOrder.unload_at ?? null
                    break
                case 'return':
                    time = this.currentOrder.return_at ?? null
                    break
            }
            return time ? moment.parseZone(time).format('HH:mm') : null
        },
        toUTC(date) {
            return moment.tz(date, 'YYYY-MM-DD HH:mm:ss', this.order.timezone).utc();
        },
        runTimeline() {
            if (this.percentage >= 100) {
                this.isProcessCompleted = true
                clearInterval(this.counterInterval)
            } else {
                this.counterInterval = setInterval(
                    function () {
                        if (this.current(this.currentStatus.key)) {
                            this.now = moment().utc()
                        }
                    }.bind(this), 1000)
            }
        },
    },
    computed: {
        currentStatus() {
            return this.statuses.find((el) => el.key === this.order.status);
        },
        percentage() {
            let per = 0

            const dateStart = this.toUTC(this.order.start_at)
            const dateLoad = this.toUTC(this.order.load_at)
            const dateArrive = this.toUTC(this.order.arrive_at)
            const dateUnload = this.toUTC(this.order.unload_at)
            const dateReturn = this.toUTC(this.order.return_at)

            const currentLoading = moment.duration(this.now.diff(dateStart)).asSeconds();
            const loadingDuration = moment.duration(dateLoad.diff(dateStart)).asSeconds();

            const currentDelivery = moment.duration(this.now.diff(dateLoad)).asSeconds();
            const deliveryDuration = moment.duration(dateArrive.diff(dateLoad)).asSeconds();

            const currentObject = moment.duration(this.now.diff(dateArrive)).asSeconds();
            const objectDuration = moment.duration(dateUnload.diff(dateArrive)).asSeconds();

            const currentReturn = moment.duration(this.now.diff(dateUnload)).asSeconds();
            const returnDuration = moment.duration(dateReturn.diff(dateUnload)).asSeconds();

            if (this.currentStatus.key === 'new' && this.current) {
                return 0;
            } else if (this.currentStatus.key === 'loading' && this.current) {
                per = parseFloat(((currentLoading / loadingDuration) * 100).toFixed(2));
            } else if (this.currentStatus.key === 'delivery' && this.current) {
                per = parseFloat(((currentDelivery / deliveryDuration) * 100).toFixed(2));
            } else if (this.currentStatus.key === 'object' && this.current) {
                per = parseFloat(((currentObject / objectDuration) * 100).toFixed(2));
            } else if (this.currentStatus.key === 'return' && this.current) {
                per = parseFloat(((currentReturn / returnDuration) * 100).toFixed(2));
            } else {
                return 0;
            }

            return per > 100 ? 100 : per;
        },
        timerCaption() {
            switch (this.currentStatus.key) {
                case 'loading':
                    return 'Идет загрузка'
                case 'delivery':
                    return 'Идет доставка на объект'
                case 'object':
                    return 'Машина на объекте'
                case 'return':
                    return 'Возвращается на завод'
                case 'done':
                    return "Завершен"
                default:
                    return ''
            }
        },
    },
    watch: {
        'order': {
            handler: function (val) {
                this.currentOrder = val;
                this.isProcessCompleted = false;
            },
            deep: true,
            immediate: true
        },
        'percentage': {
            handler: function (val) {
                if (val >= 100) {
                    this.isProcessCompleted = true
                    clearInterval(this.counterInterval)
                }
            },
            deep: true,
            immediate: true
        },
        'status': {
            handler: function (val, prevVal) {
                if (val && prevVal && (val !== prevVal)) {
                    this.isProcessCompleted = false;
                    this.runTimeline();
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
