import {
    ArrowDown as ArrowDownIcon, ArrowLeft, ArrowRight, Back, Check, Close,
    Delete, Download,
    EditPen, Minus,
    Operation,
    Plus, Printer,
    Refresh, Remove,
    RefreshLeft,
    Search,
    Top, Switch, CopyDocument, ArrowUp, Sort, Grid, VideoPlay, InfoFilled, View, Filter, User, CirclePlus, CircleClose,
    VideoPause
} from "@element-plus/icons-vue";

export default {
    data() {
        return {
            icons: {
                arrowDown: ArrowDownIcon,
                arrowLeft: ArrowLeft,
                arrowRight: ArrowRight,
                arrowUp: ArrowUp,
                operation: Operation,
                refresh: Refresh,
                search: Search,
                plus: Plus,
                circlePlus: CirclePlus,
                circleClose: CircleClose,
                minus: Minus,
                edit: EditPen,
                delete: Delete,
                top: Top,
                back: Back,
                download: Download,
                check: Check,
                remove: Remove,
                print: Printer,
                close: Close,
                switch: Switch,
                sort: Sort,
                grid: Grid,
                copy: CopyDocument,
                videoPlay: VideoPlay,
                info: InfoFilled,
                view: View,
                filter: Filter,
                refreshLeft: RefreshLeft,
                user: User,
                pause: VideoPause
            }
        }
    }
}
