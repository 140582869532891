import { defineStore } from 'pinia'
import { httpService } from '@/services/http.service'
import { API_ROUTES } from '@/const'
import moment from "moment";

export const useMixDriverStore = defineStore('mixDriver', {
    state: () => {
        return {
            // ordersData:{
            //     mixOrders:[],
            //     actualOrder:[],
            //     mixOrdersDone:[],
            // },

            mixOrders: {},
            actualOrder: null,
            mixOrdersDone: {},
            driverCanLoadOrder: false,

            vehicles: [],

            status: null,
            loading: true,

            orders: {},
            dateFilter: moment(),
            tz: 'Europe/Moscow'
        }
    },
    getters: {
        getOrders: (state) => state.orders,

        getMixOrders:     (state) => state.mixOrders,
        getActualOrder:   (state) => state.actualOrder,
        getTZ:   (state) => state.tz,
        getMixOrdersDone: (state) => state.mixOrdersDone,

        getLoading:  (state) => state.loading,
        getStatus:   (state) => state.status,
        getVehiclesData: (state) => state.vehicles,

        getDateFilter: (state) => state.dateFilter,
        getDriverCanLoadOrder:   (state) => state.driverCanLoadOrder,
    },
    actions: {
        async getDriverInfo(force = false) {
            if (!(this.dateFilter in this.mixOrders) || force){
                this.loading = true
                window.Echo.leave(`laravel_database_ChannelMixOrderDeletedNew`);
                window.Echo.leave(`laravel_database_ChannelMixOrderUpdateNew`);

                await httpService().post(API_ROUTES.mixVehicle.getDriverInfo, {
                    date: this.dateFilter,
                }).then(({ data }) => {
                    if (data.success) {
                        this.status = data.status

                        const mixOrdersAll = data.mixOrdersAll
                        this.mixOrders = data.mixOrders
                        this.actualOrder = data.actualOrder
                        this.driverCanLoadOrder = data.settings.driverCanLoadOrder
                        this.mixOrdersDone = data.mixOrdersDone
                        this.tz = data.tz

                        if (this.status === 'free') {
                            this.getVehicles()
                        }

                        mixOrdersAll.forEach((order)=>{
                                window.Echo.channel('laravel_database_ChannelMixOrderDeletedNew')
                                    .listen(`.MixOrderDeleted.${order.id}`, () => {
                                        this.getDriverInfo(true)
                                    })

                                window.Echo.channel('laravel_database_ChannelMixOrderUpdateNew')
                                    .listen(`.MixOrderUpdate.${order.id}`, () => {
                                        this.pullOrder(order.id)
                                        this.getDriverInfo(true)
                                    });
                        })
                    }

                    this.loading = false
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
            }
        },
        getVehicles(){
            this.loading = true

            httpService().post(API_ROUTES.mixVehicle.getDriverVehicles).then(({ data }) => {

                if (data.success) {
                    this.vehicles = data.vehicles
                }

                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        pullOrder(id) {
            return httpService().get(API_ROUTES.mix.getOrderById+'/'+id).then(({ data }) => {
                if (data.success && data.order) {
                    this.pushOrder(id, data.order)
                }
            })
        },
        setDateFilter(date){
            this.dateFilter = date
        },
        pushOrder(id, order) {
            this.orders[id] = order
        },
    }
})
