<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.9001 3.77297L8.70676 1.51301C8.26676 1.27301 7.73343 1.27301 7.28676 1.51301L3.09344 3.77297C2.78678 3.93964 2.6001 4.26633 2.6001 4.62633C2.6001 4.98633 2.78678 5.31297 3.09344 5.47964L7.28676 7.73967C7.50676 7.85967 7.75343 7.91967 7.99343 7.91967C8.23343 7.91967 8.4801 7.85967 8.7001 7.73967L12.8934 5.47964C13.2001 5.31297 13.3868 4.98633 13.3868 4.62633C13.4001 4.26633 13.2068 3.93964 12.9001 3.77297Z"
            fill="#505968"
        />
        <path
            d="M6.60014 8.5266L2.70014 6.57326C2.40014 6.41992 2.05347 6.43992 1.7668 6.61326C1.48014 6.7866 1.31348 7.09326 1.31348 7.4266V11.1133C1.31348 11.7533 1.6668 12.3266 2.24013 12.6133L6.14014 14.5599C6.27347 14.6266 6.42015 14.6599 6.56682 14.6599C6.74016 14.6599 6.9135 14.6133 7.06683 14.5133C7.3535 14.3399 7.52016 14.0333 7.52016 13.6999V10.0133C7.52683 9.3866 7.1735 8.81326 6.60014 8.5266Z"
            fill="#505968"
        />
        <path
            d="M9.41354 8.5266L13.3135 6.57326C13.6135 6.41992 13.9602 6.43992 14.2469 6.61326C14.5335 6.7866 14.7002 7.09326 14.7002 7.4266V11.1133C14.7002 11.7533 14.3469 12.3266 13.7735 12.6133L9.87354 14.5599C9.7402 14.6266 9.59352 14.6599 9.44685 14.6599C9.27351 14.6599 9.10017 14.6133 8.94684 14.5133C8.66017 14.3399 8.49351 14.0333 8.49351 13.6999V10.0133C8.48684 9.3866 8.84018 8.81326 9.41354 8.5266Z"
            fill="#505968"
        />
    </svg>
</template>

<script>
export default {
    name: "BoxIcon",
};
</script>

<style scoped></style>
