<template>
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.4"
            d="M21.5865 2.6665H10.4132C5.55984 2.6665 2.6665 5.55984 2.6665 10.4132V21.5732C2.6665 26.4398 5.55984 29.3332 10.4132 29.3332H21.5732C26.4265 29.3332 29.3198 26.4398 29.3198 21.5865V10.4132C29.3332 5.55984 26.4398 2.6665 21.5865 2.6665Z"
            fill="#1984E6"
        />
        <path
            d="M21 13H11C10.4533 13 10 12.5467 10 12C10 11.4533 10.4533 11 11 11H21C21.5467 11 22 11.4533 22 12C22 12.5467 21.5467 13 21 13Z"
            fill="#1984E6"
        />
        <path
            d="M21 21H11C10.4533 21 10 20.5467 10 20C10 19.4533 10.4533 19 11 19H21C21.5467 19 22 19.4533 22 20C22 20.5467 21.5467 21 21 21Z"
            fill="#1984E6"
        />
    </svg>
</template>

<script>
export default {
    name: "SequenceIcon",
};
</script>

<style scoped></style>
