<template>
    <EventsEmptyOrLoading v-if="loading || !accountEvents.length" :loading="loading"/>
    <ul v-else class="event-list">
        <li v-show="newEvents.length > 0" class="event-list__item event-list__item--new pt-8 pb-8 pointer"
            v-for="(event, index) in newEvents"
            :key="index">
            <EventItem :event="event" @updateEvents="handleUpdateEvents" :mix-order-groups="mixOrderGroups"/>
        </li>
        <li v-show="doneEvents.length > 0 && selectedTab === 'all'" class="event-list__item pt-8 pb-8"
            v-for="(event, index) in doneEvents" :key="index">
            <EventItem :event="event"/>
        </li>
    </ul>
</template>

<script>
import EventItem from "@/views/components/Events/EventItem.vue";
import EventsEmptyOrLoading from "@/views/components/Events/EventsEmptyOrLoading.vue";
import moment from 'moment';

export default {
    name: "EventList",
    components: {EventItem, EventsEmptyOrLoading},
    props: ['selectedTab', 'accountEvents', 'mixOrderGroups', 'loading'],
    inject: ['api'],
    emits: ['updateEvents', 'readAllEvents'],
    created() {
    },
    computed: {
        doneEvents() {
            const doneEvents = this.accountEvents?.filter(event => event.status === 'done');
            return this.sortEventsDescending(doneEvents);
        },
        newEvents() {
            const newEvents = this.accountEvents?.filter(event => event.status === 'new')
            return this.sortEventsDescending(newEvents);
        },
    },
    methods: {
        sortEventsDescending(events) {
            return [...events].sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf());
        },
        handleUpdateEvents(mixOrderGroup, mixOrderId) {
            this.$emit('updateEvents', mixOrderGroup, mixOrderId);
        }
    },
}
</script>
