<template>
    <div class="gantt-chart">
        <!-- Основное тело диаграммы -->
        <div class="gantt-body">
            <div v-for="(group, mixIndex) in data" :key="group.id" class="gantt-group">
                <div class="group-header">
                    <p class="group-header__name">{{ group.mix_name }} - {{group.mixer_name}}</p>
                    <div class="group-header__mix-tasks">
                        <el-tooltip content="Загрузка завода" v-for="mixTask in group.mixTasks" :key="mixTask.id">
                            <div
                                class="gantt-task-top"
                                :style="getTaskStyle(mixTask, false)"
                            >
                                {{ mixTask.total }}
                            </div>
                        </el-tooltip>
                    </div>
                </div>

                <div class="group-rows">
                    <div class="group-rows__labels">
                        <div v-for="row in group.rows" :key="row.id" class="group-rows__label">
                            <div class="group-rows__label-info">
                                <article class="group-rows__label-info-icon"
                                   :class="[{'group-rows__label-info-icon--active': row.rent}]">
                                    <el-tooltip :content="`${row.rent ? 'Арендованное' : 'Собственное'} ТС`"
                                                placement="top">
                                        <RentIcon :current-color="true"/>
                                    </el-tooltip>
                                </article>
                                <article class="group-rows__label-info-icon"
                                   :class="[{'group-rows__label-info-icon--active': row.gidrolotok}]">
                                    <el-tooltip :content="`${row.gidrolotok ? 'Есть гидролоток' : 'Нет гидролотка'}`"
                                                placement="top">
                                        <GidrolotokLetterIcon :current-color="true"/>
                                    </el-tooltip>
                                </article>
                            </div>
                            <p class="group-rows__label-text group-rows__label-text--vehicle">{{ getFormattedVehicleNumber(row.vehicle_number) }}</p>
                            <el-tooltip content="Объём авто" placement="top">
                                <p class="group-rows__label-text group-rows__label-text--volume">{{ row.volume }} м³</p>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="group-rows__rows">
                        <!-- Отображение строк для каждой группы -->
                        <div class="time-header" v-if="mixIndex === 0">
                            <div v-for="time in timeSlots" :key="time" class="time-slot">
                                <div class="tick" v-for="i in 5" :key="i"></div>
                                <p class="time-slot__value">{{ time }}</p>
                            </div>
                        </div>
                        <div class="d-flex f-direction-column">
                            <div class="current-time-indicator" :style="currentTimeStyle"></div>
                            <div v-for="row in group.rows" :key="row.id" class="gantt-row">
                                <div class="row-tasks">
                                    <!-- Добавляем временные слоты для каждой строки -->
                                    <div
                                        v-for="time in timeSlots"
                                        :key="time"
                                        class="time-cell">
                                    </div>
                                    <!-- Отображение задач для каждой строки -->
                                    <div
                                        v-for="task in row.tasks"
                                        :key="task.id"
                                        class="gantt-task" :class="[task.color !== 'gray' ? `gantt-task--${task.color}` : '', {'gantt-task--current-mix': !task.order_group_id}]"
                                        :style="getTaskStyle(task)"
                                    >
                                        <div class="d-flex gantt-task__line-container">
                                            <div class="gantt-task__line" :style="style"
                                                 v-for="(style, index) in getLineStyle(task)" :key="index"></div>
                                        </div>
                                        <div class="gantt-task__content" :style="addInnerPadding(getTaskStyle(task))">
                                            <div class="gantt-task__content-time">
                                                <el-tooltip content="Плановое время загрузки">
                                                    <span>{{ getTime(task.start) }}</span>
                                                </el-tooltip>
                                                -
                                                <el-tooltip content="Плановое время окончания">
                                                    <span>{{ getTime(task.end) }}</span>
                                                </el-tooltip>
                                            </div>
                                            <div class="gantt-task__content-vehicle">{{ task.total }} м³
                                                {{ task.company_name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment-timezone";
import functionsMixin from "./../../mixins/functionsMixin";
import GidrolotokLetterIcon from "@/views/components/Icons/GidrolotokLetterIcon.vue";
import RentIcon from "@/views/components/Icons/RentIcon.vue";

export default {
    name: "SelfGantt",
    // TODO оптимизировать from/to/timezone, объединить и сократить кол-во пропсов
    mixins: [functionsMixin],
    components: {GidrolotokLetterIcon, RentIcon},
    props: {
        from: String,
        to: String,
        data: Array,
        timezone: {
            type: String,
            default: 'UTC' // Часовой пояс по умолчанию
        }
    },
    data() {
    },
    created() {
    },
    computed: {
        timeSlots() {
            // Рассчитаем временные интервалы между «от» и «до»
            const slots = [];
            const start = moment.tz(this.from, 'YYYY-MM-DD HH:mm', this.timezone);
            const end = moment.tz(this.to, 'YYYY-MM-DD HH:mm', this.timezone);
            while (start.isBefore(end)) {
                slots.push(start.format('HH:mm'));
                start.add(1, 'hour');
            }
            return slots;
        },

        currentTimeStyle() {
            // Рассчитать положение индикатора текущего времени
            const now = moment.tz(this.timezone);
            const start = moment.tz(this.from, 'YYYY-MM-DD HH:mm', this.timezone);
            const end = moment.tz(this.to, 'YYYY-MM-DD HH:mm', this.timezone);

            const totalMinutes = end.diff(start, 'minutes');
            const currentMinutes = now.diff(start, 'minutes');

            return {left: `${(currentMinutes / totalMinutes) * 100}%`};
        },
    },
    methods: {
        getTaskStyle(task) {
            const start = moment.tz(this.from, 'YYYY-MM-DD HH:mm', this.timezone);
            const end = moment.tz(this.to, 'YYYY-MM-DD HH:mm', this.timezone);
            // Время начала и окончания задачи
            const taskStart = moment.tz(task.start, 'YYYY-MM-DD HH:mm', this.timezone);
            const taskEnd = moment.tz(task.end, 'YYYY-MM-DD HH:mm', this.timezone);
            // Всего минут в диапазоне времени на диаграмме
            const totalMinutes = end.diff(start, 'minutes');
            // Длительность задачи в минутах
            const taskDurationMinutes = taskEnd.diff(taskStart, 'minutes');
            // Смещение задачи от начала временного диапазона
            const offsetMinutes = taskStart.diff(start, 'minutes');
            // Вычисляем стиль для правильного отображения задачи

            return {
                left: `${(offsetMinutes / totalMinutes) * 100}%`,
                width: `${(taskDurationMinutes / totalMinutes) * 100}%`,
            };
        },
        addInnerPadding(task) {
            const {left} = task;
            if (left[0] === '-') {
                return {
                    'margin-left': 'auto',
                    'text-align': 'right',
                }
            }
            return '0'
        },
        getLineStyle(task) {
            const dayEnd = moment.tz(this.to, 'YYYY-MM-DD HH:mm', this.timezone)

            const start = moment.tz(task.start, 'YYYY-MM-DD HH:mm', this.timezone);
            const load = moment.tz(task.load, 'YYYY-MM-DD HH:mm', this.timezone);
            const arrive = moment.tz(task.arrive, 'YYYY-MM-DD HH:mm', this.timezone);
            const unload = moment.tz(task.unload, 'YYYY-MM-DD HH:mm', this.timezone);
            const end = moment.tz(task.end, 'YYYY-MM-DD HH:mm', this.timezone);

            if (end.isBefore(start) && !task.load) {
                return [{width: "100%"}]
            }

            // Временные отрезки по задаче
            const time = [start, load, arrive, unload, end];

            // Временная разница между каждым отрезком
            const countDiff = (start, end) => {
                // Условие, если временной отрезок переходит на след. день
                if (end.isBefore(start)) {
                    return dayEnd.diff(start, 'minutes')
                }
                return end.diff(start, 'minutes')
            }

            // Массив, в котором исключаем отрезки, переходящие на след. день
            const filter = [time[0]]

            for (const [index, item] of time.entries()) {
                const nextItem = time[index + 1]

                if (nextItem) {
                    if (nextItem.isBefore(item)) {
                        break
                    }
                    filter.push(nextItem)
                }
            }

            // общее кол-во минут
            const totalMinutes = countDiff(filter[0], filter[filter.length - 1])

            // высчитываем длину каждого отрезка
            const periodsWidth = filter.map((period, index, self) => {
                const nextPeriod = self[index + 1];

                if (nextPeriod) {
                    const taskDurationMinutes = nextPeriod.diff(period, 'minutes')
                    return {
                        width: `${(taskDurationMinutes / totalMinutes) * 100}%`,
                    };
                } else return
            })

            return periodsWidth
        },
        getTime(time) {
            return moment(time).format("HH:mm")
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/sass/_variables.scss";

.gantt-chart {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
}

.time-header {
    display: flex;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 10;
    border-bottom: 1px solid $light-border-color;
    height: 32px;
}

.time-slot {
    position: relative;
    flex: 1;
    text-align: center;
    border-right: 1px solid #efefef;
    font-size: 12px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
    height: 32px;

    &:hover {
        background-color: $neutral30;
    }

    &__value {
        color: $neutral100;
        font-size: 10px;
        margin: 0;
        width: fit-content;
        rotate: 300deg;
        cursor: context-menu;
    }
}

/* Добавляем засечки каждые 10 минут под каждым часовым интервалом */
.time-slot .tick {
    position: absolute;
    bottom: 0;
    width: 1px; /* Ширина засечки */
    height: 6px; /* Высота засечки */
    background-color: $light-border-color;
}

/* Распределяем засечки по всей ширине временной ячейки */
.time-slot .tick:nth-child(1) {
    left: 10%;
}

.time-slot .tick:nth-child(2) {
    left: 30%;
}

.time-slot .tick:nth-child(3) {
    left: 50%;
}

.time-slot .tick:nth-child(4) {
    left: 70%;
}

.time-slot .tick:nth-child(5) {
    left: 90%;
}

.gantt-body {
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid $light-border-color;
}

.gantt-group {
    display: flex;
    flex-direction: column;
}

.group-header {
    background-color: $neutral30;
    color: $neutral900;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    position: relative;
    height: 32px;
    display: grid;
    gap: 5px;
    grid-template-columns: 200px 1fr;

    &__name {
        margin-block: auto;
        padding-left: 5px;
    }

    &__mix-tasks {
        position: relative;
        overflow-x: clip;
    }
}

.group-rows {
    display: grid;
    gap: 5px;
    grid-template-columns: 200px 1fr;


    &__labels {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        justify-items: center;
        background: #fcfcfc;
    }

    &__label {
        margin-inline: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        height: 50px;

        &-info {
            display: flex;
            align-items: center;
            gap: 4px;

            &-icon {
                width: 16px;
                height: 16px;
                color: $neutral100;

                &--active {
                    color: $neutral500;
                }
            }

            &-item {
                margin: 0;
                color: $neutral400;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
            }
        }

        &-text {
            margin-block: 0;
            color: $neutral400;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;

            &--volume {
                width: 40px;
            }

            // резерв места под полный номер автомобиля
            &--vehicle {
                width: 80px;
                font-stretch: 100%;
            }
        }
    }

    &__rows {
        position: relative;
        display: flex;
        flex-direction: column;

        .gantt-row:last-child {
            border-bottom: none;
        }
    }
}

.gantt-row {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid $light-border-color;
    position: relative;
    overflow: hidden;
}

.row-label {
    width: 150px;
    padding: 0 10px;
    position: absolute;
    left: 0;
    z-index: 3;
    color: $neutral300;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.row-tasks {
    position: relative;
    flex: 1;
    display: flex;
}

.gantt-task-top {
    position: absolute;
    height: 32px;
    color: $neutral700;
    font-size: 12px;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $neutral100;
    transform: translate(0, -50%);
    top: 50%;

    &:after {
        content: '';
        width: 1px;
        height: 100%;
        background: $neutral50;
        position: absolute;
        right: -1px;
        top: 0;
    }

    &:hover {
        transition: all 0.3s ease;
        z-index: 3;
        background-color:  $neutral200;
    }
}

.gantt-task {
    position: absolute;
    height: 48px;
    border-radius: 4px;
    text-align: left;
    padding: 2px;
    z-index: 2;
    top: 1px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    font-weight: 400;
    font-size: 12px !important;
    line-height: 16px !important;
    border: 1px solid white;
    background: $neutral30;
    color: $neutral200;

    // запрет на выделение текста
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        border-color: currentColor;
        transition: all 0.3s;
    }

    &__line-container {
        transition: all 0.3s;
        background: #E8E8E8;
    }

    &__line {
        height: 4px;

        &:nth-child(odd) {
            transition: all 0.3s;
            background: #C9CCD0;
        }
    }

    &--current-mix {
        border-color: currentColor;

        &:hover {
            transition: all 0.2s;
            background: #EBEBEB;
            color: #9DA1AA;

            .gantt-task__line-container {
                background: #D6D6D6;
            }

            .gantt-task__line:nth-child(odd) {
                background: #B3B7BD;
            }
        }
    }

    cursor: default;

    &--middle-gray {
        background: $neutral50;
        color: $neutral300;
    }

    &--middle-gray &__line-container {
        background: $neutral100;
    }

    &--middle-gray &__line:nth-child(odd) {
        background: $neutral300;
    }

    &--current-mix.gantt-task--middle-gray:hover {
        background: #DFDFE2;
        color: #7C828D;

        .gantt-task__line-container {
            background: #B3B7BD;
        }

        .gantt-task__line:nth-child(odd) {
            background: #7C828D;
        }
    }

    // Темно-серая карточка
    &--dark-gray {
        background: #DEE1E3;
        color: #596273;
    }

    &--dark-gray &__line-container {
        background: #BAC0CA;
    }

    &--dark-gray &__line:nth-child(odd) {
        background: #596273;
    }

    &--current-mix.gantt-task--dark-gray:hover {
        background: #D3D6D9;
        color: $neutral600;

        .gantt-task__line-container {
            background: #AEB5C1;
        }

        .gantt-task__line:nth-child(odd) {
            background: $neutral600;
        }
    }

    // Зеленая карточка
    &--green {
        background: #d5f7f0;
        color: #007F74;
    }

    &--green &__line-container {
        background: #96ecd9;
    }

    &--green &__line:nth-child(odd) {
        background: #007F74;
    }

    &--current-mix.gantt-task--green:hover {
        background: #BAF2E7;
        color: #00665E;

        .gantt-task__line-container {
            background: #75E6CD;
        }

        .gantt-task__line:nth-child(odd) {
            background: #00665E;
        }
    }

    // Голубая карточка
    &--blue {
        background: #D1E9FF;
        color: $primary600;
    }

    &--blue &__line-container {
        background: $primary200;
    }

    &--blue &__line:nth-child(odd) {
        background: $primary600;
    }

    &--current-mix.gantt-task--blue:hover {
        background: #ADD8FF;
        color: #1673CA;

        .gantt-task__line-container {
            background: #72BAFE;
        }

        .gantt-task__line:nth-child(odd) {
            background: #1673CA;
        }
    }

    // Желтая карточка
    &--yellow {
        background: #FBEFCB;
        color: #AE641F;
    }

    &--yellow &__line-container {
        background: $warning100;
    }

    &--yellow &__line:nth-child(odd) {
        background: $warning400;
    }

    &--current-mix.gantt-task--yellow:hover {
        background: #F9E8B3;
        color: #8B5018;

        .gantt-task__line-container {
            background: #F2CB54;
        }

        .gantt-task__line:nth-child(odd) {
            background: #BD630F;
        }
    }

    // Красная карточка
    &--red {
        background: #FDC6C2;
        color: $danger400;
    }

    &--red &__line-container {
        background: $danger100;
    }

    &--red &__line:nth-child(odd) {
        background: $danger400;
    }

    &--current-mix.gantt-task--red:hover {
        background: #FCA29C;
        color: #952A23;

        .gantt-task__line-container {
            background: #F86C62;
        }

        .gantt-task__line:nth-child(odd) {
            background: #952A23;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &-time {
            font-size: 9px;
        }

        &-vehicle {
            font-size: 11px;
        }
    }
}

.current-time-indicator {
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: $primary600;
    top: 0;
    z-index: 3;
}

.time-cell {
    flex: 1;
    border-right: 1px solid $light-border-color; /* Линии между временными интервалами */
    height: 50px; /* Высота клетки */
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 1px;
        background: $light-border-color;
        top: 0;
        left: 50%;
        height: 100%;
    }
}
</style>
