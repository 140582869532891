<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke="currentColor"
            d="M6 17H4C3.44772 17 3 16.5523 3 16L3 8C3 7.44772 3.44772 7 4 7L20 7C20.5523 7 21 7.44771 21 8V16C21 16.5523 20.5523 17 20 17H18"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path stroke="currentColor"
            d="M7 21L17 21C17.5523 21 18 20.5523 18 20V16C18 15.4477 17.5523 15 17 15L7 15C6.44772 15 6 15.4477 6 16L6 20C6 20.5523 6.44772 21 7 21Z"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path stroke="currentColor" d="M10 11H14" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
        <path stroke="currentColor"
            d="M7 7L17 7C17.5523 7 18 6.55229 18 6V4C18 3.44772 17.5523 3 17 3L7 3C6.44772 3 6 3.44772 6 4L6 6C6 6.55229 6.44772 7 7 7Z"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "PrinterIcon"
}
</script>

<style scoped>

</style>
