<template>
    <div class="d-flex gap-8 align-center flex-wrap">
        <el-text v-if="factory_name" class="neutral700 factory" size="large">
            {{ factory_name }}
            </el-text>
            <el-text
              v-if="factory_name && (mix_name || mixer_name)"
              class="neutral200 factory"
              size="large"
            >
              /
            </el-text>
            <el-text v-if="mix_name" class="neutral700 factory" size="large">
              {{ mix_name }}
            </el-text>
            <el-text
              v-if="mix_name && mixer_name"
              class="neutral200 factory"
              size="large"
            >
              /
            </el-text>
            <el-text v-if="mixer_name" class="neutral700 factory" size="large">
              {{ mixer_name }}
            </el-text>
        </div>
  </template>

  <script>
  export default {
    name: "NewMixDriverTaskLoadingPlace",
    props: {
      factory_name: {
        type: String,
        default: "",
      },
      mix_name: {
        type: String,
        default: "",
      },
      mixer_name: {
        type: String,
        default: "",
      },
    },
  };
  </script>

  <style scoped>
  .factory {
    /* Add any specific styles if needed */
  }
  </style>
