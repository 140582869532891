<template>
    <div class="event-modal" ref="openButton">
        <el-dialog
            class="event-modal"
            v-model="dialog"
            modal-class="event-modal__overlay"
            :close-icon="closeIcon"
            :modal="false"
        >
            <template #header>
                <el-radio-group class="event-modal__tabs" v-model="selectedTab">
                    <el-radio-button class="event-modal__tab textXS" label="new" :disabled="!newEventsAmount">
                        Новые {{ this.newEventsAmount ? `(+${this.newEventsAmount})` : "" }}
                    </el-radio-button>
                    <el-radio-button
                        class="event-modal__tab textXS"
                        label="all"
                        active>
                        Все события
                    </el-radio-button>
                </el-radio-group>
                <el-tooltip v-if="newEventsAmount" content="Прочитать все">
                    <button class="event-modal__read-all-btn pointer" @click="() => $emit('readAllEvents')">
                        <DoubleCheckIcon width="20" height="20" :currentColor="true"/>
                    </button>
                </el-tooltip>
            </template>

            <EventList @update-events="handleUpdateEvents" :selectedTab="selectedTab"
                       :account-events="accountEvents" :mix-order-groups="mixOrderGroups" :loading="loading"/>
        </el-dialog>
    </div>
</template>

<script>
import EventList from "@/views/components/Events/EventList.vue";
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import CloseIconSM from "@/views/components/Icons/CloseIconSM";
import DoubleCheckIcon from "@/views/components/Icons/DoubleCheckIcon.vue";

export default {
    name: "EventModal",
    components: {EventList, DoubleCheckIcon},
    props: ['accountEvents', 'modelValue', 'selectedEventTab', 'mixOrderGroups', 'loading'],
    inject: ['api'],
    emits: ['update:modelValue', 'updateEvents', 'readAllEvents', 'update:selectedEventTab'],
    mixins: [mobileCheckMixin],
    data() {
        return {
            dialog: false,
            selectedTab: "new",
            closeIcon: CloseIconSM,
        }
    },
    computed: {
        newEventsAmount() {
            return this.accountEvents.filter((e) => e.status === "new").length;
        },
    },
    methods: {
        handleUpdateEvents(mixOrderGroup, mixOrderId) {
            this.$emit('updateEvents', mixOrderGroup, mixOrderId);
        }
    },
    watch: {
        modelValue() {
            this.dialog = this.modelValue;
        },
        dialog() {
            this.$emit('update:modelValue', this.dialog);
        },
        selectedEventTab() {
            this.selectedTab = this.selectedEventTab
        },
        selectedTab() {
            this.$emit('update:selectedEventTab', this.selectedTab)
        },
        'createMix': {
            handler: function () {
                if (this.createMix) this.createOrderMix()
                this.$emit('reset')
            },
            deep: true,
            immediate: true
        },
        'newEventsAmount': {
            handler: function (val) {
                if (!val) {
                    this.selectedTab = 'all';
                } else this.selectedTab = 'new';
            },
            immediate: true
        }
    },
};
</script>
