<template>
    <hr class="add-shipping__content__hr"/>
    <div>
        <el-row :gutter="16">
            <!-- Объём -->
            <el-col :sm="windowWidth  < 1900 ? 5 : 4">
                <div class="addDeliveryFormItem addDeliveryFormTotal">
                    <div>
                        <el-tooltip effect="dark" content="Объём" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <PackageGoodIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                            </div>
                        </el-tooltip>
                    </div>
                    <el-input-number
                        v-model="form.total"
                        placeholder="0"
                        :step="0.1"
                        controls-position="right"
                        @change="getTimeInfo()"
                    />
                </div>
            </el-col>

            <!-- Завод -->
            <el-col :sm="(windowWidth < 1900 || !isLab) ? 8 : 6" v-if="orderMixersAmount">
                <div class="addDeliveryFormItem pointer" @click="openSelectDialog('mix')">
                    <div>
                        <el-tooltip effect="dark" content="Завод" placement="top">
                            <div class="addDeliveryFormItemIcon" :width='20' :height='20'>
                                <BuildingIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="addDeliveryFormItemInputText">
                        <div v-if="!form.mix_id" class="neutral200">Выберите завод</div>
                        <div class="cutOverflow" v-else>
                            {{ getLabel('mix') }}
                        </div>
                    </div>
                </div>
            </el-col>

             <!-- Время загрузки -->
             <el-col :sm="windowWidth < 1900 ? 5 : 4" :style="{ minWidth: '150px', maxWidth: mobile ? '100%' : '250px' }">
                <!-- <el-row gutter="10"> -->
                    <!-- <el-col :span="orderGroupItem.delivery_type !== 'take-away' ? 24 : ''"> -->
                        <div class="addDeliveryFormItem addDeliveryFormTotal">
                            <div>
                                <el-tooltip effect="dark" content="Время загрузки" placement="top">
                                    <div class="addDeliveryFormItemIcon">
                                        <ArrowDownRightIcon class="neutral700" :currentColor="true" :width='20' :height='20' :strokeWidth="1"/>
                                    </div>
                                </el-tooltip>
                            </div>
                            <el-input
                                v-model="form.start_at"
                                @change="changedOrder('start_at')"
                                placeholder="00:00"
                                type="time"
                                :disabled="!form.total"
                            />
                        </div>
                    <!-- </el-col> -->

                    <!-- <el-col :span="2" v-if="orderGroupItem.delivery_type !== 'take-away'" v-show="false">
                        <div class="p-relative">
                            <div class="addDeliveryFormItemSeparator"></div>
                        </div>
                    </el-col> -->
                    <!-- <el-col :span="11" v-if="orderGroupItem.delivery_type !== 'take-away'" v-show="false">
                        <div class="addDeliveryFormItem addDeliveryFormTotal">
                            <div>
                                <el-tooltip effect="dark" content="Время прибытия на объект" placement="top">
                                    <div class="addDeliveryFormItemIcon">
                                        <BuildingIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                                    </div>
                                </el-tooltip>
                            </div>
                            <el-input
                                v-model="form.arrive_at"
                                @change="changedOrder('arrive_at')"
                                placeholder="00:00"
                                type="time"
                                :disabled="!form.total"
                            />
                        </div>
                    </el-col> -->
                <!-- </el-row> -->
            </el-col>

            <!-- Рецепт -->
            <el-col v-if="!isLab" :sm="(!orderMixersAmount && windowWidth < 1900) || !isLab && (!isLab && orderMixersAmount) ? 8 : 6">
                <div class="addDeliveryFormItem pointer" @click="openSelectDialog('good')">
                    <div>
                        <el-tooltip effect="dark" content="Рецепт" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <RecipeIcon class="neutral700" :currentColor="true" :width='20' :height='20' :strokeWidth="2"/>
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="addDeliveryFormItemInputText">
                        <div v-if="!form.good_id" class="neutral200">
                            Выберите рецепт
                        </div>
                        <div class="cutOverflow" v-else>
                            {{ getLabel('good') }}
                        </div>
                    </div>
                </div>
            </el-col>

            <!-- Заполняет пустое пр-во для переноса строки, если рецепта нет -->
            <el-col v-if="showHiddenBlock" :sm="!orderMixersAmount ? 5 : 4"/>

             <!-- Авто -->
             <el-col :sm="windowWidth < 1900 ? 5 : 4">
                <div class="addDeliveryFormItem pointer" @click="openSelectDialog('vehicle')">
                    <div>
                        <el-tooltip effect="dark" content="ТС" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <SolidMixerIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="addDeliveryFormItemInputText">
                        <div v-if="!form.vehicle_id" class="neutral200">
                            <span v-if="orderGroupItem.delivery_type !== 'take-away'">Выберите авто</span>
                            <span v-else>Авто</span>
                        </div>
                        <div class="cutOverflow" v-else>
                            {{ getLabel('vehicle') }}
                        </div>
                    </div>
                </div>
            </el-col>

            <!-- Водитель -->
            <el-col :sm="windowWidth < 1900 ? 8 : 6">
                <div class="addDeliveryFormItem pointer" @click="openSelectDialog('driver')">
                    <div>
                        <el-tooltip effect="dark" content="Водитель" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <SolidUserProfileIcon class="neutral700" :currentColor="true" :width='20' :height='20'/>
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="addDeliveryFormItemInputText">
                        <div v-if="!form.driver_id" class="neutral200">
                            <span v-if="orderGroupItem.delivery_type !== 'take-away'">Выберите водителя</span>
                            <span v-else>Водитель</span>
                        </div>
                        <div class="cutOverflow" v-else>
                            {{ getLabel('driver') }}
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>

    </div>

    <hr class="add-shipping__content__hr mt-0"/>

    <div class="d-flex justify-between">
        <MixPageOrderTimeline :order="order" v-show="false"/>

        <div class="add-shipping__content__order-terms__title__item"
             v-if="orderGroupItem.delivery_type === 'take-away'">
             <ArrowDownRightIcon :currentColor="true" :width='20' :height='20' :strokeWidth="1"/>
            <div class="neutral900"><span class="neutral300">Время на загрузку:</span> {{ timeInfo.loading }} мин</div>
        </div>
    </div>

    <div class="add-shipping__content__order-terms" v-if="orderGroupItem.delivery_type !== 'take-away'">
        <div class="add-shipping__content__order-terms__title">
            <div class="add-shipping__content__order-terms__title__item">
                <ArrowDownRightIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" :width='20' :height='20' :strokeWidth="1"/>
                <div class="neutral900"><span class="neutral300">Время на загрузку:</span> {{ timeInfo.loading }} мин</div>
            </div>
            <div class="add-shipping__content__order-terms__title__item" v-if="orderGroupItem.delivery_type !== 'take-away'">
                <RouteIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :strokeWidth="1" :currentColor="true" :width='20' :height='20' />
                <div class="neutral900"><span class="neutral300">До объекта:</span> {{ timeInfo.dist }} км</div>
            </div>
            <div class="add-shipping__content__order-terms__title__item">
                <ClockIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" :width='20' :height='20' />
                <div class="neutral900"><span class="neutral300">Время на дорогу:</span> {{ timeInfo.toObject }} мин</div>
            </div>
        </div>
    </div>

    <div class="add-shipping__comments">

    <hr />
            <template v-if="!orderGroupItem.info?.commentDriver && !isEditCommentFormOpened">
                <el-button
                    style="border: none; padding: 0; background-color: transparent; color: #409eff;"
                    :icon="icons.plus"
                    type="primary"
                    @click="openEditCommentForm"
                >
                    Добавить комментарий для этой отгрузки
                </el-button>
            </template>
            <template v-else>
                <div class="d-flex f-direction-column gap-5">
                    <div v-if="!isEditCommentFormOpened" class="d-flex align-center mb-5">
                        <ChatCircleDotsIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                        <p class="textSM textMedium neutral700 m-0 ml-5 mr-10">Комментарий для водителя:</p>
                        <EditPen class="primary600 pointer" width="16" height="16" @click="openEditCommentForm"/>
                    </div>
                </div>
                <div
                    v-if="!isEditCommentFormOpened"
                    v-html="getFormattedComment(orderGroupItem.info?.commentDriver)" />
            </template>

            <div v-if="isEditCommentFormOpened" class="addDeliveryFormItem editDriverComment">
                <el-tooltip effect="dark" content="Индивидуальный комментарий" placement="top">
                    <div class="addDeliveryFormItemIcon neutral700" :currentColor="true">
                        <ChatCircleDotsIcon class="neutral700" :currentColor="true" width="20" height="20"/>
                    </div>
                </el-tooltip>
                <div class="addDeliveryFormItemInputText">
                    <el-input v-model="form.mix_order_comment" placeholder="Комментарий для водителя" type="textarea" :autosize="{ minRows: 1, maxRows: 4 }" />
                </div>
            </div>
        </div>

    <el-dialog
        class="group-order-action-dialog"
        v-model="selectDialog"
        :title="selectDialogTitle"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <template v-if="selectType === 'mix'">
            <EditSelectMixes
                :items="orderMixers"
                @updateItem="updateMixes"
                @close="() => selectDialog = false"
                :is-all-mixers-is-in-order="isAllMixersIsInOrder"
                :order-mixers="orderMixers"
            />
        </template>
        <template v-else-if="selectType === 'vehicle'">
            <EditSelectVehicles
                :vehicles="schedules.map(el => el.vehicle)"
                @update="updateVehicle"
            />
        </template>
        <template v-else-if="selectType === 'driver'">
            <EditSelectDrivers
                v-model="form.driver_id"
                :items="drivers"
                @close="() => selectDialog = false"
            />
        </template>
        <template v-else-if="selectType === 'good'">
            <EditSelectGoods
                v-model="form.good_id"
                :integration-unit-id="integrationUnitId"
                type="mix"
                @update="updateGood"
                @close="() => selectDialog = false"
            />
        </template>
    </el-dialog>
</template>
<script>
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";
import iconsMixin from "@/mixins/iconsMixin";
import {httpService} from "@/services/http.service";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import EditSelectMixes from "@/views/dispatch/components/editSelects/EditSelectMixes.vue";
import EditSelectVehicles from "@/views/dispatch/components/editSelects/EditSelectVehicles.vue";
import EditSelectDrivers from "@/views/dispatch/components/editSelects/EditSelectDrivers.vue";
import MixPageOrderTimeline from "@/views/components/MixPage/OrdersGroup/MixPageOrderTimeline.vue";
import EditSelectGoods from "@/views/dispatch/components/editSelects/EditSelectGoods.vue";
import ChatCircleDotsIcon from "@/views/components/Icons/ChatCircleDotsIcon.vue";
import {EditPen} from "@element-plus/icons-vue";
import {useCommonStore} from '@/store/common';
import {mapState} from 'pinia'

// icons
import ArrowDownRightIcon from '@/views/components/Icons/ArrowDownRightIcon.vue';
import PackageGoodIcon from "@/views/components/Icons/PackageGoodIcon.vue";
import SolidMixerIcon from "@/views/components/Icons/SolidMixerIcon.vue";
import SolidUserProfileIcon from '@/views/components/Icons/SolidUserProfileIcon.vue';
import BuildingIcon from "@/views/components/Icons/BuildingIcon.vue";
import RouteIcon from "@/views/components/Icons/RouteIcon.vue";
import ClockIcon from "@/views/components/Icons/ClockIcon.vue";
import RecipeIcon from '@/views/components/Icons/RecipeIcon'

export default {
    name: "AddShippingContentDeliveryEdit",
    components: {EditSelectGoods, MixPageOrderTimeline, EditSelectDrivers, EditSelectVehicles,
        EditSelectMixes,
        ChatCircleDotsIcon,
        EditPen,
        ArrowDownRightIcon,
        PackageGoodIcon,
        SolidMixerIcon,
        SolidUserProfileIcon,
        BuildingIcon,
        RouteIcon,
        ClockIcon,
        RecipeIcon,
    },
    mixins: [functionsMixin, mobileCheckMixin, iconsMixin],
    inject: ['timezone', 'api'],
    props: {
        orderGroupItem: {
            type: [Object, null],
            default: null
        },
        total: [Number],
        mix: [Object],
        vehicle: [Object],
        vehicles: [Object],
        driver: [Object],
        mixId: [Number],
        start_at: {
            type: String,
            default: '00:00'
        },
        arrive_at: {
            type: String,
            default: '00:00'
        },
        editForm: [Object],
        mixOrder: [Object],
    },
    emits: ['setEditData'],
    data() {
        return {
            form: {
                total: null,
                mix_id: null,
                mixer_id: null,
                start_at: null,
                arrive_at: null,
                vehicle_id: null,
                driver_id: null,
                vehicle: null,
                mix_order_comment: '',
                good_id: null
            },
            timeLoading: 0,
            timeLoadingOld: 0,
            timeInfo: {
                loading: 0,
                toObject: 0,
                unloading: 0,
                return: 0,
                dist: 0
            },
            selectDialog: false,
            selectType: null,
            selectDialogTitle: null,
            schedules: [],
            isEditCommentFormOpened: false,
            integrationUnitId: null
        }
    },
    created() {
        this.setData()
        this.getVehicles()
        if (this.mixOrder) {
            this.form.mix_id = this.mixOrder.mix_id
            this.form.mixer_id = this.mixOrder.mixer_id
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            drivers: 'getDrivers',
            modules: 'getModules',
            integrations: 'getIntegrations',
        }),
        orderMixers() {
            const orderMixerIds = this.orderGroupItem.info.mixerIds;
            const mixers = this.modules.find((module) => module.key === this.$route.meta.moduleKey)?.mixers;
            const selectedMixers = mixers.filter((mixer) => orderMixerIds.includes(mixer.id));

            if (selectedMixers.length === 1) {
                this.updateMixes(selectedMixers[0])
            }

            return selectedMixers.map((mixer) => {
                return {
                    id: mixer.id, mix_id: mixer.mix_id,
                    name: this.setMixName(mixer, mixers)
                }
            })
        },
        isLab() {
            return this.modules.find((module) => module.key === this.$route.meta.moduleKey).lab.length ?? 0;
        },
        order() {
            return {
                start_at: this.convertTimeToDateTime(this.form.start_at),
                load_at: this.form.start_at ? this.convertTimeToDateTime(this.form.start_at, 'load_at') : null,
                arrive_at: this.convertTimeToDateTime(this.form.arrive_at),
                unload_at: this.form.arrive_at ? this.convertTimeToDateTime(this.form.arrive_at, 'unload_at') : null,
                return_at: this.form.arrive_at ? this.convertTimeToDateTime(this.form.arrive_at, 'return_at') : null,
                status: this.mixOrder?.status ?? 'wait',
                type: this.orderGroupItem.delivery_type,
            }
        },
        isAllMixersIsInOrder() {
            const mixers = this.modules.find((module) => module.key === this.$route.meta.moduleKey)?.mixers;
            const orderMixerIds = this.orderGroupItem.info.mixerIds;
            return mixers.every((mixer) => orderMixerIds.includes(mixer.id));
        },
        orderMixersAmount() {
            return this.orderMixers.length > 1
        },
        showHiddenBlock() {
            if(this.isLab && !this.orderMixersAmount) return false
            if(!this.isLab && this.orderMixersAmount) return false

            return this.windowWidth < 1900 ? true : false
        }
    },
    watch: {
        form: {
            handler(val) {
                this.$emit('setEditData', val)
            },
            deep: true
        },
        'form.mix_id': {
            handler: function () {
                this.getTimeInfo()
            },
            deep: true,
            immediate: true
        },
        'form.total': {
            handler: function (newTotal, oldTotal) {
                if (newTotal !== oldTotal && newTotal && oldTotal) {

                    this.timeLoading = newTotal * 2.5 * 60
                    this.timeLoadingOld = oldTotal * 2.5 * 60

                    let arrive_at

                    arrive_at = moment(this.form.arrive_at, 'HH:mm:ss').subtract(this.timeLoadingOld, 'seconds').add(this.timeLoading, 'seconds')
                    this.form.arrive_at = arrive_at.format("HH:mm:ss")
                }
            },
            deep: true,
            immediate: true
        },
        'form.mixer_id': {
            handler: function (val) {
                if(val) {
                    this.setIntegrationUnitId();
                    this.form.good_id = null;
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        updateMixes(mix){
            this.form.mix_id = mix.mix_id
            this.form.mixer_id = mix.id
        },
        updateGood(goodId){
            this.form.good_id = goodId
        },
        convertTimeToDateTime(time, type) {
            const now = moment(this.orderGroupItem.date_shipment);
            const timeMoment = moment(`${now.format('YYYY-MM-DD')}T${time}`);

            let addMinutes = 0

            if (type === 'load_at') {
                addMinutes = this.timeInfo.loading
            } else if (type === 'unload_at') {
                addMinutes = this.timeInfo.unloading
            } else if (type === 'return_at') {
                addMinutes = Math.abs(this.timeInfo.unloading) + Math.abs(this.timeInfo.return)
            } else if (type === 'arrive_at') {
                addMinutes = Math.abs(this.timeInfo.loading) + Math.abs(this.timeInfo.toObject)
            }

            return timeMoment.add(addMinutes, 'minutes').format();
        },
        setData() {
            if (this.total) this.form.total = this.total ?? 0
            if (!this.mixOrder) this.form.mix_id = this.mix?.module_reserve_id
            if (this.start_at) this.form.start_at = moment.parseZone(this.start_at).format('HH:mm')
            if (this.arrive_at) this.form.arrive_at = moment.parseZone(this.arrive_at).format('HH:mm')
            if (this.vehicle) this.form.vehicle_id = this.vehicle?.id
            if (this.driver) this.form.driver_id = this.driver?.id
            if (this.mix_order_comment) this.form.mix_order_comment = this.mix_order_comment
            if (this.mixOrder?.time_info) this.timeInfo = JSON.parse(this.mixOrder.time_info)
        },
        async getTimeInfo() {
            if (!this.form.start_at) {
                this.form.start_at = moment().tz(this.orderGroupItem.timezone).add(5,'minutes').format('HH:mm')
                this.changedOrder('start_at')
            }
            if (this.form.mix_id) {
                await httpService().post(this.api.dispatch.orderGroups.getTimeInfo, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    order_group_item_id: this.orderGroupItem.id,
                    mixId: this.form.mix_id,
                    total: this.form.total
                }).then((response) => {
                    let data = response.data
                    if (data.success) {
                        this.timeInfo = data.timeInfo
                    }
                    this.form.arrive_at = moment(this.convertTimeToDateTime(this.form.start_at, 'arrive_at')).format("HH:mm:ss")
                })
            }
        },
        getVehicles(){
            httpService().post(this.api.vehicle.getVehicleSchedulesForDay, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.orderGroupItem.date
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.schedules = data.schedules
                }
            })
        },
        getLabel(type) {
            let label = null

            if (type === 'mix') {
                label = this.orderMixers.find(el => el.id === this.form.mixer_id)?.name ?? '-'
            }

            if (type === 'vehicle') {
                if (this.form.vehicle) {
                    label = this.getFormattedVehicleNumber(this.form.vehicle?.number)
                } else {
                    if (this.form.vehicle_id) {
                        label = this.getFormattedVehicleNumber(this.vehicles.find(el => el.id === this.form.vehicle_id)?.number)
                    } else if (this.vehicle) {
                        label = this.getFormattedVehicleNumber(this.vehicle.number)
                    }
                }
            }

            if (type === 'driver') {
                label = this.drivers.find(el => el.id === this.form.driver_id)?.name
                if (label) label = this.formatName(label)
            } else if (type === 'good') {
                label = this.getData(this.form.good_id, 'good')
            }

            return label ?? '-'
        },
        async changedOrder(time_type) {
            await this.getTimeInfo()
            if (this.start_at !== this.arrive_at && this.total) {
                const timeLoading = this.total * 2.5 * 60

                let timeLoad = moment(this.start_at)
                let timeObject = moment(this.arrive_at).subtract(timeLoading, 'seconds').add(this.timeLoading, 'seconds')

                // let minutesDiffLoad = timeObject.diff(timeLoad, "minutes") + 1
                let minutesDiffLoad = Math.round(timeObject.diff(timeLoad, "minutes"))


                if (time_type === "start_at") {

                    this.form.arrive_at = moment(this.form.start_at, 'HH:mm')
                        .add(minutesDiffLoad, "minutes")
                        .format("HH:mm")
                }


                if (time_type === "arrive_at") {
                    this.form.start_at = moment(this.form.arrive_at, 'HH:mm')
                        .subtract(minutesDiffLoad, "minutes")
                        .format("HH:mm");
                }
            } else {
                if (this.form.start_at || this.form.arrive_at) {
                    const timeLoading = this.timeInfo.loading ?? 0
                    const timeDelivery = this.timeInfo.toObject ?? 0

                    const addTime = Math.round(timeLoading + timeDelivery)
                    if (time_type === "start_at") {
                        this.form.arrive_at = moment(this.form.start_at, 'HH:mm')
                            .add(addTime, "minutes")
                            .format("HH:mm")
                    }

                    if (time_type === "arrive_at") {
                        this.form.start_at = moment(this.form.arrive_at, 'HH:mm')
                            .subtract(addTime, "minutes")
                            .format("HH:mm");
                    }
                }
            }
        },
        openSelectDialog(type) {
            this.selectType = type

            if (type === 'mix') {
                this.selectDialogTitle = 'Выберете бетонный завод'
            } else if (type === 'vehicle') {
                this.selectDialogTitle = 'Выберите транспортное средство'
            } else if (type === 'driver') {
                this.selectDialogTitle = 'Выберите водителя'
            } else if (type === 'good') {
                if (this.orderGroupItem.lab === true) {
                    this.$message({
                        message: 'Для выбора рецепта по отгрузке обратитесь к лаборатории',
                        showClose: true,
                        type: 'error'
                    })
                    return
                }
                this.selectDialogTitle = 'Выберите рецепт'
            }

            this.selectDialog = true
        },
        updateVehicle(vehicle) {
            let driver_id = this.schedules.find((f) => f.vehicle_id === vehicle.id)

            this.form.vehicle = vehicle
            this.form.vehicle_id = vehicle?.id

            if (driver_id) {
                this.form.driver_id = driver_id.driver_id
            } else if(!this.form.driver_id && vehicle?.driver_id) {
                this.form.driver_id = vehicle.driver_id
            }

            this.selectDialog = false
        },
        openEditCommentForm() {
            this.isEditCommentFormOpened = true
            if(this.orderGroupItem.info?.commentDriver) {
                this.form.mix_order_comment = this.orderGroupItem.info?.commentDriver
            }
        },
        setMixName(mixer, allMixers) {
            const amountOfMixersPerMix = allMixers.filter((item) => item.mix_id === mixer.mix_id)?.length;
            return amountOfMixersPerMix > 1 ? mixer.name : mixer.mix_name;
        },
        setIntegrationUnitId() {
            const mix_id = this.orderMixers.find((mixer) => mixer.id == this.form.mixer_id).mix_id; // находим id завода
            const integration = this.integrations.find((integration) => integration.account_module?.module_reserve_id === mix_id); // находим нужную интеграцию
            if (integration) {
                this.integrationUnitId = integration.id
            }
        },
    }
}
</script>
<style lang="scss">
.content__delivery {
    &__item {

        .el-input-number {
            width: 100px;
        }

        .el-input-number__increase {
            border: none;
            border-radius: 4px 4px 2px 2px;
            background-color: #fff;
            height: 20px !important;
            width: 20px !important;
            right: 3px !important;
            border: none !important;
            border-radius: 4px 4px 0 0 !important;
        }

        .el-input-number__decrease {
            border: none;
            border-radius: 2px 2px 4px 4px;
            background-color: #fff;
            height: 20px !important;
            width: 20px !important;
            right: 3px !important;
            border: none !important;
            border-radius: 0 0 4px 4px !important;
        }

        &__input-number {
            height: 40px;
            width: 100px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #393f4a;

            .el-input {
                .el-input__wrapper {
                    height: 40px;
                    display: flex;
                    border-radius: 6px;
                    box-shadow: none;
                    padding: 0 0 0 15px !important;
                    background-color: #F7F7F8 !important;
                }
            }

            .el-input__inner {
                height: 40px;
                color: #393F4A;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                text-align: left !important;
            }
        }
    }
}

.content__delivery__item__data__select {
    .el-select__wrapper {
        padding: 0;
        border: none;
        box-shadow: none;
        align-items: flex-end;
        height: 21px !important;
        min-height: 0 !important;

        .el-select__suffix {
            display: none;
        }
    }

    .el-select__wrapper.is-hovering:not(.is-focused) {
        box-shadow: none;
    }

    .el-select__wrapper.is-focused {
        box-shadow: none;
    }

    .el-select__placeholder {
        top: unset;
        transform: translateY(-50%);
        color: #1984E6;
        text-decoration-line: underline;
    }

    .el-select__placeholder.is-transparent {
        color: #1984E6;
    }
}
</style>
<style scoped lang="scss">
.el-form-item {
    margin-bottom: 0 !important;
}

.content__delivery {
    display: flex;
    align-items: center;
    width: 100%;

    &__item {
        margin-right: 21px;
        min-width: 120px;

        &__total {
            width: 100px;
            min-width: 100px;
            height: 40px;
        }

        &__label {
            color: #2c3139;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-right: 4px;
        }

        &__data {

            height: 26px;
            display: flex;
            margin-top: 4px;
            justify-content: start;
            align-items: center;

            svg {
                margin-right: 5px;
            }
        }
    }

    &__footer {
        justify-content: flex-end;
        margin-top: 25px;
        display: flex;
    }
}
</style>
