<template>
    <el-text class="textSM">
        {{ completed ? '00:00:00' : timerFormat }}
    </el-text>
</template>

<script>
export default {
    name: "CountdownTimer",
    props: ['endTime', 'completed', 'now', 'status'],
    data() {
        return {
            intervalId: null,
            milliseconds: 0,
            timerFormat: "00:00:00",
        };
    },
    computed: {
        totalMilliseconds() {
            return this.endTime?.valueOf() ? this.endTime.diff(this.now) : 0
        },
    },
    watch: {
        completed(newVal) {
            if (newVal) {
                this.resetTimer();
            }
        },
        'status': {
            handler: function (val, prevVal) {
                if (val && prevVal && (val !== prevVal)) {
                    this.startTimer();
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        if (!this.completed) {
            this.startTimer();
        }
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
    },
    methods: {
        resetTimer() {
            clearInterval(this.intervalId);
            this.milliseconds = 0;
            this.timerFormat = "00:00:00";
        },
        getTimeFormat() {
            this.totalMilliseconds = this.endTime?.valueOf()
                ? this.endTime.diff(this.now)
                : this.milliseconds;

            const seconds = Math.floor((this.totalMilliseconds / 1000) % 60);
            const minutes = Math.floor((this.totalMilliseconds / (1000 * 60)) % 60);
            const hours = Math.floor((this.totalMilliseconds / (1000 * 60 * 60)) % 24);

            if (this.totalMilliseconds < 0) {
                this.resetTimer();
                return "00:00:00";
            } else {
                return `${hours.toString().padStart(2, "0")}:${minutes
                    .toString()
                    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
            }
        },
        startTimer() {
            if (this.completed) return;
            clearInterval(this.intervalId);
            this.milliseconds = 0;
            this.timerFormat = this.getTimeFormat();

            this.intervalId = setInterval(() => {
                this.milliseconds += 1000;
                this.timerFormat = this.getTimeFormat();
            }, 1000);
        },
    },
};
</script>
