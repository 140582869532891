<template>
    <div v-if="details">
        <el-row :gutter="20">
            <el-col :sm="8" v-if="details.type === 'delivery'">
                <MixOrderDetailsGroupMap
                    :coor-lat-from="details.mix?.latitude"
                    :coor-lon-from="details.mix?.longitude"
                    :coor-lat-to="details.address?.latitude"
                    :coor-lon-to="details.address?.longitude"
                    :vehicle="vehicle"
                    :status="details.status"
                />
            </el-col>
            <el-col :sm="details.type === 'delivery' ? 16 : 24">
                <div class="add-shipping">

                    <div class="add-shipping__header__title neutral900 w-100">
                        <div class="d-flex-full align-center">
                            <div class="cutOverflow">
                                {{
                                    (details.status === 'new' && !details.confirm) ?
                                        'Подтверждение отгрузки' :
                                        'Подробности отгрузки'
                                }}
                                #{{ formatNumber(details.id) }} -
                                {{
                                    orderGroup?.company?.name ?? orderGroup?.company
                                }}
                            </div>
                            <div>
                                <template v-if="details.status === 'new'">
                                    <div class="group-orders-status-text nowrap"
                                         :class="details.confirm ? 'group-orders-status-text-done ' : 'group-orders-status-text-unconfirmed'">
                                        {{ details.confirm ? 'Подтверждена' : 'Неподтверждена' }}
                                    </div>
                                </template>
                                <template v-else-if="details.status === 'done'">
                                    <div class="group-orders-status-text group-orders-status-text-done nowrap">
                                        Выполнена
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="group-orders-status-text group-orders-status-text-confirmed nowrap">
                                        {{ getStatusLabel(details.status) }}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="add-shipping__content">
                        <div class="add-shipping__content__header d-flex-full-stroke">
                            <div>
                                <div v-if="!details.confirm" class="add-shipping__content__header__title">
                                    Лучший вариант автоматического подбора
                                </div>
                                <div v-else class="add-shipping__content__header__title">
                                    Отгрузка подтверждена пользователем
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="d-flex" v-if="details.confirm">
                                    <el-button class="ci-btn ci-btn_default mr-10"
                                               @click="showPrint"
                                               :icon="icons.print"/>
                                    <el-tooltip
                                        v-if="details.confirm && details.status !== 'done'"
                                        raw-content
                                        effect="dark"
                                        :show-after="500"
                                        placement="top"
                                    >
                                        <template #content>
                                            <div class="text-center textXS" style="width:200px">
                                                Смените статус вручную, если отгрузка зависла по причине сбоя GPS или
                                                водитель не сменил статус
                                            </div>
                                        </template>
                                        <span>
                                        <el-button
                                            @click="() => statusChange = true"
                                            class="ci-btn ci-btn_light mr-10"
                                            :disabled="loading"
                                        >
                                            Сменить статус
                                        </el-button>
                                    </span>
                                    </el-tooltip>
                                </div>
                                <el-button @click="() => $emit('edit')"
                                           v-if="details.status === 'new'"
                                           class="ci-btn ci-btn_light"
                                           :disabled="loading"
                                >
                                    Редактировать
                                </el-button>
                            </div>
                        </div>

                        <hr class="add-shipping__content__hr">

                        <div class="content__delivery justify-between">
                            <div class="content__delivery__item">
                                <div class="content__delivery__item__data">
                                    <PackageGoodIcon :currentColor="true" />
                                    {{ details.total }} м³
                                </div>
                            </div>

                            <div class="content__delivery__item">
                                <div class="content__delivery__item__data">
                                    <ClockIcon :currentColor="true" />
                                    <span v-if="details.type === 'take-away'">{{
                                            moment.parseZone(details.start_at).format('HH:mm')
                                        }}</span>
                                    <span v-else>
                                        <el-tooltip :show-after="500" effect="dark" content="Старт загрузки на заводе"
                                                    placement="top">{{
                                                moment.parseZone(details.start_at).format('HH:mm')
                                            }}</el-tooltip>
                                        – <el-tooltip :show-after="500" effect="dark" content="Возвращение на завод"
                                                      placement="top">{{
                                            moment.parseZone(details.return_at).format('HH:mm')
                                        }}</el-tooltip>
                </span>
                                </div>
                            </div>

                            <div class="content__delivery__item">
                                <div class="content__delivery__item__data">
                                    <template
                                        v-if="!details.vehicle || !details.vehicle?.gps_vehicle_id">
                                        <VehicleIcon />
                                    </template>
                                    <template v-else>
                                        <el-tooltip effect="dark" placement="top" :show-after="150"
                                                    :disabled="details.vehicle.gps_online">
                                            <template #content>
                                                <template v-if="details.vehicle.gps_last_date">
                                                    GPS сигнал потерян<br/>
                                                    Время последнего обновления:<br/>
                                                    {{
                                                        getDateMoment(details.vehicle.gps_last_date, 'DD.MM HH:mm')
                                                    }}
                                                </template>
                                                <template v-else>
                                                    GPS сигнал не обнаружен
                                                </template>
                                            </template>
                                            <span class="ordersTableItemGPSIcon">
                                                <SignalIcon :class="{ordersTableItemGPSIconOnline: details.vehicle.gps_online}" />
                                            </span>
                                        </el-tooltip>
                                    </template>
                                    {{ details.number ? getVehicleNumber(details.number) : '-' }}
                                </div>
                            </div>
                        </div>

                        <div class="mb-15 mt-15">
                            <MixPageOrderTimelineNew :order="details"/>
                        </div>

                        <hr class="add-shipping__content__hr">


                        <el-tabs v-model="tab">
                            <el-tab-pane label="Информация" name="info">

                                <div class="add-shipping__content__order-terms__title mb-10">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <MixBoldIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true"/>
                                        <div class="neutral900"><span class="neutral300">Завод:</span>
                                            {{ details.mixer?.name }}
                                        </div>
                                    </div>
                                </div>

                                <div class="add-shipping__content__order-terms__title  mb-10"
                                     v-if="details.type === 'delivery'">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <ArrowDownRightIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" />
                                        <div class="neutral900"><span class="neutral300">Адрес доставки:</span>
                                            {{ details.address?.name ?? '-' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="add-shipping__content__order-terms__title mb-10"
                                     v-if="details.type === 'delivery'">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <UserProfileIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" strokeWidth="1.8"/>

                                        <div class="neutral900"><span class="neutral300">Водитель:</span>
                                            {{ details.driver?.name ?? '-' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="add-shipping__content__order-terms__title mb-10">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <ClockIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" strokeWidth="1.2"/>
                                        <div class="neutral900"><span class="neutral300">Время на загрузку:</span>
                                            {{ details?.info?.loading ? formatNumber(details.info.loading) : '-' }} мин
                                        </div>
                                    </div>
                                </div>
                                <div class="add-shipping__content__order-terms__title mb-10"
                                     v-if="details.type === 'delivery'">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <RouteIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" />
                                        <div class="neutral900"><span class="neutral300">До объекта:</span>
                                            {{ details?.info?.dist ?? '-' }}
                                            км
                                        </div>
                                    </div>
                                </div>

                                <div class="add-shipping__content__order-terms__title mb-10"
                                     v-if="details.type === 'delivery'">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <ClockIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" strokeWidth="1.2" />
                                        <div class="neutral900"><span class="neutral300">Время на дорогу:</span>
                                            {{ details?.info?.toObject ? formatNumber(details.info.toObject) : '-' }}
                                            мин
                                        </div>
                                    </div>
                                </div>

                                <div class="add-shipping__content__order-terms__title mb-10">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <RecipeIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" strokeWidth="1.7"/>
                                        <div class="neutral900"><span class="neutral300">Рецепт на заводе:</span>
                                            {{ details?.good?.name ?? '-' }}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="details?.mix_order_groups_comment_driver || details?.mix_order_comment"
                                    class="add-shipping__content__order-terms__title mb-10">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <el-tooltip :disabled='!details.mix_order_comment' content="Индивидуальный комментарий" position="top">
                                            <ChatCircleDotsIcon strokeWidth="1.1"
                                                :class="['add-shipping__content__order-terms__title__item__icon align-self-start',
                                                    details.mix_order_comment ? 'warning300 pointer' : 'neutral300']"
                                                :currentColor="true"/>
                                        </el-tooltip>
                                        <div class="neutral900">
                                            <p class="neutral300 m-0">Комментарий водителю:</p>
                                            <div
                                                v-html="getFormattedComment(details.mix_order_comment.length ? details.mix_order_comment : details.mix_order_groups_comment_driver)"/>
                                        </div>
                                    </div>
                                </div>

                            </el-tab-pane>
                            <el-tab-pane label="История" name="log" v-if="details.confirm">
                                <MixOrderDetailsGroupLog :mix-order-id="details?.id" :tab="tab" height="240px"/>
                            </el-tab-pane>
                        </el-tabs>

                    </div>

                    <div class="add-shipping__footer">
                        <button
                            class="ci-btn back-g-none mr-10"
                            @click="$emit('close')"
                        >
                            Закрыть
                        </button>
                        <el-button
                            v-if="!details.confirm && details.status === 'new'"
                            class="ci-btn ci-btn_blue"
                            @click="update(true)"
                            :disabled="loading"
                        >
                            Подтвердить
                        </el-button>
                        <el-button
                            v-else-if="details.confirm && details.status === 'new'"
                            class="ci-btn ci-btn_blue add-shipping__footer__cancel"
                            @click="update(false)"
                            :disabled="loading"
                        >
                            Отменить подтверждение
                        </el-button>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>

    <el-dialog
        class="group-order-action-dialog"
        v-model="statusChange"
        width="600"
        :destroy-on-close="true"
    >
        <StatusChangeModal
            :order="details"
            @get="$emit('get')"
            @loadingAction="loadingAction"
            @close="() => statusChange = false"
        />
    </el-dialog>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import {httpService} from "@/services/http.service";
import MixOrderDetailsGroupMap from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroupMap.vue";
import StatusChangeModal from "@/views/dispatch/components/StatusChangeModal.vue";
import MixPageOrderTimelineNew from "@/views/components/MixPage/OrdersGroup/MixPageOrderTimelineNew.vue";
import moment from "moment/moment";
import MixOrderDetailsGroupLog from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroupLog.vue";

// icons
import iconsMixin from "@/mixins/iconsMixin";
import ClockIcon from '@/views/components/Icons/ClockIcon.vue';
import UserProfileIcon from '@/views/components/Icons/UserProfileIcon.vue';
import ArrowDownRightIcon from '@/views/components/Icons/ArrowDownRightIcon.vue';
import RecipeIcon from '@/views/components/Icons/RecipeIcon.vue';
import RouteIcon from '@/views/components/Icons/RouteIcon.vue';
import MixBoldIcon from "@/views/components/Icons/MixBoldIcon.vue";
import PackageGoodIcon from "@/views/components/Icons/PackageGoodIcon.vue";
import SignalIcon from "@/views/components/Icons/SignalIcon.vue";
import VehicleIcon from "@/views/components/Icons/VehicleIcon.vue";
import ChatCircleDotsIcon from "@/views/components/Icons/ChatCircleDotsIcon.vue";

export default {
    name: "MixOrderDetailsGroupShow",
    computed: {
        moment() {
            return moment
        }
    },
    components: {MixOrderDetailsGroupLog, MixPageOrderTimelineNew, StatusChangeModal, MixOrderDetailsGroupMap,
        ClockIcon, PackageGoodIcon, SignalIcon, VehicleIcon,
        ArrowDownRightIcon, UserProfileIcon, RecipeIcon, RouteIcon, MixBoldIcon, ChatCircleDotsIcon},
    props: ['orderGroup', 'mixOrder', 'loading'],
    mixins: [functionsMixin, mixOrderInfo, iconsMixin],
    inject: ['api'],
    data() {
        return {
            edit: false,
            details: null,
            statusChange: false,
            tab: 'info',
            vehicle: null,
        }
    },
    created() {
        this.details = this.mixOrder
        if (this.details?.type === 'delivery') this.getMapData()
    },
    methods: {
        update(confirm) {
            this.loadingAction(true)
            // const now = moment(this.date);
            httpService().post(this.api.dispatch.orders.update + '/' + this.mixOrder.id, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order: null,
                confirm: confirm
            }).then(() => {
                this.$message({
                    message: 'Отгрузка успешно обновлена',
                    showClose: true,
                    type: 'success'
                })
                if (confirm !== null) this.details.confirm = confirm
                this.$emit('get')
                if (!confirm) this.$emit('close')
            }).catch((error) => {
                const {errors} = error.response.data

                if (errors) {
                    for (let key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            errors[key].forEach(errorMessage => {
                                this.$message({
                                    message: errorMessage,
                                    showClose: true,
                                    type: 'error'
                                });
                            });
                        }
                    }
                } else {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })

                    if (error.response.data.message === 'Не найден рецепт для данной продукции на выбранном заводе') {
                        setTimeout(() => {
                            this.$message({
                                message: 'Выберите рецепт',
                                showClose: true,
                                type: 'info'
                            })
                        }, 200)

                        setTimeout(() => {
                            this.$emit('edit')
                        }, 400)
                    }
                }
                this.loadingAction(false)
            })
        },
        getMapData() {
            httpService().post(this.api.dispatch.orderGroups.getMapData, {
                account_modules_id: this.$route.params.id,
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order_id: this.mixOrder.id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    if (data.values.length > 0) {
                        this.vehicle = data.values[0]
                    }
                    this.loadingVehicles = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingVehicles = false
            })
        },
        showPrint() {
            this.$emit('print')
        },
        loadingAction(loading) {
            this.$emit('loadingAction', loading)
        },
    },
    watch: {
        'mixOrder': {
            handler() {
                this.details = this.mixOrder
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
