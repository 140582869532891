<template>

    <div class="d-flex-full mb-15" v-if="showAddButton">
        <el-button size="small" @click="() => orderCreateDialog = true">
            Добавить отгрузку
        </el-button>
        <div class="d-flex-full">
            <el-button
                v-for="(status, index) in orderStatuses"
                :key="index"
                :disabled="getStatusCount(status.key) === 0"
                @click="() => filterByStatus(status.key)"
                size="small"
                :type="status.key === filterStatus ? 'primary' : 'default'"
            >
                {{ status.name }} <span class="ml-5" v-show="getStatusCount(status.key) > 0">({{
                    getStatusCount(status.key)
                }})</span>
            </el-button>
        </div>
    </div>
    <div class="manage-deliveries">
        <div class="manage-deliveries__filter" style="display: none">
            Скрыть завершенные доставки
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                <path d="M1 3.75L3.5 1.25L6 3.75" stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
        </div>
        <div v-if="ordersData.length" class="manage-deliveries__orders">
            <div class="el-table ordersTable">
                <table class="el-table__body w-100" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                    <tr>
                        <th style="width: 90px">
                            <div class="cell textXS textRegular neutral200">
                                #
                            </div>
                        </th>
                        <th style="width: 85px">
                            <div class="cell textXS textRegular neutral200">
                                Объем
                            </div>
                        </th>
                        <th style="width: 80px" v-if="!isTakeAway">
                            <div class="cell textXS textRegular neutral200">
                                <el-tooltip effect="dark" placement="top" content="Старт загрузки на заводе"
                                            :show-after="150">
                                    Загрузка
                                </el-tooltip>
                            </div>
                        </th>
                        <th style="width: 80px" v-if="!isTakeAway">
                            <div class="cell textXS textRegular neutral200">
                                <el-tooltip effect="dark" placement="top" content="Время прибытия на объект"
                                            :show-after="150">
                                    Доставка
                                </el-tooltip>
                            </div>
                        </th>
                        <th style="width: 80px" v-if="!isTakeAway">
                            <div class="cell textXS textRegular neutral200">
                                <el-tooltip effect="dark" placement="top" content="Время возвращения на завод"
                                            :show-after="150">
                                    Возврат
                                </el-tooltip>
                            </div>
                        </th>
                        <th style="width: 150px">
                            <div class="cell textXS textRegular neutral200">
                                ТС
                            </div>
                        </th>
                        <th>
                            <div class="cell textXS textRegular neutral200">
                                Водитель
                            </div>
                        </th>
                        <th>
                            <div class="cell textXS textRegular neutral200">
                                Завод
                            </div>
                        </th>
                        <th style="width: 115px">
                            <div class="cell textXS textRegular neutral200">

                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="order in (paginate ? displayData : ordersData)" :key="order.id">
                        <tr class="el-table__row"
                            :class="{ordersTableBorder: order.status === 'new' || order.status === 'done'}">
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem">
                                        <span class="textMedium" :class="[
                                            { warning300 : order.confirm && order.status === 'new' },
                                            { primary600 : order.confirm && (order.status !== 'new' && order.status !== 'done') },
                                            { success400 : order.status === 'done' },
                                        ]">#{{
                                                order.id
                                            }} </span>
                                            <el-tooltip
                                                v-if="isTakeAway" :show-after="150"
                                                effect="dark" placement="top" content="Самовывоз. Ручное управление отгрузками">
                                                <span class="ml-5 neutral700">(C)</span>
                                            </el-tooltip>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItem__volume"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span>{{
                                                (order.status === 'new' || order.status === 'loading') ? order.total : (order.done === 0 ? order.total : order.done)
                                            }} м³</span>
                                    </div>
                                </div>
                            </td>
                            <td  v-if="!isTakeAway">
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub ordersTableItemTime"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <div class="ordersTableItem__loading-time">
                                            <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                    dateToTime(order.start_at)
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td  v-if="!isTakeAway">
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub ordersTableItemTime"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <div class="ordersTableItem__market-time">
                                            <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                    dateToTime(order.arrive_at)
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td  v-if="!isTakeAway">
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub ordersTableItemTime"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <div class="ordersTableItem__market-time">
                                            <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                    dateToTime(order.return_at)
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem" :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span class="cutOverflow">{{
                                                order.vehicle?.number ? getVehicleNumber(order.vehicle.number) : '-'
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                order.driver?.name ? formatName(order.driver?.name) : '-'
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                setMixName(order.mix, order.mixer) ?? '-'
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem" :class="{ ordersTableItemConfirm: order.confirm }">
                                        <el-button @click="openMixOrderDetails(order)" size="small" style="height: 24px !important;">
                                            Подробнее
                                        </el-button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <template v-if="order.status !== 'new' && order.status !== 'done'">
                            <tr class="ordersTableBorder">
                                <td class="el-table__row" :colspan="isTakeAway ? 6 : 9">
                                    <div class="cell">
                                        <MixPageOrderTimelineNew :order="order"/>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    </tbody>
                </table>
            </div>

            <TablePagination
                v-if="paginate"
                v-model="pageSize"
                :total="valueLength"
                :disable-size="true"
                @action="setPage"
            />

            <div class="w-100 mt-15 mb-15" v-if="!hiddenFooter">
                <div class="textSM textRegular neutral300 mb-10">
                    Объем заказа: {{ orderGroupItem?.total ?? '-' }} м³
                </div>
                <div class="textSM textRegular neutral300 mb-10">
                    Ожидает отрузки: {{ sumWait }} м³
                </div>
                <div class="textSM textRegular neutral300 mb-10">
                    В процессе: {{ sumWork }} м³
                </div>
                <div class="textSM textMedium neutral900">
                    Отгружено: <span class="primary600">{{ sumDone }} м³</span>
                </div>
            </div>
        </div>

        <div v-else class="manage-deliveries__orders--empty">
            Нет запланированных доставок
        </div>
    </div>

    <el-dialog
        class="group-order-action-dialog"
        v-model="orderCreateDialog"
        title=""
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <MixOrderGroupAddShippingForm
            v-if="orderGroupItem.delivery_type === 'take-away'"
            :order-group-item="orderGroupItem"
            @close="() => orderCreateDialog = false"
            @get="$emit('update')"
        />

        <AddShipping
            v-else
            :order-group-item="orderGroupItem"
            @close="() => orderCreateDialog = false"
            @get="$emit('update')"
        />
    </el-dialog>

    <el-dialog
        class="group-order-action-dialog"
        v-model="orderDetailsDialog"
        title=""
        :width="dialogSize"
        :destroy-on-close="true"
        @close="unlockOrder"
    >
        <MixOrderDetailsGroup
            :mix-order-id="orderDetails?.id"
            :order-group="orderGroupItem"
            @close="closeOrderDetailsDialog"
            @get="$emit('update')"
        />
    </el-dialog>

    <el-dialog
        v-model="showMap"
        title=""
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <LogisticsInfoMapFull
            :order="currentOrder"
            :accountModuleReserveId="accountModuleReserveId"
            :color="true"
            @hideWindow="hideMap"
        />
    </el-dialog>
</template>
<script>
import moment from "moment";
import MixPageOrderTimelineNew from "@/views/components/MixPage/OrdersGroup/MixPageOrderTimelineNew.vue";
import AddShipping from "@/views/dispatch/components/AddShipping.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import LogisticsInfoMapFull from "@/views/components/LogisticsPage/Info/LogisticsInfoMapFull.vue";
import MixOrderGroupAddShippingForm from "@/views/components/MixOrderGroup/MixOrderGroupAddShippingForm.vue";
import functionsMixin from "@/mixins/functionsMixin";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import MixOrderDetailsGroup from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroup.vue";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import {httpService} from "@/services/http.service";

export default {
    name: 'ManageDeliveriesOrders',
    components: {
        TablePagination,
        MixOrderDetailsGroup,
        LogisticsInfoMapFull,
        AddShipping,
        MixPageOrderTimelineNew,
        MixOrderGroupAddShippingForm,
    },
    props: {
        orders: Array,
        simple: Boolean,
        showAddButton: Boolean,
        orderGroupItem: {
            type: [Object, null],
            default: null
        },
        paginate: Boolean,
        hiddenFooter: Boolean
    },
    mixins: [mobileCheckMixin, functionsMixin, tableMixin, mixOrderInfo],
    data() {
        return {
            intervalType: new Map([
                ['withInterval', 'Интервал на разгрузку'],
                ['withoutInterval', 'Без интервала на разгрузку'],
                ['withIntervalClient', 'Интервал от клиента'],
            ]),
            showMap: false,
            currentOrder: null,
            accountModuleReserveId: null,
            openDeleteDialog: false,
            orderDetailsDrawer: false,
            orderDetails: null,
            orderDetailsDialog: false,
            orderCreateDialog: false,
            pageSize: 5,
            pageSizes: [
                5, 10
            ],
            filterStatus: null
        }
    },
    async created() {

    },
    computed: {
        ...mapState(useCommonStore, {
            modules: 'getModules'
        }),
        ordersData() {
            if (this.orderGroupItem) {
                let data = this.orders ?? this.orderGroupItem.orders
                if (this.filterStatus) data = data.filter((item) => item.status === this.filterStatus)
                return data.sort((a, b) => {
                    if (a.confirm && !b.confirm) return -1;
                    if (!a.confirm && b.confirm) return 1;

                    if (a.status === b.status) {
                        switch (a.status) {
                            case 'new':
                            case 'loading':
                                return new Date(a.start_at) - new Date(b.start_at);
                            case 'delivery':
                                return new Date(a.arrive_at) - new Date(b.arrive_at);
                            case 'return':
                                return new Date(a.return_at) - new Date(b.return_at);
                            case 'done':
                                return new Date(a.start_at) - new Date(b.start_at);
                            default:
                                return 0;
                        }
                    }

                    const statusOrder = ['new', 'loading', 'delivery', 'pouring', 'object', 'return'];
                    const statusDiff = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
                    if (statusDiff !== 0) return statusDiff;

                    if (a.confirm && !b.confirm) {
                        return new Date(a.start_at) - new Date(b.start_at);
                    }

                    return [];
                });
            } else {
                return []
            }
        },
        sumDone() {
            return this.ordersData.filter(el => el.status === 'done')
                .reduce((total, item) => total + Math.abs(item.done), 0)
        },
        sumWait() {
            return this.ordersData.filter(el => el.status === 'new')
                .reduce((total, item) => total + Math.abs(item.total), 0)
        },
        sumWork() {
            return this.ordersData.filter(el => (el.status !== 'new' && el.status !== 'done'))
                .reduce((total, item) => total + Math.abs(item.total), 0)
        },
        orderStatuses() {
            const shortenStatuses = [
                {
                    key: 'loading',
                    name: 'Загрузка'
                },
                {
                    key: 'delivery',
                    name: 'Доставка'
                },
                {
                    key: 'object',
                    name: 'На объекте'
                },
                {
                    key: 'return',
                    name: 'Возвращается'
                }
            ]

            const statuses = this.windowWidth > 1400 ? this.statuses : shortenStatuses;

            if(!this.isTakeAway) {
                return statuses
            }

            return statuses.filter((status) => status.key !== 'loading'
                && status.key !== 'delivery'
                && status.key !== 'return')
        },
        isTakeAway() {
            return this.orderGroupItem.deliveryType === 'take-away'
        }
    },
    methods: {
        dateToTime(data) {
            if (data) {
                return moment.parseZone(data).format('HH:mm')
            }
            return '-'
        },
        filterValues() {
            if (this.paginate) {
                this.filtered = this.ordersData

                if (this.filterStatus) {
                    this.filtered = this.filtered.filter(el => el.status === this.filterStatus)
                }

                this.valueLength = this.filtered.length
                return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
            }
        },
        timelineData(order) {
            return {
                statusHistory: order.statusHistory,
                status: order.status,
                timeline: order.timeline,
            }
        },
        getMapInfo(order) {
            this.currentOrder = order
            this.accountModuleReserveId = order.mix_id
            this.showMap = true
        },
        closeOrderDetailsDialog() {
            this.unlockOrder()
            this.orderDetailsDialog = false
        },
        unlockOrder() {
            if (this.orderDetails?.id) {
                httpService().post(this.api.mixOrder.unlockOrder + '/' + this.orderDetails?.id, {
                    account_modules_id: this.$route.params.id,
                    account_module_reserve_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                })
            }
        },
        hideMap() {
            this.showMap = false
        },
        openMixOrderDetails(order) {
            this.orderDetails = order
            this.orderDetailsDialog = true
        },
        getStatusCount(status) {
            return this.ordersData.filter(el => el.status === status).length
        },
        filterByStatus(status) {
            if (this.filterStatus === status) {
                this.filterStatus = null;
            } else {
                this.filterStatus = status;
            }
        },
        setMixName(mix, mixer) {
            const allMixers = this.modules.find((module) => module.key === this.$route.meta.moduleKey).mixers;
            const mixersAmount = allMixers.filter((item) => item.mix_id === mix.id).length;
            return mixersAmount > 1 ? mixer.name : mix.name
        },
    }
}
</script>
<style scoped lang="scss">
.manage-deliveries {
    &__header {
        display: flex;
        align-items: center;

        &__number {
            color: #393F4A;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-left: 8px;
        }
    }

    &__orders {
        min-height: 332px;

        &--empty {
            min-height: 332px;
            color: #393F4A;
            font-size: 16px;
            font-style: normal;
            align-items: center;
            display: flex;
            justify-content: center;
            font-weight: 700;
            line-height: 24px;
        }
    }

    &__info {

        width: 791px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        margin-bottom: 12px;

        &__item {

            display: flex;
            margin-bottom: 12px;
            min-width: 389px;

            &__label {
                color: #8A909A;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                width: 174px;
            }

            &__data {
                color: #22252C;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                width: 215px;
            }
        }
    }

    &__edit-action {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        &__button {
            padding: 8px 20px;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid #C9CCD0;
            background: #FFF;

            color: #49515F;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;

            cursor: pointer;
            height: fit-content;
        }
    }

    &__filter {
        display: flex;
        justify-content: end;
        margin: 24px 0;

        color: var(--Neutral-600, #49515F);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        align-items: center;

        svg {
            margin-left: 8px;
        }
    }

    &__footer {

        &__add-delivery {
            cursor: pointer;
            display: flex;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            border-radius: 8px;
            background: #1984E6;
            color: #FFF;
            width: fit-content;

            svg {
                margin-right: 6px;
            }
        }

        &__navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__go-back {
                cursor: pointer;
                color: #393F4A;
                font-size: 14px;
                font-style: normal;
                margin-right: 16px;
                font-weight: 500;
                line-height: 20px;
            }

            &__complete {

            }
        }
    }
}
</style>
