<template>
    <div class="doc-viewer-loader" v-if="loading">
        <ReportLoader />
        <h3 class="text-center textLG textMedium neutral100">Идет загрузка документа...</h3>
    </div>
    <div v-else>
        <iframe class="doc-viewer"
                ref="myIframe"
                frameborder="0"
                :src="`https://view.officeapps.live.com/op/embed.aspx?src=${documentUrl}`" />
    </div>
</template>

<script>
import ReportLoader from '@/views/components/Loaders/ReportLoader.vue';

export default {
    name: 'DocViewer',
    props: ['url'],
    components: {
        ReportLoader
    },
    data() {
        return {
            loading: true
        };
    },
    computed: {
        documentUrl() {
            return this.url;
        }
    },
    mounted() {
        // Set a timeout of 2 seconds to simulate loading
        setTimeout(() => {
            this.loading = false;
        }, 2000);
    }
};
</script>

<style lang="scss">
    .doc-viewer {
        width: 100%;
        height: 100svh;
        border: none;
    }

    .doc-viewer-loader {
        width: 100%;
        height: 100svh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>
