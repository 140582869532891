<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.99984 7.33366C8.73622 7.33366 9.33317 6.73671 9.33317 6.00033C9.33317 5.26395 8.73622 4.66699 7.99984 4.66699C7.26346 4.66699 6.6665 5.26395 6.6665 6.00033C6.6665 6.73671 7.26346 7.33366 7.99984 7.33366Z"
            stroke="#505968"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.00016 14C8.00016 14 12.6668 10.7692 12.6668 6.61538C12.6668 5.39131 12.1752 4.21737 11.3 3.35181C10.4248 2.48626 9.23784 2 8.00016 2C6.76249 2 5.5755 2.48626 4.70033 3.35181C3.82516 4.21737 3.3335 5.39131 3.3335 6.61538C3.3335 10.7692 8.00016 14 8.00016 14Z"
            stroke="#505968"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: "MapPinIcon",
};
</script>

<style scoped></style>
