<template>
    <div class="serviceTariffMixDistanceForm">
        <div class="cards">
            <div class="card child" v-for="(child, index) in form.childs" :key="index">
                <div class="card-body p-relative">

                    <el-button class="deleteButton" :icon="icons.delete" @click="removeChild(index)"/>

                    <ServiceTariffChild
                        :key="index"
                        :child="child"
                        :price-types="priceTypes"
                        @input="updateChild(index, $event)"
                        type="mix"
                    />

                </div>
            </div>
        </div>

        <el-button class="mt-15" :icon="icons.plus" @click="addChild">Добавить дополнительное условие в тарифе</el-button>
    </div>
</template>

<script>
import serviceInfo from "@/mixins/serviceInfo";
import ServiceTariffChild from "@/views/settings/serviceTariffs/components/ServiceTariffChild.vue";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "ServiceTariffMixDistanceForm",
    components: {ServiceTariffChild},
    props: ['modelValue'],
    mixins: [serviceInfo, iconsMixin],
    data() {
        return {
            form: this.modelValue
        }
    },
    methods: {
        addChild() {
            this.form.childs.push({
                id: null,
                name: null,
                vat: 0,
                price: null,
                full_price: null,
                price_type: null,
                volume_min: null,
                volume_max: null,
                distance_from: null,
                distance_to: null,
                default: false
            })
        },
        removeChild(index) {
            this.form.childs.splice(index, 1);
        },
        updateChild(index, value) {
            this.$set(this.form.childs, index, value);
        },
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.form = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>
.child {
    border: 1px solid #c9ccd0;
    border-radius: 12px;
    margin-bottom: 10px;
}
.deleteButton {
    position: absolute;
    right: 25px;
    top: 10px;
    height: 28px !important;
    width: 28px !important;
    z-index: 999999999;
}

.serviceTariffMixDistanceForm {
    .cards {
        max-height: 40svh;
        overflow: auto;
    }
}
</style>
