<template>
    <svg width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M16 16L8 8"
              stroke="#151517"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M16 8L8 16"
              stroke="#151517"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
    name: "CloseIconSM",
};
</script>
