<template>
    <el-date-picker v-model="itemDate"
                    class="custom-date-picker"
                    placeholder='Сегодня'
                    type="date"
                    :prefix-icon="calendarIcon"
                    :picker-options="pickerOptions"
                    :clearable="false"
                    format="DD.MM.YYYY"
                    value-format="DD.MM.YYYY"
                    :editable="false">
    </el-date-picker>
</template>

<script>
import CalendarIcon from "@/views/components/Icons/Calendar.vue";

export default {
    props: ["modelValue"],
    data() {
        return {
            calendarIcon: CalendarIcon,
            itemDate: this.modelValue,
        };
    },
    watch: {
        'itemDate': {
            handler: function () {
                this.$emit('update:modelValue', this.itemDate)
            },
            deep: true,
            immediate: true
        },
        'modelValue': {
            handler: function () {
                this.itemDate = this.modelValue
            },
            deep: true,
            immediate: true
        },
    },
};
</script>
