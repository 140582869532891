<template>
    <svg :class="[{ 'currentColor': currentColor }]" width="22" height="14" viewBox="0 0 22 14" fill="none"
         xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.62176 12.2835C6.55362 14.572 3.13646 14.5724 3.06832 12.2835C3.13609 9.99019 6.55362 9.98947 6.62176 12.2835ZM14.2026 12.2835C14.1338 14.5731 10.721 14.571 10.6525 12.2835C10.7207 9.99199 14.133 9.98839 14.2026 12.2835Z"
                fill="#505968"/>
            <path
                d="M5.60125 8.49326C5.69708 8.49298 5.78891 8.45565 5.85667 8.38944C5.92444 8.32322 5.96264 8.23349 5.96293 8.13984V5.37439C5.96274 5.28068 5.92458 5.19086 5.8568 5.12457C5.78903 5.05827 5.69715 5.0209 5.60125 5.02061H2.13474L2.38445 4.37748C2.47061 4.16153 2.62124 3.9759 2.81678 3.84471C3.01231 3.71352 3.24371 3.64284 3.4809 3.64185H7.3997C7.55368 3.64185 7.70615 3.67149 7.84841 3.72908C7.99067 3.78667 8.11992 3.87109 8.22879 3.9775C8.33765 4.08391 8.424 4.21024 8.48289 4.34926C8.54178 4.48829 8.57207 4.63729 8.57202 4.78776V12.2837C8.57183 12.3774 8.53367 12.4672 8.4659 12.5335C8.39812 12.5998 8.30624 12.6372 8.21034 12.6375H7.31646C7.3361 12.5205 7.34583 12.4022 7.34556 12.2837C7.16877 8.81573 2.02498 9.18499 2.3734 12.6375C2.20076 12.6666 2.02373 12.659 1.85437 12.6152C1.68501 12.5714 1.5273 12.4924 1.39202 12.3837C1.25673 12.2749 1.14703 12.1389 1.07042 11.985C0.9938 11.831 0.952063 11.6627 0.94805 11.4916V8.49326H5.60125Z"
                fill="#505968"/>
            <path
                d="M5.23988 7.78592H1.06401C1.06713 7.77012 1.07194 7.75468 1.07837 7.73985L1.85992 5.72803H5.23988V7.78592ZM19.1531 12.2836C19.1078 14.5912 15.5702 14.5394 15.6055 12.2364C15.6866 9.98489 19.1185 10.0385 19.1531 12.2836Z"
                fill="#505968"/>
            <path
                d="M9.448 3.0229L12.3171 0.954565C12.3355 1.01107 14.6695 8.03339 14.7745 8.34794C14.8371 8.35298 15.4779 8.34794 15.538 8.35154C15.3534 7.78722 13.1359 1.13667 12.9941 0.699757L19.0623 0.0065948C19.3022 -0.0200633 19.5441 0.0346021 19.7477 0.16144C19.9513 0.288279 20.104 0.479592 20.1805 0.703357L21.523 4.74141C21.5962 4.96637 21.5884 5.20908 21.5008 5.42911C21.4133 5.64914 21.2512 5.83321 21.0416 5.95066L20.253 6.38218V8.35154H20.9116C21.0074 8.35182 21.0992 8.38915 21.167 8.45537C21.2348 8.52158 21.273 8.61131 21.2733 8.70496V11.5762C21.2725 11.8573 21.1579 12.1267 20.9545 12.3255C20.7511 12.5243 20.4755 12.6363 20.1879 12.6372H19.8475C19.8672 12.5203 19.8769 12.4019 19.8766 12.2834C19.8078 9.35565 15.5921 8.94573 14.9266 11.7886C14.8971 11.9512 14.9082 11.9829 14.8794 11.7994C14.7508 11.1755 14.3779 10.6254 13.8403 10.2662C13.3027 9.90703 12.6428 9.76716 12.0012 9.87639C11.3596 9.98562 10.787 10.3353 10.4054 10.851C10.0238 11.3667 9.86328 12.0076 9.95811 12.6372H9.2307C9.34598 12.6771 9.28226 8.47174 9.29589 8.35154H10.4682C10.4645 8.34434 10.4608 8.33354 10.4572 8.3267L9.29589 4.82634L9.07491 4.16161C9.00491 3.95772 9.00335 3.73732 9.07046 3.5325C9.13757 3.32768 9.26983 3.14915 9.448 3.0229ZM19.5293 8.35154V6.77807L16.6632 8.35154H19.5293Z"
                fill="#505968"/>
    </svg>
</template>

<script>
export default {
    name: "SolidMixerIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    svg {
        min-width: fit-content;
    }

    .currentColor {
        path {
            fill: currentColor;
        }
    }
</style>

