<template>
    <div>
        <div class="textMedium textSM neutral900 mb-10">{{ label }}</div>
        <div>
        </div>
    </div>
</template>

<script>

import MixOrderInfo from "@/mixins/mixOrderInfo";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "MixOrderGroupBasicLog",
    props: ['log'],
    mixins: [MixOrderInfo, functionsMixin],
    computed: {
        label() {
            let label = ''
            switch (this.log.event) {
                case 'create':
                    label = 'Заказ создан'
                    break;
                case 'work':
                    label = 'Заказ в работе'
                    break;
                case 'pause':
                    label = 'Заказ остановлен'
                    break;
                case 'laboratory':
                    label = 'Заказ направлен в лабораторию'
                    break;
                case 'unallocated':
                    label = 'Заказ не распределен'
                    break;
                case 'partially_distributed':
                    label = 'Заказ частично распределен'
                    break;
                case 'distribute':
                    label = 'Заказ распределен'
                    break;
                case 'confirmed':
                    label = 'Заказ подтвержден'
                    break;
                case 'unconfirmed':
                    label = 'Заказ не подтвержден'
                    break;
                case 'done':
                    label = 'Заказ выполнен'
                    break;
                case 'completed':
                    label = 'Заказ завершен'
                    break;
                case 'failed':
                    label = 'Заказ отменен'
                    break;
            }

            return label
        },
    }
}
</script>

<style scoped>

</style>
