import {defineStore} from 'pinia'
import Echo from "laravel-echo";
import io from "socket.io-client"


export const useEchoStore = defineStore({
    id: 'echo',
    state: () => ({
        echo: null
    }),
    getters: {
        Echo(state) {
            if (!state.echo) {
                window.io = io

                state.echo = new Echo({
                    broadcaster: 'socket.io',
                    host: process.env.VUE_APP_SOCKET_HOST,
                    // path: process.env.VUE_APP_SOCKET_PATH,
                    enabledTransports: ['wss'],
                    auth: {headers: {Authorization: `Bearer ${localStorage.getItem('api_token')}`}}
                })
            }
            return state.echo
        },
    },
})
