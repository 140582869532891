<template>
    <el-dialog v-model="isVisible" class="approve-modal" :lock-scroll="true" :show-close="false">
        <InfoIcon/>
        <div class="approve-modal__title text neutral400">Смена статуса</div>
        <p class="text neutral900">{{ buttonText }}</p>
        <div class="approve-modal__buttons">
            <el-button @click="$emit('approve')">Подтвердить</el-button>
            <el-button @click="$emit('close')">Отмена</el-button>
        </div>
    </el-dialog>
</template>

<script>
import InfoIcon from "@/views/components/Icons/Notifications/InfoIcon.vue";

export default {
    name: "MixDriverTaskConfirmModal",
    props: ['modelValue', 'buttonText'],
    components: {
        InfoIcon
    },
    data() {
        return {
            isVisible: this.modelValue
        }
    },
    watch: {
        'modelValue': function (newVal) {
            this.isVisible = newVal
        },
        'isVisible': function (newVal) {
            this.$emit('update:modelValue', newVal)
        }
    }
}
</script>
