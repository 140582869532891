<template>
    <el-form ref="form" label-position="top">
        <FormSelect
            class='mb-20'
            v-model="form.type"
            label="Тип документа"
            placeholder="Выберите тип"
            :required="true"
            :validation-message="validationMessages.type"
            :items="types"
        />

        <FormInput
            v-if="form.type === 'other'"
            class='mb-20'
            v-model="form.name"
            label="Название"
            placeholder="Введите название"
            :validation-message="validationMessages.name"
            :required="true"
        />

        <FileUploader class='pt-8' v-model="form.file" :required="true" :validation-message="validationMessages.file" />

        <hr class="mt-20 mb-20"/>

        <el-button :disabled="loading" type="primary" @click="$emit('createDocument')">
            Создать документ
        </el-button>
    </el-form>
</template>

<script>
import FormInput from '@/views/components/Form/FormInput';
import FormSelect from "@/views/components/Form/FormSelect";
import FileUploader from "@/components/FileUploader";

export default {
    name: "CRMCustomDocumentsCreate",
    props: ['details', 'loading', 'validationMessages', 'types'],
    components: {FormSelect, FormInput, FileUploader},
    emits: ['createDocument'],
    data() {
        return {
            form: this.details,
        }
    }
}
</script>

<style scoped>

</style>
