<template>
	<el-form-item v-if="select !== false" :label="label" :label-width="labelWidth" v-bind:class="[extraClass, { 'is-required': required }]">
		<div class="inputModal" :class="[ {disabled: disabled}, {pointer: !disabled}, {empty: !itemId}]">
			<div class="d-flex">
				<div class="inputModalWrapper" @click="openSelectDialog">
					{{ itemId ? getInfo(this.itemId, this.values) : this.placeholder }}
				</div>
				<div class="inputModalClear" v-if="itemId" @click="clearItemId()">
					<i class="el-icon el-input__icon el-input__clear">
						<CloseRoundIcon/>
					</i>
				</div>
			</div>
		</div>
	</el-form-item>

	<el-dialog
		v-model="selectDialog"
		:title="dialogTitle"
		:width="dialogSize"
		:destroy-on-close="true"
        v-if="!createDialog"
	>
        <template v-if="loadingTable">
            <TableDataLoader text="тарифов"/>
        </template>
        <template v-else>
            <TableHeadBlock v-model="search"
                            :loading="loading"
                            :disable-filter="true"
                            :disable-update-button="true"
                            :search-focused="true"
                            :disable-create-button="disableCreateButton"
                            create-button-label="Прикрепить тариф"
                            @openCreateValueDrawer="() => createDialog = true"/>
            <el-table empty-text="Информация не найдена"
                      class="mt-15 w-100 defaultTableHeight"
                      v-loading="loadingTable"
                      @current-change="handleCurrentChange"
                      :data="displayData">
                <el-table-column prop="id"
                                 label="#"
                                 width="100"
                                 class-name="pointer"
                                 sortable>
                    <template v-slot="scope">
                                <span class="textSM textMedium"
                                      :class="[{ neutral200: scope.row.id !== itemId }, { primary600: scope.row.id === itemId }]">
                                    #{{ formatNumber(scope.row.id) }}
                                </span>
                    </template>
                </el-table-column>
                <el-table-column prop="name"
                                 label="Название"
                                 class-name="pointer"
                                 sortable>
                    <template v-slot="scope">
                        <span class="textSM textMedium primary600 cutOverflow">{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="integration"
                                 label="Тип"
                                 class-name="pointer"
                                 width="220px">
                    <template v-slot="scope">
                        <template v-if="scope.row.depends_on">
                            <span
                                class="textRegular textSM neutral400">{{ getInfo(scope.row.depends_on, dependsTypes) }}</span>
                        </template>
                        <template v-else>
                            <span class="textRegular textSM neutral200">-</span>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
            <TablePagination v-model="pageSize"
                             :total="valueLength"
                             :loading="loading"
                             @action="setPage"/>
        </template>
	</el-dialog>
    <el-dialog
        v-model="createDialog"
        title="Новый тариф"
        :width="mobile ? '100%' : '80%'"
        :destroy-on-close="true"
    >
        <ServiceTariffCreateDialog
            :defaultType="type"
            @create="handleAddServiceTariff"
        />
    </el-dialog>
</template>

<script>
import {httpService} from "@/services/http.service";

import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import tableMixin from "@/mixins/tableMixin";
import CloseRoundIcon from "@/views/components/Icons/CloseRoundIcon.vue";
import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import ServiceTariffCreateDialog from "@/views/settings/serviceTariffs/ServiceTariffCreateDialog";

export default {
	name: 'FormSelectTariff',
	components: {
		CloseRoundIcon, TableDataLoader, TableHeadBlock, TablePagination, ServiceTariffCreateDialog
	},
	mixins: [functionsMixin, mobileCheckMixin, tableMixin],
	inject: ['api'],
	props: [
        'addedTariffs',
		'modelValue',
		'items', // список тарифов для отображения в таблице
		'label',
		'labelWidth',
		'placeholder',
		'newItem',
		'disabled', // неактивное поле
		'required', // обязательное поле
		'extraClass', // дополнительные классы для стилизации,
		'disableCreateButton', // cкрытие кнопки добавления нового элемента в модалке
		'type' // тип тарифа: mix(доставка бетона), downtime(простой), pump(насос)
	],
	data() {
		return {
			itemId: this.modelValue,
            loading: false,
			selectDialog: false,
            createDialog: false,
            search: '',
            loadingTable: false,
            pageSize: 10,
		}
	},
    created() {
        this.getData()
    },
	computed: {
		dialogTitle() {
			const titles = {
				pump: 'Выберите тариф на насос',
				mix: 'Выберите тариф на доставку',
				downtime: 'Выберите тариф на простой'
			}
			return titles[this.type]
		}
	},
	methods: {
        getData() {
            this.loadingTable = true
            httpService().post(this.api.serviceTariff.getAll, {
                paginate: false,
                search: this.search,
                type: this.type,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.loadingButton = false

                    this.values = data.items;
                    this.valueLength = data.items.length

                    if (this.addedTariffs && this.addedTariffs?.length) {
                        // исключаем выбранные варианты
                        const filtered = data.items.filter(item =>
                            !this.addedTariffs.some(tariff => item.id === tariff.id)
                        );
                        this.values = filtered;
                        this.valueLength = filtered.length
                    }
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            }).finally(() => {
                this.loadingTable = false
            })
        },
		openSelectDialog() {
			if (!this.disabled) {
				this.selectDialog = true
			}
		},
		clearItemId() {
			this.itemId = null
		},
		handleCurrentChange(tariff) {
            if(tariff) {
                this.selectItem(tariff?.id);
            }
            this.selectDialog = false;
		},
        filterValues() {
            this.filtered = this.items?.length ? this.items : this.values;

            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            }

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        selectItem(id) {
            this.itemId = id;
            this.$emit('update:modelValue', id);
        },
        handleAddServiceTariff(val) {
            if (val) {
                this.selectItem(val.id)
                if (this.items) {
                    this.$emit('update')
                } else this.getData()
            }
            this.createDialog = false;
            this.selectDialog = false;
        },
	},
	watch: {
		'modelValue': {
			handler: function () {
				this.itemId = this.modelValue
			},
			deep: true,
			immediate: true
		},
		'itemId': {
			handler: function (val) {
				if (val) {
					this.values.sort((a, b) => {
						if (a.id === val) return -1;
						if (b.id === val) return 1;
						return 0;
					});
				}
			},
			deep: true,
			immediate: true
		},
        'items': {
			handler: function (val) {
				if (val && val.length) {
					this.values = this.items
					this.valueLength = this.items.length
				} else this.getData()
			},
			deep: true,
			immediate: true
		},
	}
}
</script>
