<template>
    <div>
        <div class="textRegular textSM neutral300 mb-15">Компоненты:</div>
        <el-button class="mb-10" size="small" @click="openAddDialog()">Добавить компонент</el-button>

        <el-table
            :data="displayData"
            class="w-100 mt-15">
            <el-table-column
                prop="name"
                label=""
            >
                <template v-slot="scope">
                    <div class="d-flex-full">
                        <div class="textMD textMedium neutral900">{{ scope.row.component?.name }} / {{ formatNumber(Number(scope.row.total)) }} {{ scope.row.component?.unit?.short_name }}</div>

                        <template v-if="scope.row.component?.integration_unit_id">
                            <IntegrationLogo
                                :integration-unit-id="
                                        scope.row.component?.integration_unit_id
                                    "
                            />
                        </template>
                        <template v-else>
                                <span class="textRegular textSM neutral200"
                                >-</span
                                >
                        </template>
                    </div>

                    <hr/>

                    <div class="d-flex-full"
                         v-for="(integration, index) in integrations.filter(el => el.integration.key === 'SmartMix')"
                         :key="index">

                        <div class="cutOverflow">
                            <IntegrationLogo
                                :integration-unit-id="integration.id"
                            />
                        </div>
                        <div>
                            <el-button @click="openAddDialogByComponent(scope.row.id, scope.row.component?.id, integration.id)" type="text">Добавить</el-button>
                        </div>
                    </div>

                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label=""
                width="60px">
                <template v-slot="scope">
                    <el-tooltip effect="dark" content="Удалить компонент" :show-after="500" placement="left">
                        <el-button
                            size="small"
                            @click="remove(scope.row)"
                        >
                            Х
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <TablePagination
            v-model="pageSize"
            :total="valueLength"
            @action="setPage"
        />
    </div>

    <el-dialog
        v-model="addDialog"
        title="Выберите компонент"
        :width="mobile ? '100%' : '60%'"
        :destroy-on-close="true"
    >
        <MixOrderDetailsGroupEditSelectGoods
            @updateItem="updateGood"
        />
    </el-dialog>
</template>

<script>

import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import MixOrderDetailsGroupEditSelectGoods
    from "@/views/components/MixOrderGroup/components/editSelects/MixOrderDetailsGroupEditSelectGoods.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "LabSpecComponents",
    components: {IntegrationLogo, MixOrderDetailsGroupEditSelectGoods, TablePagination},
    props: ['components'],
    mixins: [tableMixin, mobileCheckMixin, functionsMixin],
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
        })
    },
    data() {
        return {
            values: [],
            addDialog: false,
            selectedComponentGoodId: null,
            selectedComponentId: null,
            selectedIntegrationId: null
        }
    },
    created() {
        this.values = this.components
    },
    methods: {
        openAddDialog() {
            this.selectedComponentGoodId = null
            this.selectedIntegrationId = null
            this.selectedComponentId = null
            this.addDialog = true
        },
        remove(id) {
            console.log(id)
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        updateGood(good) {
            this.values.push(good)

            this.addDialog = false
        },
        openAddDialogByComponent(componentId, componentGoodId, integrationId) {
            this.selectedComponentGoodId = componentId
            this.selectedComponentId = componentGoodId
            this.selectedIntegrationId = integrationId
            this.addDialog = true
        }
    },
    watch: {
        'components': {
            handler() {
                this.values = this.components
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
