<template>
    <div>
        <div class="mb-10"><el-input v-model="accountId" placeholder="ID аккаунта"/></div>
        <div class="mb-10"><el-input v-model="integrationUnitId" placeholder="ID интеграции"/></div>
        <div class="mb-10 d-flex justify-between"><el-button @click="doAction('goods-duplicate-delete-soft-id')">Удалить дубляж в товарах по софт ид</el-button>
            <el-button @click="doAction('goods-duplicate-delete')">Удалить дубляж в товарах</el-button>
        </div>
    </div>
</template>

<script>
import {httpService} from '@/services/http.service'

export default {
    name: 'GodModeFunctions',
    data() {
        return {
            loading: false,
            accountId: null,
            integrationUnitId: null,
        }
    },
    inject: ['api'],
    methods: {
        doAction(functionName) {
            this.loading = true

            httpService().post(this.api.admin.function, {
                function: functionName,
                accountId: this.accountId,
                integrationUnitId: this.integrationUnitId,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    console.log(data.message);
                    this.$message({
                        message: 'Успешно',
                        showClose: true,
                        type: 'success'
                    })
                }
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    },
}
</script>

<style scoped>

</style>
