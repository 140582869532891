export const API_ROUTES = {
    notifications: {
        all: '/api/notifications',
        send: '/api/notifications/send',
        readAll: '/api/notifications/read-all'
    },
    account: {
        login: '/api/account/login',
        me: '/api/account/me',
        register: '/api/account/register',
        forgot: '/api/account/forgot',
        password: '/api/account/password',
        verify: '/api/account/verify',
        loginByToken: '/api/account/loginByToken',
        registerAccount: "/api/account/registerAccount",
        suggestionByDaData: "/api/account/suggestionByDaData",
        suggestionAddressByDaData: "/api/account/suggestionAddressByDaData",
        suggestionINNByDaData: "/api/account/suggestionINNByDaData",
        suggestionAddress: '/api/account/suggestionAddress',
        getCoordinatesByAddress: '/api/account/getCoordinatesByAddress',
    },
    cashbox: {
        get: '/api/cashbox/get',
    },
    cashboxOperation: {
        create: '/api/cashbox-operation/create',
        update: '/api/cashbox-operation/create',
        remove: '/api/cashbox-operation/remove',
    },
    cashboxAccount: {
        create: '/api/cashbox/createAccount',
        remove: '/api/cashbox/removeAccount',
    },
    contract: {
        get: '/api/contract/get',
        remove: '/api/contract/remove',
        getOne: '/api/contract/getOne',
        create: '/api/contract/create',
        createW: '/api/contract/createW',
    },
    contractValue: {
        get: '/api/contract-value/get',
        create: '/api/contract-value/create',
        remove: '/api/contract-value/remove',
    },
    weighing: {
        get: '/api/weighing/get',
        getInfo: '/api/weighing/getInfo',
        getDrivers: '/api/weighing/getDrivers',
    },
    weighingValue: {
        getOne: '/api/weighing-value/getOne',
        getValues: '/api/weighing-value/getValues',
        getSimpleValues: '/api/weighing-value/getSimpleValues',
        getOneDayValues: '/api/weighing-value/getOneDayValues',
        create: '/api/weighing-value/create',
        remove: '/api/weighing-value/remove',
        update: '/api/weighing-value/update',
    },
    storehouse: {
        get: '/api/storehouse/get',
        getUnits: '/api/storehouse/getUnits',
    },
    storehouseValue: {
        create: '/api/storehouse-value/create',
        get: '/api/storehouse-value/get',
        remove: '/api/storehouse-value/remove',
    },
    storehouseGood: {
        remove: '/api/storehouse-good/remove',
        edit: '/api/storehouse-good/edit',
    },
    userLog: {
        getMixOrderHistory: '/api/userLog/getMixOrderHistory',
    },
    production: {
        get: '/api/production/get',
        getControlTasks: '/api/production/getControlTasks',
        getInfoForGoodCreate: '/api/production/getInfoForGoodCreate',
    },
    productionValue: {
        getAll: '/api/production-value/getAll',
        create: '/api/production-value/create',
        remove: '/api/production-value/remove',
    },
    productionGood: {
        create: '/api/production-good/create',
        remove: '/api/production-good/remove',
        getAll: '/api/production-good/getAll',
    },
    tires: {
        create: '/api/tires/create',
        remove: '/api/tires/remove',
        getAll: '/api/tires/getAll',
        getBrands: '/api/tires/getBrands',
    },
    operator: {
        getOrders: '/api/operator/getOrders',
        changeStatus: '/api/operator/changeStatus',
        getMixers: '/api/operator/getMixers',
        createMixOrder: '/api/operator/createMixOrder',
        getModule: '/api/operator/getModule',
    },
    productionPost: {
        create: '/api/production-post/create',
        remove: '/api/production-post/remove',
        getAll: '/api/production-post/getAll',
    },
    productionReport: {
        create: '/api/production-report/create',
        edit: '/api/production-report/edit',
        remove: '/api/production-report/remove',
        getAll: '/api/production-report/getAll',
    },
    productionOrder: {
        create: '/api/production-order/create',
        remove: '/api/production-order/remove',
        edit: '/api/production-order/edit',
        getAll: '/api/production-order/getAll',
    },
    productionOrderStep: {
        do: '/api/production-order-step/do',
    },
    productionPlan: {
        getMemberPlan: '/api/production-plan/getMemberPlan',
        create: '/api/production-plan/create',
        remove: '/api/production-plan/remove',
    },
    productionShift: {
        getAll: '/api/production-shift/getAll',
        create: '/api/production-shift/create',
        remove: '/api/production-shift/remove',
    },
    productionCalendar: {
        create: '/api/production-calendar/create',
        createReport: '/api/production-calendar/createReport',
        remove: '/api/production-calendar/remove',
    },
    widget: {
        getOne: '/api/widget/getOne',
    },
    settings: {
        getPaymentPage: '/api/settings/getPaymentPage',
        getTariffs: '/api/settings/getTariffs',
        getVehicles: '/api/settings/getVehicles',
        getVehiclesWithoutIntgOrTraccar: '/api/settings/getVehiclesWithoutIntgOrTraccar',
        createOrUpdateVehicle: '/api/settings/createOrUpdateVehicle',
        removeVehicle: '/api/settings/removeVehicle',
        getBrands: '/api/settings/getBrands',
        getModels: '/api/settings/getModels',
        getWidgets: '/api/settings/getWidgets',
        getIntegrations: '/api/settings/getIntegrations',
        getDataSmartMix: '/api/settings/getDataSmartMix',
        getOneCIntegration: '/api/settings/getOneCIntegration',
        getAllIntegrations: '/api/settings/getAllIntegrations',
        createIntegration: '/api/settings/createIntegration',
        editIntegration: '/api/settings/editIntegration',
        removeIntegration: '/api/settings/removeIntegration',
        getRelations: '/api/settings/getRelations',
        getAllRelations: '/api/settings/getAllRelations',
        getOneRelation: '/api/settings/getOneRelation',
        createRelation: '/api/settings/createRelation',
        updateRelation: '/api/settings/updateRelation',
        removeRelation: '/api/settings/removeRelation',
        getFactories: '/api/settings/getFactories',
        getFactoryTypes: '/api/settings/getFactoryTypes',
        createFactory: '/api/settings/createFactory',
        updateFactory: '/api/settings/updateFactory',
        removeFactory: '/api/settings/removeFactory',
        getOperations: '/api/settings/getOperations',
        getModuleEconomySettings: '/api/settings/getModuleEconomySettings',
        getModulePlanSettings: '/api/settings/getModulePlanSettings',
        setTariff: '/api/settings/setTariff',
        getAccountSettings: '/api/settings/getAccountSettings',
        getAppSettings: '/api/settings/getAppSettings',
        getAccountModuleSettings: '/api/settings/getAccountModuleSettings',
        saveAccountSettings: '/api/settings/saveAccountSettings',
        editAppsSettings: '/api/settings/editAppsSettings',
    },
    mailReport: {
        getAccountSettings: '/api/mailReport/getAccountSettings',
        createOrUpdate: '/api/mailReport/createOrUpdate',
        sendAccountReport: '/api/mailReport/sendAccountReport',
    },
    moduleEconomy: {
        createValue: '/api/moduleEconomy/createValue',
    },
    modulePlan: {
        createValue: '/api/modulePlan/createValue',
    },
    module: {
        create: '/api/module/create',
        get: '/api/module/get',
        getOne: '/api/module/getOne',
        editOne: '/api/module/editOne',
        deleteOne: '/api/module/deleteOne',
    },
    account_module: {
        get: '/api/account-module/get',
        getAvailableRelations: '/api/account-module/getAvailableRelations',
    },
    custom_module: {
        getModule: '/api/custom-module/getModule',
        getModuleData: '/api/custom-module/getModuleData',
        doAction: '/api/custom-module/doAction'
    },
    users: {
        get: '/api/users/get',
        getRoles: '/api/users/getRoles',
        getLogs: '/api/users/getLogs',
    },
    user: {
        get: '/api/user/getOne',
        edit: '/api/user/editOne',
        ban: '/api/user/ban',
        removeUser: '/api/user/removeUser',
        create: '/api/user/create',
        setup: '/api/user/setup',
        getSetupInfo: '/api/user/getSetupInfo',
        getIsModuleAvailable: '/api/user/getIsModuleAvailable',
    },
    navigation: {
        get: '/api/navigation/get',
    },
    moduleAccess: {
        create: '/api/module-access/create',
        access: '/api/module-access/user-has-access',
    },
    good: {
        listByAccount: '/api/good/listByAccount',
        get: '/api/good/get',
        getByDispatch: '/api/good/getByDispatch',
        getUnits: '/api/good/getUnits',
        create: '/api/good/create',
        remove: '/api/good/remove',
        removeGoods: '/api/good/removeGoods',
        update: '/api/good/update',
        getOneGoodDetails: '/api/good/getOneGoodDetails',
        addGoodPrice: '/api/good/addGoodPrice',
        generateTaskForGetRecipes: '/api/good/generateTaskForGetRecipes',
    },
    relationGroup: {
        get: '/api/relationGroup/getRelations',
        getRelationGroups: '/api/relationGroup/getRelationGroups',
        create: '/api/relationGroup/create',
        remove: '/api/relationGroup/remove',
        multiRemove: '/api/relationGroup/multiRemove',
    },
    company: {
        get: '/api/company/get',
        getOne: '/api/company/getOne',
        getAttribute: '/api/company/getAttribute',
        getAddresses: '/api/company/getAddresses',
        getCompanyAddresses: '/api/company/getCompanyAddresses',
        create: '/api/company/create',
        createIndividual: '/api/company/createIndividual',
        createCompanyAddress: '/api/company/createCompanyAddress',
        removeCompanyAddress: '/api/company/removeCompanyAddress',
        createCompanyManager: '/api/company/createCompanyManager',
    },
    accountGroup: {
        get: '/api/account-group/get',
    },
    document: {
        getByType: '/api/document/getByType',
        getAll: '/api/document/getAll',
        getOne: '/api/document/getOne',
        create: '/api/document/create',
        remove: '/api/document/remove',
        removeGood: '/api/document/removeGood',
        changeStatus: '/api/document/changeStatus',
    },
    serviceTariff: {
        getAll: '/api/serviceTariff/getAll',
        getOne: '/api/serviceTariff/getOne',
        create: '/api/serviceTariff/create',
        remove: '/api/serviceTariff/remove',
        edit: '/api/serviceTariff/edit',
    },
    mixOrder: {
        getServiceTariff: '/api/mixOrder/getServiceTariff',
        getOrderInfo: '/api/mixOrder/getOrderInfo',
        getOrderInfoSimple: '/api/mixOrder/getOrderInfoSimple',
        unlockOrder: '/api/mixOrder/unlockOrder',
        getLogs: '/api/mixOrder/getLogs',
        getOne: '/api/mixOrder/getOne',
    },
    vehicle: {
        get: '/api/vehicle/get',
        getOne: '/api/vehicle/getOne',
        createAxle: '/api/vehicle/createAxle',
        removeAxle: '/api/vehicle/removeAxle',
        getAxles: '/api/vehicle/getAxles',
        createTire: '/api/vehicle/createTire',
        createTires: '/api/vehicle/createTires',
        removeTire: '/api/vehicle/removeTire',
        getVehicleSchedulesForDay: '/api/vehicle/getVehicleSchedulesForDay'
    },
    tech: {
        get: '/api/tech/get',
        getTech: '/api/tech/getTech',
        getService: '/api/tech/getService',
        getServiceDetails: '/api/tech/getServiceDetails',
        changeTechStatus: '/api/tech/changeTechStatus',
        changeTechMileage: '/api/tech/changeTechMileage',
        changeTechFuel: '/api/tech/changeTechFuel',
        getTechDetails: '/api/tech/getTechDetails',
        getTechFuel: '/api/tech/getTechFuel',
        getTechService: '/api/tech/getTechService',
        getTechPeriodicityWorks: '/api/tech/getTechPeriodicityWorks',
        getTechServiceNextWorkNumber: '/api/tech/getTechServiceNextWorkNumber',
        removeTechService: '/api/tech/removeTechService',
        getTechGoods: '/api/tech/getTechGoods',
        createTechStore: '/api/tech/createTechStore',
        removeTechStore: '/api/tech/removeTechStore',
        changeTechStoreTotal: '/api/tech/changeTechStoreTotal',
        getTechStoreValues: '/api/tech/getTechStoreValues',
        removeTechOneFuel: '/api/tech/removeTechOneFuel',
        getTires: '/api/tech/getTires',
        getTireHistory: '/api/tech/getTireHistory',
        removeTireHistory: '/api/tech/removeTireHistory',
    },
    techVehicle: {
        create: '/api/tech-vehicle/create',
        createHistory: '/api/tech-vehicle/createHistory',
        removeHistory: '/api/tech-vehicle/removeHistory',
        getHistory: '/api/tech-vehicle/getHistory',
        createWork: '/api/tech-vehicle/createWork',
        updateStatus: '/api/tech-vehicle/updateStatus',
        remove: '/api/tech-vehicle/remove',
        update: '/api/tech-vehicle/update',
        changeStatusPeriodicityWork: '/api/tech-vehicle/changeStatusPeriodicityWork',
        uploadImage: '/api/tech-vehicle/uploadImage',
    },
    fuel: {
        getVehicles: '/api/fuel/getVehicles',
        createVehicle: '/api/fuel/createVehicle',
    },
    dispatch: {
        getLast: '/api/dispatch/get-last',
        getOrders: '/api/dispatch/getOrders',
        getDispatch: '/api/dispatch/getDispatch',
        createOrders: '/api/dispatch/createOrders',
        getOrderInfo: '/api/dispatch/getOrderInfo',
        // createOrder: '/api/dispatch/createOrder',
        getOrderGroupById: '/api/dispatch/get-group-by-id',
        getSummary: '/api/dispatch/get-summary',
        listByAccount: '/api/dispatch/listByAccount',
        printOrderDoc: '/api/dispatch/printOrderDoc',
        printDocs: '/api/dispatch/printDocs',

        schedules: {
            getPosts: '/api/dispatch/schedules/getPosts',
            getPostDefaultSchedules: '/api/dispatch/schedules/getPostDefaultSchedules',
            getPostSchedules: '/api/dispatch/schedules/getPostSchedules',
            updatePostDefaultSchedules: '/api/dispatch/schedules/updatePostDefaultSchedules',
            updatePostSchedules: '/api/dispatch/schedules/updatePostSchedules',
        },

        orderGroups:{
            getOrderGroups: '/api/dispatch/order-groups/getOrderGroups',
            createNewOrderGroup: '/api/dispatch/order-groups/createNewOrderGroup',
            distributeNewOrderGroup: '/api/dispatch/order-groups/distributeNewOrderGroup',
            distributeUpdateOrderGroup: '/api/dispatch/order-groups/distributeUpdateOrderGroup',
            distributeNotSaveOrderGroup: '/api/dispatch/order-groups/distributeNotSaveOrderGroup',
            distributeSaveOrderGroup: '/api/dispatch/order-groups/distributeSaveOrderGroup',
            distributeSaveOrderGroupTest: '/api/dispatch/order-groups/distributeSaveOrderGroupTest',
            getTestData: '/api/dispatch/order-groups/getTestData',
            saveTestData: '/api/dispatch/order-groups/saveTestData',
            updateOrderGroups: '/api/dispatch/order-groups/update',
            getBestDelivery: '/api/dispatch/order-groups/best-delivery',
            delete: '/api/dispatch/order-groups/delete',
            getOrdersByGroupsid: '/api/dispatch/order-groups/orders',
            getRequiremenTime: '/api/dispatch/order-groups/requiremen-time',
            changeStatus: '/api/dispatch/order-groups/change-status',
            getTimeInfo: '/api/dispatch/order-groups/getTimeInfo',
            getSpecGoods: '/api/dispatch/order-groups/getSpecGoods',
            getMapData: '/api/dispatch/order-groups/getMapData',
            getDefaultSpec: '/api/dispatch/order-groups/getDefaultSpec',
        },

        mixers: {
            getMixersShift: '/api/dispatch/mixers/index',
            getAllMixers: '/api/dispatch/mixers/getAllMixers',
            createMixer: '/api/dispatch/mixers/createMixer',
            createDriver: '/api/dispatch/mixers/createDriver',
            changeShift: '/api/dispatch/mixers/changeShift',
            changeDayShifts: '/api/dispatch/mixers/changeDayShifts',
            updateDefaultSchedules: '/api/dispatch/mixers/updateDefaultSchedules',
            removeDefaultSchedules: '/api/dispatch/mixers/removeDefaultSchedules',
            getMapInfo: '/api/dispatch/mixers/getMapInfo'
        },

        orders: {
            timeline: '/api/dispatch/orders/timeline',
            bigGant: '/api/dispatch/orders/bigGant',
            index: '/api/dispatch/orders/index',
            create: '/api/dispatch/orders/create',
            delete: '/api/dispatch/orders/delete',
            update: '/api/dispatch/orders/update'
        }
    },
    mixOrderGroup: {
        getOrder: '/api/mixOrderGroup/getOrder',
        changeStatus: '/api/mixOrderGroup/changeStatus',
        changeTotal: '/api/mixOrderGroup/changeTotal',
        removeOrder: '/api/mixOrderGroup/removeOrder',
        removeCompanyManager: '/api/mixOrderGroup/removeCompanyManager',
        addCompanyManager: '/api/mixOrderGroup/addCompanyManager',
        addService: '/api/mixOrderGroup/addService',
        removeService: '/api/mixOrderGroup/removeService',
        confirmService: '/api/mixOrderGroup/confirmService',
        getAllTariffs: '/api/mixOrderGroup/getAllTariffs',
        checkOneCIntegration: '/api/mixOrderGroup/checkOneCIntegration',
        getCheckOneCIntegration: '/api/mixOrderGroup/getCheckOneCIntegration'
    },
    crm: {
        getOrders: '/api/crm/getOrders',
    },
    crmTasks: {
        index: '/api/crm-tasks/index',
        changeStatus: '/api/crm-tasks/changeStatus',
        store: '/api/crm-tasks/store',
        remove: '/api/crm-tasks/remove',
    },
    crmComments: {
        store: '/api/crm-comments/store',
        remove: '/api/crm-comments/remove',
    },
    lab: {
        getOrders: '/api/lab/getOrders',
        getMixes: '/api/lab/getMixes',
        saveSpec: '/api/lab/saveSpec',
        getSpecGoods: '/api/lab/getSpecGoods',
        getSpecGoodsByGood: '/api/lab/getSpecGoodsByGood',
        createSpecGood: '/api/lab/createSpecGood',
        getSpecGood: '/api/lab/getSpecGood',
        getDefaultSpec: '/api/lab/getDefaultSpec',
        clearDefaultSpec: '/api/lab/clearDefaultSpec',
    },
    logistics: {
        getModule: '/api/logistics/getModule',
    },
    mix: {
        getModule: '/api/mix/getModule',
        getGoods: '/api/mix/getGoods',
        getGoodsCategories: '/api/mix/getGoodsCategories',
        createGood: '/api/mix/createGood',
        updateGood: '/api/mix/updateGood',
        removeGood: '/api/mix/removeGood',
        getOrders: '/api/mix/getOrders',
        getOneOrder: '/api/mix/getOneOrder',

        getOrderById: '/api/mix/orderById',

        getProd: '/api/mix/getProd',
        getCounterparties: '/api/mix/getCounterparties',
        getCounterpartiesGoods: '/api/mix/getCounterpartiesGoods',
        getInfo: '/api/mix/getInfo',
        createOrder: '/api/mix/createOrder',
        removeOrder: '/api/mix/removeOrder',
        getOrderDoc: '/api/mix/getOrderDoc',
        createOrderBatch: '/api/mix/createOrderBatch',
        removeOrderBatch: '/api/mix/removeOrderBatch',
        getOrderEconomy: '/api/mix/getOrderEconomy',
        sendMixOrderToSoft: '/api/mix/sendMixOrderToSoft',
        createOrderGroup: '/api/mix/createOrderGroup',
        removeOrderGroup: '/api/mix/removeOrderGroup',
        getOrderGroups: '/api/mix/getOrderGroups',
        getOrderGroupInfoCreate: '/api/mix/getOrderGroupInfoCreate',
        getOrderGroupsOrders: '/api/mix/getOrderGroupsOrders',
        storeOrderGroups: '/api/mix/storeOrderGroups',
        getMixers: '/api/mix/getMixers',
        createMixer: '/api/mix/createMixer',
        removeMixer: '/api/mix/removeMixer',
        updateActual: '/api/mix/updateActual',
        changeOrderStatus: '/api/mix/changeOrderStatus',
        printOrderDoc: '/api/mix/printOrderDoc',
        printOrderPass: '/api/mix/printOrderPass',
        getGroupOrdersBigTimeline: '/api/mix/getGroupOrdersBigTimeline',
        saveGroupOrdersBigTimeline: '/api/mix/saveGroupOrdersBigTimeline',
        getOneMixerGlonassData: '/api/mix/getOneMixerGlonassData',
        getRequestInfo: '/api/mix/getRequestInfo',
        saveRequest: '/api/mix/saveRequest',
        recalcOrders: '/api/mix/recalcOrders',

        getMixPosts: '/api/mix/getMixPosts',
    },
    mixVehicle: {
        getDriverInfo: '/api/mixVehicle/getDriverInfo',
        getDriverVehicles: '/api/mixVehicle/getDriverVehicles',
        createShift: '/api/mixVehicle/createShift',
    },
    tts: {
        info: '/api/tts/info',
        objects: '/api/tts/objects',
        data: '/api/tts/data',
        dataGetOne: '/api/tts/dataGetOne',
        orders: '/api/tts/orders',
        createOrder: '/api/tts/createOrder',
        changeOrderStatus: '/api/tts/changeOrderStatus',
    },
    admin: {
        registerGod: '/api/admin/registerGod',
        getUsers: '/api/admin/getUsers',
        activateUser: '/api/admin/activateUser',
        function: '/api/admin/function',
    },
    exportExcel: {
        exportWeighingReport: '/api/exportExcel/exportWeighingReport',
        exportMixReport: '/api/exportExcel/exportMixReport',
        exportGoodsReport: '/api/exportExcel/exportGoodsReport',
        exportProductionGoodsReport: '/api/exportExcel/exportProductionGoods',
        exportDriversReport: '/api/exportExcel/exportDriversReport',
        exportVehiclesReport: '/api/exportExcel/exportVehiclesReport',
        exportMixOrderReport: '/api/exportExcel/exportMixOrderReport',
    },
    report: {
        weighingValuesReport: '/api/report/weighingValuesReport',
        mixOrdersReport: '/api/report/mixOrdersReport',
        mixDriversReport: '/api/report/mixDriversReport',
        getWeighingData: '/api/report/getWeighingData',
        getMixData: '/api/report/getMixData',
        goodsReport: '/api/report/goodsReport',
        mixGroupOrders: '/api/report/mixGroupOrders',
        productionGoods: '/api/report/productionGoods',
        mixVehicleReport: '/api/report/mixVehicleReport',
        getGoodsReportMixOrders: '/api/report/raw-materials-report/get-mix-orders',
        postGoodsReportMixOrders: '/api/report/raw-materials-report/save-mix-orders',
    },
    analytics: {
        get: '/api/analytics/get'
    },
    payment: {
        checkPromoCode: '/api/payment/checkPromoCode',
        pay: '/api/payment/pay',
        getBankRequisites: '/api/payment/getBankRequisites',
    },
    whatsapp: {
        addProfile: '/api/whatsapp/add-profile',
        getQrCode: '/api/whatsapp/get-qr-code'
    },
    dictionary: {
        mixOrderStatuses: '/api/dictionary/mixOrderStatuses',
        mixOrderTypes: '/api/dictionary/mixOrderTypes',
        mixOrderPaymentMethods: '/api/dictionary/mixOrderPaymentMethods',
        companyIndividualTypes: '/api/dictionary/companyIndividualTypes',
        companyTypes: '/api/dictionary/companyTypes',
        addresses: '/api/dictionary/addresses',
    },
    print: {
        docs: '/api/print/docs',
        downloadDoc: '/api/print/downloadDoc',
        printDoc: '/api/print/printDoc',
        downloadCustomDoc: '/api/print/downloadCustomDoc',
    },
    exportDocument: {
        generateTTN: '/api/exportDocument/generateTTN',
    },
    driverApp: {
        index: '/api/driverApp/index',
        changeStatus: '/api/driverApp/changeStatus',
    },
    mixOrderGroupsLogs: {
        index: '/api/mixOrderGroupsLogs/index',
    },
    impersonate: {
        asUser: '/api/impersonate/asUser',
        leaveUser: '/api/impersonate/leaveUser',
    },
    customDocument: {
        index: '/api/customDocument/index',
        delete: '/api/customDocument/delete',
        uploadCustomDocument: '/api/customDocument/uploadCustomDocument',
    },
    accountEvents: {
        index: '/api/accountEvents/index',
        read: '/api/accountEvents/read',
        readAll: '/api/accountEvents/readAll',
    }
}
