<template>
    <el-radio-group class="toggle-button" :value="activeTab" @change="updateValue">
        <el-radio-button v-for="item in items" :key="item" :value="item">
                {{ item }}
        </el-radio-button>
    </el-radio-group>
</template>

<script>
export default {
    name: "SwitchButton",
    props: ['activeTab', 'items', 'icon', 'isRounded'],
    methods: {
        updateValue(newValue) {
            this.$emit('changeTab', newValue); // Emit the updated value back to the parent
        }
    }
}
</script>
