<template>
    <Auth title="CRM" :tabs="tabs" :settings="$route.params.id">
        <div class="card">
            <div class="card-body dispatchCardBody">
                <div class="group-orders-summary">
                    <div class="group-orders-summary-body">
                        <el-row :gutter="30" style="gap: 8px">
                            <el-col :sm="5">
                                <el-date-picker
                                    v-model="date"
                                    :type="'date'"
                                    :format="'D MMMM'"
                                    :clearable="false"
                                    :editable="false"
                                    :size="'default'"
                                    @change="moduleGet"
                                />
                            </el-col>
                            <el-col :sm="4">
                                <div class="order-groups d-flex" style="gap: 10px;position: relative;top: 5px;">
                                    <img :src="require('@/assets/open_box.svg')" class="order-groups__icon"/>
                                    <div>
                                        <AnimateNumber :count="ordersCount ?? 0"/> {{ declension(ordersCount ?? 0, 'заказ') }}
                                    </div>
                                </div>
                            </el-col>
                            <el-col :sm="4">
                                <div class="order-groups d-flex" style="gap: 10px;position: relative;top: 5px;">
                                    <img :src="require('@/assets/box.svg')" class="order-groups__icon"/>
                                    <div>
                                        <AnimateNumber :count="volume ?? 0" :fraction="1"/> м³
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <MixOrderGroupTable
                    :loading="loading"
                    :order-groups="values"
                    @showActionDialog="showActionDialog"
                    @showAdd="showAdd"
                    :crm="true"
                />

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import CRMTabs from "@/mixins/tabs/CRMTabs";
import moment from "moment";
import {httpService} from "@/services/http.service";
import MixOrderGroupTable from "@/views/components/MixOrderGroup/MixOrderGroupTable.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import AnimateNumber from "@/views/components/AnimateNumber.vue";
import mixOrderGroupStatusDeclension from "@/mixins/mixOrderGroupStatusDeclension";
import {mapState, mapStores} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import {useEchoStore} from "@/store/Echo";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'CRMOrders',
    computed: {
        ...mapStores(useEchoStore),
        ...mapState(useOrderGroupsStore, {
            filters: 'getFilters',
        }),
        moment() {
            return moment
        },
        ordersCount() {
            return this.values.length
        },
        volume() {
            let sum = 0
            this.values.forEach(el => sum = sum + el.total)
            return this.formatNumber(sum, 1)
        }
    },
    mixins: [CRMTabs, mobileCheckMixin, mixOrderGroupStatusDeclension, functionsMixin],
    data() {
        return {
            loading: true,
            createOrderDrawer: false,
            values: [],
            date: moment(),
        }
    },
    components: {
        AnimateNumber,
        MixOrderGroupTable,
        Auth,
    },
    inject: [
        'api'
    ],
    async created() {
        this.date = this.filters['filter[date]']
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true

            httpService().post(this.api.crm.getOrders, {
                account_modules_id: this.$route.params.id,
                module_key: 'crm',
                date: this.filters['filter[date]']
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.orders.sort((a, b) => {
                        const statusGroups = {
                            confirmed: 1,
                            work: 1,
                            done: 2,
                            completed: 2,
                            pause: 3,
                            failed: 4,
                        };

                        if (statusGroups[a.status] !== statusGroups[b.status]) {
                            return statusGroups[a.status] - statusGroups[b.status];
                        }

                        return a.firstOrderTimeDelivery - b.firstOrderTimeDelivery;
                    })

                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        currentDayName() {
            const format = "YYYY-MM-DD";
            const date = moment(this.filters['filter[date]']).toDate()
            const currentMomentDate = moment(date).format(format)
            const today = moment().format(format);

            if (currentMomentDate === today) {
                return 'Сегодня'
            }

            const tomorrow = moment().add({day: 1}).format(format);

            if (currentMomentDate === tomorrow) {
                return 'Завтра'
            }

            const lowerDate = moment(date, null, 'ru').format('dddd')
            return lowerDate.substring(0, 1).toUpperCase() + lowerDate.substring(1)
        },
        changeStatus(id) {
            this.loading = true

            httpService().post(this.api.mixOrderGroup.changeStatus, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.moduleGet()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        setCreateOrderDrawer() {
            this.createOrderDrawer = !this.createOrderDrawer
        },
        changeDate(date) {
            this.filters['filter[date]'] = moment(date).utcOffset(0, true).startOf('day')
        },
    },
    watch:{
        'date': {
            handler: function (value) {
                this.filters['filter[date]'] = moment(value).format('YYYY-MM-DD')
            },
            deep: true,
            immediate: false
        },
    },
}
</script>

<style scoped>

</style>
