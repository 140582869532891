<template>
    <div>
        <div class="textMedium textSM neutral900 mb-10">Добавлена новая отгрузка</div>
        <div>
            <div class="neutral900 textRegular textXS mb-5">
                <span class="neutral300 mr-5">{{ mixOrderNumber ? `Отгрузка №${mixOrderNumber}` : '' }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import paymentMethods from "@/mixins/paymentMethods";

export default {
    name: "MixOrderGroupNewMixOrder",
    mixins: [functionsMixin, paymentMethods],
    props: ['log'],
    computed: {
        mixOrderNumber() {
            return JSON.parse(this.log.content.info).mixOrderId || '';
        }
    }
}
</script>

<style scoped>

</style>
