<template>
    <svg
        :class="[{ currentColor: currentColor }]"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        x="0"
        y="0"
        viewBox="0 0 24 24"
        xml:space="preserve"
        class=""
    >
        <g>
            <path
                d="M7.25 7c0-2.619 2.131-4.75 4.75-4.75S16.75 4.381 16.75 7s-2.131 4.75-4.75 4.75S7.25 9.619 7.25 7zM15 13.25H9c-3.17 0-5.75 2.58-5.75 5.75A2.752 2.752 0 0 0 6 21.75h12A2.752 2.752 0 0 0 20.75 19c0-3.17-2.58-5.75-5.75-5.75z"
                fill="#505968"
                opacity="1"
                data-original="#505968"
                class=""
            ></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: "SolidUserProfileIcon",
    props: ["currentColor", "strokeWidth"],
};
</script>

<style scoped>
.currentColor {
    path {
        fill: currentColor;
    }
}
</style>
