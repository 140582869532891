<template>
    <el-form-item
        :label="label"
        :label-width="!label ? '0' : ''"
        :class="{'error-border': validationMessage, 'is-required': required}"
    >
        <slot></slot>
        <el-input
            class="custom-input"
            v-model="itemValue"
            @input="handleInput"
            type="number"
            :placeholder="placeholder"
            :disabled="disabled"
            :precision="precision ?? 0"
            :step="step ?? '0.01'"
            :max="max"
            :min="min"
            @keypress="preventNegative"
            @focusout="$emit('focusoutAction')"
        />
        <p
            v-if='validationMessage'
            class="formInput__validation-message m-0">
            {{validationMessage}}
        </p>
    </el-form-item>
</template>

<script>

export default {
    name: "FormNumber",
    props: [
        'modelValue',
        'label',
        'placeholder',
        'required',
        'precision',
        'step',
        'max',
        'min',
        'disabled',
        'validationMessage'
    ],
    data () {
        return {
            itemValue: this.modelValue
        }
    },
    methods: {
         preventNegative(e) {
            if (e.key === '-') {
                e.preventDefault();
            }
        },
        handleInput(val) {
            if(this.min > 0 && val < this.min) {
                this.itemValue = null
            }
            this.$emit('update:modelValue', this.itemValue)
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
