<template>
    <article @click="event.status === 'new' ? handleUpdateEvents() : null"
             :class="['event-item d-flex gap-10 align-center', `event-item_style_${color}`]">
        <div class="event-item__square p-12">
            <TriangleWarningIcon
                class="event-item__icon_warning"
                v-if="event.priority === 'warning' || event.priority === 'emergency'"
                width="24"
                height="24"
            />
            <Calendar
                v-else
                class="event-item__icon_calendar"
                width="24"
                height="24"
            />
        </div>
        <div class="event-item__body d-flex f-direction-column gap-4">
            <div class="event-item__content-header d-flex justify-between align-center gap-6">
                <p class="event-item__content-title textSM neutral900 m-0">{{ title }}</p>
                <p class="event-item__content-date textXS neutral300 m-0">{{ time }}</p>
            </div>
            <div class="event-item__content-bottom align-center d-flex justify-between gap-6 flex-1">
                <p class="event-item__number textXS neutral900 m-0">Отгрузка - #{{ event.mix_order_id }}</p>
                <p class="textXS neutral600 m-0 cutOverflow">{{ event.company_name }}</p>
            </div>

        </div>
    </article>
</template>

<script>
import {httpService} from "@/services/http.service";
import TriangleWarningIcon from "@/views/components/Icons/TriangleWarningIcon";
import Calendar from "@/views/components/Icons/CalendarAlt";
import functionsMixin from "@/mixins/functionsMixin";
import moment from 'moment';

export default {
    name: "EventItem",
    props: ['event', 'mixOrderGroups'],
    mixins: [functionsMixin],
    components: {Calendar, TriangleWarningIcon},
    data() {
        return {
            titles: {
                delay_new: 'Начало загрузки задерживается',
                delay_loading: "Загрузка машины задерживается",
                delay_delivery: "Доставка задерживается",
                delay_on_object: "Машина задерживается на объекте",
                delay_done: "Машина задерживается по пути на завод",
                update_on_object: 'На объекте'
            }
        }
    },
    computed: {
        title() {
            return this.titles[this.event.type]
        },
        time() {
            return moment(this.event.created_at).tz(this.event.tz).format('HH:mm');
        },
        color() {
            if (this.event.status === 'done') return 'grey'
            switch (this.event.priority) {
                case 'warning':
                    return 'yellow'
                case 'emergency':
                    return 'red'
                default:
                    return 'blue'
            }
        },
    },
    methods: {
        async readEvent(eventId) {
            await httpService().post(this.api.accountEvents.read, {
                eventId: eventId,
            }).then(() => {}).catch(error => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        async handleUpdateEvents() {
            if (this.mixOrderGroups.length) {
                const mixOrderGroup = this.mixOrderGroups.find((item) => item.id == this.event.mix_order_group_id);
                if (mixOrderGroup) {
                    await this.readEvent(this.event.id);
                    this.$emit('updateEvents', mixOrderGroup, this.event.mix_order_id);
                }
            }
        },
    }
}
</script>
