<template>
    <div style="width: 160px;">
    <el-tooltip class="w-fit ml-auto" :content="tooltip" placement="top" :disabled="!tooltip">
        <div class="d-flex d-flex-end gap-8" v-if="order.doc">
            <div class="d-flex-end f-direction-column gap-4 textXS neutral200">
                <span>В АСУ - <span
                    :class="[docColor]">#{{ order.doc }}</span></span>
            <div class="d-flex d-flex-end align-center gap-8">
                <template v-if="order.status === 'new'">
                    <el-button
                        class="operatorOrderButton"
                        type="primary"
                        :icon="icons.videoPlay"
                        @click="this.$emit('openMixOrderAction', order)"
                    />
                </template>
                <template v-else-if="order.status === 'loading'">
                    <div class="d-flex-end">
                        <SmallLoader class="mr-5"/>
                        <span class="group-orders-status-text group-orders-status-text-unconfirmed">
                        Загрузка
                    </span>
                    </div>
                </template>
                <template v-else>
                    <span class="group-orders-status-text group-orders-status-text-done">
                        Выполнена
                    </span>
                </template>
            </div>
            </div>
            <el-button @click="() => this.$emit('printOrder', order)"
                v-if="!mobile && operatorPrint && order.confirm" class="ci-btn operatorOrderPrintButton">
                <PrinterIcon />
            </el-button>
        </div>
        <div v-else class="d-flex-end f-direction-column gap-4 w-fit ml-auto textXS neutral200">
            <template v-if="order.confirm">Не отправлена в АСУ</template>
            <div class="d-flex d-flex-end gap-8" style="width: 88px">
                <el-button v-if="order.confirm" class="operatorOrderButton" disabled
                           type="primary"
                           :icon=icons.videoPlay
                />
                <el-button @click="() => this.$emit('printOrder', order)"
                    v-if="!mobile && operatorPrint && order.confirm" class="ci-btn operatorOrderPrintButton" >
                    <PrinterIcon />
                </el-button>
            </div>
        </div>
    </el-tooltip>
    </div>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin'
import SmallLoader from "@/views/components/Loaders/SmallLoader.vue";
import PrinterIcon from "@/views/components/Icons/PrinterIcon.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "OperatorElcon",
    props: ['order', 'operatorPrint'],
    components: {SmallLoader, PrinterIcon},
    mixins: [iconsMixin, mobileCheckMixin],
    data() {
        return {}
    },
    created() {
    },
    computed: {
        tooltip() {
            let content = ''
            if(!this.order.confirm
            || this.order.status === 'done'
            || this.order.status === 'delivery'
            || this.order.doc && this.order.status === 'new') {
                return content
            }
            if(this.order.status === 'loading') {
                content = 'Ожидание завершения от АСУ'
            }
            else if(!this.order.doc) {
                content = 'Идет отправка данных'
            } else if(this.order.doc) {
                content = 'Ожидание начала загрузки'
            }
        return content
        },
        docColor() {
            const {status} = this.order;
                return status !== 'new' && status !== 'loading' ? 'success400'
                : status === 'loading' ? 'primary600' : 'neutral200'
        }
    },
}
</script>

<style scoped>

</style>
