<template>
    <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.5 5H12.5"
            stroke="white"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.875 2.5H3.125C2.77982 2.5 2.5 2.77982 2.5 3.125V11.875C2.5 12.2202 2.77982 12.5 3.125 12.5H11.875C12.2202 12.5 12.5 12.2202 12.5 11.875V3.125C12.5 2.77982 12.2202 2.5 11.875 2.5Z"
            stroke="white"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10 1.25V2.5"
            stroke="white"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M5 1.25V2.5"
            stroke="white"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

