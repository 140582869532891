<template>
    <p class="events-empty textLG neutral300 text-center mb-15 mt-15">
        <span class="events-empty__loader" v-if="loading">
            События загружаются...
            <ReportLoader class="loader"/>
        </span>
        <span v-else>Нет событий</span>
    </p>
</template>

<script>
import ReportLoader from "@/views/components/Loaders/ReportLoader";

export default {
    name: "EventsEmptyOrLoading",
    props: ['loading'],
    components: {ReportLoader}
};
</script>

<style lang="scss" scoped>
.events-empty {

    &__loader {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: center;
    }

    svg.loader {
        width: 32px;
        height: 32px;

        &:deep(path) {
            fill: currentColor;
        }
    }
}
</style>
