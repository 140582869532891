<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
             <DrawerHeadTitle class="mix-create-title" :title="modalTitle">
                </DrawerHeadTitle>
            <div v-if="!showForm">
                <div class="textSM textRegular400 neutral500 sub-title">Выберите ТС из уже созданных в системе или добавьте новое ТС</div>

                <el-input
                    class="w-100 mb-15"
                    v-model="search"
                    placeholder="Поиск"
                    clearable
                />

                <el-table :data="displayData" style="width: 100%" @row-click="selectVehicle" class="pointer" :show-header='false'>
                    <template v-slot:empty>
                        <div class="text-center mt-30 mb-30">
                            <NoDataIcon/>
                        </div>
                    </template>
                    <el-table-column prop="rent" label="" width="20">
                        <template v-slot="vehicle">
                            <span :class="'textLG textMedium ' + (vehicle.row.rent ? 'neutral500' : 'neutral100')">
                                А
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="gidrolotok" label="" width="20">
                        <template v-slot="vehicle">
                            <span :class="'textLG textMedium ' + (vehicle.row.gidrolotok ? 'neutral500' : 'neutral100')">
                                Г
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="gps_online" label="" width="20">
                        <template v-slot="vehicle">
                            <span class="textLG textMedium">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.99935 9.33329C8.73575 9.33329 9.33268 8.73636 9.33268 7.99996C9.33268 7.26356 8.73575 6.66663 7.99935 6.66663C7.26295 6.66663 6.66602 7.26356 6.66602 7.99996C6.66602 8.73636 7.26295 9.33329 7.99935 9.33329Z" :stroke="vehicle.row.gps_vehicle_id ? '#1984e6' : '#c9ccd0'" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.334 12C14.174 10.8867 14.6673 9.5 14.6673 8C14.6673 6.5 14.174 5.11333 13.334 4" :stroke="vehicle.row.gps_vehicle_id ? '#1984e6' : '#c9ccd0'" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M2.66732 4C1.82732 5.11333 1.33398 6.5 1.33398 8C1.33398 9.5 1.82732 10.8867 2.66732 12" :stroke="vehicle.row.gps_vehicle_id ? '#1984e6' : '#c9ccd0'" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.1992 10.4C11.6992 9.7333 11.9993 8.89996 11.9993 7.99996C11.9993 7.09996 11.6992 6.26664 11.1992 5.59998" :stroke="vehicle.row.gps_vehicle_id ? '#1984e6' : '#c9ccd0'" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.80001 5.59998C4.30001 6.26664 4 7.09996 4 7.99996C4 8.89996 4.30001 9.7333 4.80001 10.4" :stroke="vehicle.row.gps_vehicle_id ? '#1984e6' : '#c9ccd0'" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="number" label="">
                        <template v-slot="vehicle">
                            <span class="ml-30 textLG textMedium neutral900 cutOverflow">
                                {{getVehicleNumber(vehicle.row.number)}}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />

                <el-button
                    type="primary"
                    @click="() => showForm = true"
                    class="vehicleButton mt-32 br-8 textSM textMedium"
                >
                    Добавить новое ТС
                </el-button>
            </div>


            <el-form
                v-if="showForm"
                ref="form"
                size="small"
                class="dispatch-order-groups-create-form new-mixer"
                label-position="left"
                label-width="200px"
                :model="form"
            >

                <!-- <FormInputSuggestions
                    prop="vehicleNumber"
                    v-model.vehicleNumber="form.vehicleNumber"
                    label="Регистрационный номер"
                    placeholder="Пр.: А7500АА750"
                    :items="vehicles"
                    :required="true"
                /> -->
                 <FormInput
                    v-model.vehicleNumber="form.vehicleNumber"
                    label="Регистрационный номер"
                    placeholder="Пр.: Е290КХ750"
                    :required="true"
                    :validation-message="validationMessages.vehicleNumber ? validationMessages.vehicleNumber : null"
                />
                <el-form-item label="GPS">
                    <el-switch v-model="form.gps_status" label="GPS" size="large"/>
                </el-form-item>

                <FormNumber
                    v-if="form.gps_status"
                    v-model="form.gps_imei"
                    label="GPS идентификатор"
                    min="0"
                    placeholder="Пр.: 845793845783877"
                    :required="true"
                    :validation-message="validationMessages.gps_imei ? validationMessages.gps_imei : null"
                    :step="1"
                />

                <el-form-item label="Арендное ТС">
                    <el-switch v-model="form.rent" label="Арендованный ТС" size="large"/>
                </el-form-item>


                <FormSelect
                    popper-class="driverFormItem"
                    v-if="form.rent"
                    v-model="form.rent_company_id"
                    label="Контрагент арендного ТС"
                    placeholder="Выберите контрагента"
                    :items="companies"
                    new-item="company"
                    style="line-height:24px"
                    :new-item-data="form.rent_company_id"
                    :required="true"
                    :validation-message="validationMessages.rent_company_id ? validationMessages.rent_company_id : null"
                />

                <FormNumber
                    v-if="type === 'mix'"
                    v-model="form.volume"
                    label="Объём миксера (м³)"
                    placeholder="Пр.: 15"
                    min="1"
                    :required="true"
                    :step="1"
                    :validation-message="validationMessages.volume ? validationMessages.volume : null"
                />

                <FormNumber
                    v-if="type === 'pump'"
                    label="Длина насоса (м)"
                    placeholder="Пр.: 15"
                    v-model="form.pump_length"
                    min="1"
                    :required="true"
                    :step="1"
                    :validation-message="validationMessages.pump_length ? validationMessages.pump_length : null"
                />

                <el-form-item label="Количество осей" class='driverFormItem'>
                    <el-select
                        v-model="form.axle"
                        @clear="() => form.axle = null"
                        clearable
                        placeholder="Пр.: 3">
                        <el-option v-for="axle in axleAmount"
                            :key="axle"
                            :label="axle"
                            :value="axle">
                            <span style="float: left">{{ axle }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>

                <FormInput
                    v-model="form.name"
                    label="Название авто для документов"
                    placeholder="Пр.: Автобетоно-ль на шасси Камаз"
                />

                <el-form-item label="Наличие гидролотка" v-if="type === 'mix'">
                    <el-switch v-model="form.gidrolotok" label="Наличие гидролотка" size="large"/>
                </el-form-item>

                <FormSelect
                    extraClass="driverFormItem"
                    v-model="form.driverId"
                    label="Водитель по умолчанию"
                    placeholder="Выберите водителя"
                    :items="drivers"
                    :new-item="type === 'mix' ? 'mixDriver' : 'pumpDriver'"
                    :new-item-data="form.driverId"
                    :required="true"
                    :validation-message="validationMessages.driverId ? validationMessages.driverId : null"
                />

                <el-form-item label="Время работы по умолчанию" :required="true">
                    <div class="d-flex-full w-100">
                        <el-input
                            :class="!isWorkingHoursValid || validationMessages.workingHours ? 'error-border': ''"
                            v-model="form.startTime"
                            type="time"
                        />
                        <span class="ml-10 mr-10">&#8212;</span>
                        <el-input
                            :class="!isWorkingHoursValid || validationMessages.workingHours ? 'error-border': ''"
                            v-model="form.endTime"
                            type="time"
                        />

                    </div>
                    <div class="formInput__validation-message" v-if="!isWorkingHoursValid || validationMessages.workingHours">
                        {{ !isWorkingHoursValid ? 'Часы работы не могут совпадать' : validationMessages.workingHours }}
                    </div>
                </el-form-item>

                <el-form-item class="driverFormItem" label="Заводы по умолчанию" :required="true">
                    <el-select-v2
                        v-model="form.mixers"
                        :options="mixers"
                        :props="{label:'name',value:'id'}"
                        placeholder="Укажите заводы"
                        filterable
                        multiple
                    >
                    </el-select-v2>
                    <div class="formInput__validation-message" v-if="validationMessages.mixers">
                        {{ validationMessages.mixers }}
                    </div>
                </el-form-item>

            </el-form>

        </template>

        <template v-slot:footerOneButton>

            <div class="d-flex gap-8">
                <el-button
                    :icon="icons.arrowLeft"
                    class="vehicleButton br-8 w-fit m-0"
                    v-if="showForm"
                    @click="returnToPrevPage">
                    Выбрать другое ТС
                </el-button>

                <el-button
                    type="primary"
                    class="vehicleButton br-8"
                    v-if="showForm"
                    :loading="loading"
                    :disabled="isSubmitDisabled"
                    @click="submitForm">
                    Добавить {{ type === 'mix' ? 'миксер' : 'насос' }}
                </el-button>
            </div>
        </template>
    </DrawerFramework>
</template>

<script>
import FormNumber from "@/views/components/Form/FormNumber.vue";
import DrawerFramework from "@/views/components/Drawer/DrawerFramework.vue";
import DrawerHeadTitle from "@/views/components/Drawer/DrawerHeadTitle.vue";
// import FormInputSuggestions from "@/views/components/Form/FormInputSuggestions.vue";
import iconsMixin from '@/mixins/iconsMixin'
import {httpService} from "@/services/http.service";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import NoDataIcon from "@/views/components/NoDataIcon.vue";

export default {
    name: "MixerCreate",
    components: {
        NoDataIcon,
        TablePagination,
        FormSelect,
        FormInput,
        // FormInputSuggestions,
        DrawerHeadTitle,
        DrawerFramework,
        FormNumber
    },
    props: ['displayDataFromList','mixes', 'drivers', 'type'],
    mixins: [tableMixin, functionsMixin, iconsMixin],
    inject: ['api'],
    created() {
        this.getVehicles()
        this.mixMixers()
    },
    data () {
        return {
            loading: false,
            showForm: false,
            axleAmount: [2, 3, 4, 5],
            form: {
                id: null,
                axle: null,
                gidrolotok: false,
                vehicleNumber: null,
                volume: null,
                startTime: null,
                endTime: null,
                name: null,
                rent: false,
                rent_company_id: null,
                gps_status: false,
                gps_imei: null,
                mixes: [],
                mixers: [],
                driverId: null,
                pump_length: null,
            },
            vehicles: [],
            mixers: [],
            validationMessages: {
                mixers: '',
                driverId: '',
                vehicleNumber: '',
                volume: '',
                gps_imei: '',
                rent_company_id: '',
                pump_length: '',
                workingHours: ''
            },
        }
    },
    computed: {
        // isSubmitDisabled() {
        //     return (!this.form.vehicleNumber || (this.type === 'mix') ? !this.form.volume : !this.form.pump_length || !this.form.driverId) || !this.isWorkingHoursValid
        // },
        isWorkingHoursValid() {
            const {startTime, endTime} = this.form
            return !startTime && !endTime ? true : startTime !== endTime ? true : false
        },
        modalTitle() {
            const action = (!this.showForm && this.values?.length) || (this.showForm && this.form?.id) ? 'Редактировать' : 'Добавить'
            const type = this.type === 'mix' ? 'миксер' : 'насос'
            return `${action} ${type}`
        }
    },
    methods: {
        mixMixers(){
            let mixers = []
            let mixes = JSON.parse(JSON.stringify(this.mixes))

            mixes.forEach((i)=>{
                mixers = [...mixers, ...i.mixers.map((mixer) => {
                    mixer.name = this.tableTitle(i, mixer)
                    return mixer
                })]
                // mixers = [...mixers, ...i.mixers]
            })

            return this.mixers = mixers
        },
        tableTitle(mix, mixer){
            if (mix?.mixers.length > 1) return mix.name + ' - ' + mixer.name
            return mix.name
        },
        createMixer() {
            this.loading = true

            httpService().post(this.api.dispatch.mixers.createMixer, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                default: true,
                form: {
                    ...this.form,
                    vehicleNumber: this.form.vehicleNumber.replace(/\s/g, '')
                },
                type: this.type
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Добавлен',
                        showClose: true,
                        type: 'success'
                    })
                    this.$emit('closeDrawer', data.mixer)
                    this.$emit('getAllMixers')
                } else {
                    this.loading = false
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            }).catch((error) => {
                const errors = error.response.data.errors

                console.log(error)

                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
                if(!errors){ //Обработка ошибок с бэка
                     this.$message({
                     message: error.response.data.message,
                     showClose: true,
                     type: 'error'
                 })
                }
                this.loading = false
            }).finally(() => {this.loading = false})
        },
        getVehicles() {
            this.loading = true
            httpService().post(this.api.settings.getVehiclesWithoutIntgOrTraccar).then((response) => {
                let data = response.data

                if (data.success)
                    this.vehicles = data.data.sort((a, b) => a.name > b.name ? 1 : -1)

                    const filteredValues = this.vehicles.filter(i => !this.displayDataFromList.includes(i.id))
                    const filteredValuesByType = filteredValues.filter((vehicle) => vehicle.type === this.type)
                    this.values =  filteredValuesByType

                    this.loading = false
                    this.showForm = !this.values.length > 0

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        },
        filterValues() {
            this.filtered = this.values

            if(this.type) this.filtered = this.filtered.filter(item => item.type === this.type)
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        selectVehicle(vehicle) {
            this.form.id = vehicle.id
            this.form.name = vehicle.name
            this.form.vehicleNumber = this.getFormattedVehicleNumber(vehicle.number)
            this.form.axle = vehicle.axle
            this.form.gidrolotok = vehicle.gidrolotok
            this.form.volume = vehicle.volume
            this.form.rent = vehicle.rent
            this.form.rent_company_id = vehicle.rent_company_id
            this.form.gps_status = !!vehicle.gps_vehicle_id
            this.form.gps_imei = vehicle.gps_imei
            this.form.driverId = vehicle.driver_id
            this.form.pump_length = vehicle.pump_length

            this.showForm = true;
        },
        returnToPrevPage() {
            this.form.id = null,
            this.form.axle = null,
            this.form.gidrolotok = false,
            this.form.vehicleNumber = null,
            this.form.volume = null,
            this.form.startTime = null,
            this.form.endTime = null,
            this.form.name = null,
            this.form.rent_company_id = null,
            this.form.gps_status = false,
            this.form.gps_imei = null,
            this.form.mixes = [],
            this.form.mixers = [],
            this.form.driverId = null,
            this.form.pump_length = null,
            this.showForm = false;
        },
        submitForm() {
            const isValid = this.validateCreateForm();
            if(isValid) {
                this.createMixer()
            }
        },
        validateCreateForm() {
            const requiredFields = [
                { condition: !this.form.mixers.length, key: 'mixers', message: 'Укажите заводы по умолчанию' },
                { condition: !this.form.driverId, key: 'driverId', message: 'Укажите водителя по умолчанию' },
                { condition: !this.form.vehicleNumber, key: 'vehicleNumber', message: 'Укажите регистрационный номер' },
                { condition: this.type === 'mix' && !this.form.volume, key: 'volume', message: 'Укажите объём миксера' },
                { condition: this.type === 'pump' && !this.form.pump_length, key: 'pump_length', message: 'Укажите длину насоса' },
                { condition: this.form.gps_status && !this.form.gps_imei, key: 'gps_imei', message: 'Укажите IMEI' },
                { condition: this.form.rent && !this.form.rent_company_id, key: 'rent_company_id', message: 'Укажите контрагента' },
                { condition: !this.form.startTime || !this.form.endTime, key: 'workingHours', message: 'Укажите время работы по умолчанию' }
            ];

            this.validationMessages = {};
            requiredFields.forEach(({ condition, key, message }) => {
                if (condition) this.validationMessages[key] = message;
            });

                return !Object.keys(this.validationMessages).length && this.isWorkingHoursValid;
            },
    },
    watch: {
        'form': {
            handler: function(val) {
                const validationRules = [
                { key: 'mixers', condition: val.mixers.length },
                { key: 'driverId', condition: val.driverId },
                { key: 'vehicleNumber', condition: val.vehicleNumber },
                { key: 'volume', condition: val.volume },
                { key: 'pump_length', condition: val.pump_length },
                { key: 'gps_imei', condition: val.gps_imei || !val.gps_status },
                { key: 'rent_company_id', condition: val.rent_company_id },
                { key: 'workingHours', condition: val.startTime && val.endTime }
            ];

            validationRules.forEach(({ key, condition }) => {
                    if (this.validationMessages[key] && condition) {
                        this.validationMessages[key] = '';
                    }
                });
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
