<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.3277 8.25692C13.395 8.0939 13.395 7.91082 13.3277 7.7478C13.2951 7.66697 13.2471 7.5933 13.1862 7.53096L8.47218 2.81691C8.34716 2.69189 8.17759 2.62165 8.00078 2.62165C7.82397 2.62165 7.6544 2.69189 7.52938 2.81691C7.40435 2.94194 7.33411 3.1115 7.33411 3.28832C7.33411 3.46513 7.40435 3.6347 7.52938 3.75972L11.1073 7.33768L3.28673 7.33297C3.19873 7.33261 3.11152 7.34967 3.03014 7.38319C2.94877 7.4167 2.87483 7.46599 2.8126 7.52822C2.75037 7.59045 2.70107 7.66439 2.66756 7.74577C2.63405 7.82715 2.61698 7.91435 2.61734 8.00236C2.61698 8.09037 2.63405 8.17758 2.66756 8.25895C2.70107 8.34033 2.75037 8.41427 2.8126 8.4765C2.87483 8.53873 2.94877 8.58802 3.03014 8.62154C3.11152 8.65505 3.19873 8.67212 3.28674 8.67175L11.1073 8.66704L7.52938 12.245C7.40435 12.37 7.33411 12.5396 7.33411 12.7164C7.33411 12.8932 7.40435 13.0628 7.52938 13.1878C7.6544 13.3128 7.82397 13.3831 8.00078 13.3831C8.17759 13.3831 8.34716 13.3128 8.47218 13.1878L13.1862 8.47377C13.2471 8.41142 13.2951 8.33775 13.3277 8.25692Z"
            fill="#1984E6"
        />
    </svg>
</template>

<script>
export default {
    name: "ArrowRightIcon",
};
</script>

<style scoped></style>
