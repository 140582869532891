<template>
    <el-form-item :label="label" :label-width="labelWidth" :class="{'error-border': validationMessage, 'is-required': required}">
        <template v-if="newItem === 'company' || newItem === 'companySimple'">
            <div class="inputModal"
                 :class="[
                     {disabled: disabled},
                     {pointer: !disabled},
                     {empty: !itemId},
                     popperClass
                 ]">
                <div class="d-flex">
                    <div class="inputModalWrapper" @click="openSelectCompanyDialog">
                        {{ itemId ? this.getData(itemId, 'company') : this.placeholder }}
                    </div>
                    <div class="inputModalClear" v-if="itemId && !disabled" @click="clearItemId()">
                        <i class="el-icon el-input__icon el-input__clear">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 1024 1024">
                                <path
                                    d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path>
                                <path
                                    d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path>
                            </svg>
                        </i>
                    </div>
                </div>
            </div>
            <p
                v-if='validationMessage'
                class="formInput__validation-message m-0">
                {{validationMessage}}
            </p>
        </template>
        <template v-else-if="newItem === 'good'">
            <div class="inputModal"
                 :class="[
                     {disabled: disabled},
                     {pointer: !disabled},
                     {empty: !itemId},
                 ]">
                <div class="d-flex">
                    <div class="inputModalWrapper" @click="openSelectGoodDialog">
                        {{ itemId ? this.getData(itemId, 'good') : this.placeholder }}
                    </div>
                    <div class="inputModalClear" v-if="itemId" @click="clearItemId()">
                        <i class="el-icon el-input__icon el-input__clear">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 1024 1024">
                                <path
                                    d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path>
                                <path
                                    d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path>
                            </svg>
                        </i>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="newItem === 'document'">
            <div class="inputModal"
                 :class="[
                     {disabled: disabled},
                     {pointer: !disabled},
                     {empty: !itemId},
                 ]">
                <div class="d-flex">
                    <div class="inputModalWrapper" @click="openSelectContractDialog">
                        {{ itemId ? getInfo(this.itemId, this.values) : this.placeholder }}
                    </div>
                    <div class="inputModalClear" v-if="itemId" @click="clearItemId()">
                        <i class="el-icon el-input__icon el-input__clear">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 1024 1024">
                                <path
                                    d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path>
                                <path
                                    d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path>
                            </svg>
                        </i>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="newItem === 'companyAddress'">
            <div class="inputModal"
                 :class="[
                     {disabled: disabled},
                     {pointer: !disabled},
                     {empty: !itemId},
                 ]">
                <div class="d-flex">
                    <div class="inputModalWrapper" @click="openSelectAddressDialog">
                        {{ itemId ? getAddressName : this.placeholder }}
                    </div>
                    <div class="inputModalClear" v-if="itemId" @click="clearItemId()">
                        <i class="el-icon el-input__icon el-input__clear">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 1024 1024">
                                <path
                                    d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path>
                                <path
                                    d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path>
                            </svg>
                        </i>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <el-select
                :class="{'error-border': validationMessage}"
                class="custom-select"
                v-model="itemId"
                :placeholder="placeholder"
                :popper-class="popperClass"
                :clearable="!noClearable"
                :multiple="multiple"
                :collapse-tags="multiple"
                :loading="loading"
                :allow-create="allowCreate"
                :remote="isRemote"
                ref="select"
                :disabled="disabled"
                :remote-method="remoteMethod"
                :size="'default'"
                @change="$emit('changeValue')"
                filterable>


                <NewItemOption v-if="newItemPath" :path="newItemPath"/>
                <el-option
                    v-if="newItem"
                    value="newItemOption"
                    label="newItemOption"
                    class="newItemOption"
                    @click="openNewItemDialog()"
                >
                    <AddRoundIcon/>
                    <span>Создать новый</span>
                </el-option>
                <el-option v-for="(item, index) in values" :key="index" :label="getLabel(item)"
                           :title="getLabel(item)"
                           :alt="getLabel(item)"
                           :value="getValue(item)"></el-option>
            </el-select>
        </template>
        <p v-if='validationMessage' class="formInput__validation-message m-0">
            {{validationMessage}}
        </p>
    </el-form-item>

    <el-dialog
        v-model="dialog"
        :title="getDialogTitle()"
        :append-to-body='true'
        :width="mobile ? '100%' : (newItem === 'companyAddress' ? '65%' : '520px')"
        :destroy-on-close="true"
    >
        <el-form ref="form" v-loading="loading" :model="form" size="small" label-position="top">
            <template v-if="newItem === 'companyManager'">
                <FormInput
                    v-model="form.phone"
                    label="Телефон представителя"
                    placeholder="Укажите телефон"
                    inputMask="+7 ### ###-##-##"
                />
                <FormInput
                    v-model="form.name"
                    label="ФИО представителя"
                    placeholder="Укажите ФИО представителя"
                />
                <hr class="mb-30"/>
                <FormAddButton
                    :loading="loadingButton"
                    title="Добавить"
                    :disable="!form.phone"
                    @action="createCompanyManager()"
                />
            </template>
            <template v-else-if="newItem === 'companyAddress'">
                <AddressDeliveryModalCreate
                    :company-id="newItemData"
                    :company-name="additionalInfo"
                    @onAdd="handleAddAddress"
                />
            </template>
            <template v-else-if="newItem === 'company' || newItem === 'companySimple'">

                <FormDadataINN
                    v-model="form.inn"
                    label="ИНН"
                    placeholder="ИНН или название компании"
                    @info="info"
                />
                <FormInput
                    v-model="form.name"
                    label="Название контрагента"
                    placeholder="Введите полное название контрагента"
                />
                <FormDadataAddressV2
                    v-if="newItem !== 'companySimple'"
                    v-model="form.fact_address"
                    label="Адрес доставки"
                    placeholder="г.Ярославль, ул.Ленина, д.22"
                />
                <hr class="mb-30"/>
                <FormAddButton
                    :loading="loadingButton"
                    title="Добавить контрагента"
                    size="large"
                    @action="createCompany()"
                />

            </template>
            <template v-else-if="newItem === 'good'">

                <FormInput
                    v-model="form.name"
                    label="Название продукции"
                    placeholder="Н-р: Петля арматурная"
                />

                <FormSelect
                    v-model="form.unit"
                    label="Единица продукции"
                    placeholder="Выберите из списка"
                    :items="units"
                />

                <hr class="mb-30"/>
                <FormAddButton
                    :loading="loadingButton"
                    title="Добавить продукцию"
                    @action="createGood()"
                />

            </template>
            <template v-else-if="newItem === 'tire'">

                <FormSelect
                    v-model="form.brand_id"
                    label="Бренд"
                    placeholder="Выберите производителя"
                    :items="brands"
                    :required="true"
                />

                <el-row :gutter="10">

                    <el-col :sm="12">
                        <FormInput
                            v-model="form.sku"
                            label="Уникальный номер (артикул)"
                            placeholder="Введите номер"
                            :required="true"
                        />
                    </el-col>
                    <el-col :sm="12">
                        <FormInput
                            v-model="form.name"
                            label="Дополнительное название"
                            placeholder="Н-р: pilot sport"
                        />
                    </el-col>

                </el-row>

                <FormSelect
                    v-model="form.season"
                    label="Сезонность"
                    placeholder="Выберите сезонность"
                    :items="seasons"
                />

                <el-row :gutter="10">

                    <el-col :sm="12">
                        <FormNumber
                            v-model="form.mileage"
                            label="Текущий пробег"
                            placeholder="Введите пробег"
                            :required="true"
                        />
                    </el-col>
                    <el-col :sm="12">
                        <FormNumber
                            v-model="form.mileage_max"
                            label="Максимальный пробег"
                            placeholder="Введите пробег"
                            :required="true"
                        />
                    </el-col>

                </el-row>

                <el-row :gutter="10">

                    <el-col :sm="8">
                        <FormNumber
                            v-model="form.radius"
                            label="Радиус (число)"
                            placeholder="18"
                            :precision="1"
                            :step="1"
                        />
                    </el-col>
                    <el-col :sm="8">
                        <FormNumber
                            v-model="form.width"
                            label="Ширина"
                            placeholder="220"
                            :precision="0"
                            :step="1"
                        />
                    </el-col>
                    <el-col :sm="8">
                        <FormNumber
                            v-model="form.height"
                            label="Высота"
                            placeholder="65"
                            :precision="0"
                            :step="1"
                        />
                    </el-col>
                </el-row>

                <hr class="mb-15"/>
                <FormAddButton
                    :loading="loadingButton"
                    :w100="true"
                    title="Добавить шину"
                    size="large"
                    @action="createTire()"
                />

            </template>
            <template v-else-if="newItem === 'individual'">

                <FormInput
                    v-model="form.name"
                    label="Имя клиента"
                    placeholder="Укажите имя"
                />

                <FormInput
                    v-model="form.phone"
                    label="Телефон клиента"
                    placeholder="89990001212"
                    inputMask="+7 ### ###-##-##"
                />

                <hr class="mb-15"/>
                <FormAddButton
                    :loading="loadingButton"
                    :disable="!form.name"
                    :w100="true"
                    title="Добавить клиента"
                    size="large"
                    @action="createIndividual()"
                />
            </template>
            <template v-else-if="newItem === 'mixDriver' || newItem === 'pumpDriver'">
                <FormInput
                    v-model="form.name"
                    label="ФИО водителя"
                    placeholder="Укажите имя"
                />

                <FormInput
                    v-model="form.phone"
                    label="Телефон"
                    placeholder="89990001212"
                    inputMask="+7 ### ###-##-##"
                />

                <hr class="mb-15"/>
                <FormAddButton
                    :loading="loadingButton"
                    :disable="!form.name"
                    :w100="true"
                    title="Добавить водителя"
                    size="large"
                    @action="createDriver()"
                />
            </template>
        </el-form>
    </el-dialog>

    <el-dialog
        v-model="selectCompanyDialog"
        title="Выберите компанию"
        :width="dialogSize"
        @close="companyCreateFormShow = false"
        :destroy-on-close="true"
    >
        <template v-if="loadingData">
            <TableDataLoader text="компаний"/>
        </template>
        <template v-else>
            <template v-if="!companyCreateFormShow">

                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-update-button="true"
                    :search-focused="true"
                    create-button-label="Добавить компанию"
                    @moduleGet="getCompaniesData()"
                    @openCreateValueDrawer="() => companyCreateFormShow = true"
                >
                    <template v-slot:afterSearchAndFilter>
                        <TableIntegrationFilter
                            v-if="!this.integrationUnitId"
                            v-model="filters.integrationUnitId"
                            @moduleGet="getCompaniesData"
                            :table="true"
                        />

                        <el-select
                            v-model="filters.companyType"
                            placeholder="Тип компании"
                            size="large"
                            class="filterSelect"
                            style="width: 180px"
                            @change="getCompaniesData()"
                            clearable
                        >
                            <el-option
                                v-for="item in companyTypes"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </template>
                </TableHeadBlock>
                <el-table
                    empty-text="Информация не найдена"
                    class="mt-15 w-100 defaultTableHeight"
                    @current-change="handleCurrentChange"
                    v-loading="loadingTable"
                    :data="companiesData">

                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        class-name="pointer"
                        sortable>
                        <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        class-name="pointer"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral900 cutOverflow">{{
                                    scope.row.short_name ? scope.row.short_name : scope.row.name
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="inn"
                        label="ИНН"
                        class-name="pointer"
                        width="140"
                    >
                        <template v-slot="scope">
                            <span :class="'textMedium textSM ' + (scope.row.inn ? 'primary600' : 'neutral400')">{{
                                    scope.row.inn ?? '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration"
                        label="Интеграция"
                        width="220px">
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <IntegrationLogo
                                    :integration-unit-id="scope.row.integration_unit_id"
                                />
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    :loading="loading"
                    @action="setPage"
                />
            </template>
            <template v-else>
                <el-form label-position="top">
                    <FormDadataINN
                        v-model="form.inn"
                        label="ИНН"
                        placeholder="ИНН или название компании"
                        @info="info"
                    />
                    <FormInput
                        v-model="form.name"
                        label="Название контрагента"
                        placeholder="Введите полное название контрагента"
                    />
                    <hr class="mb-30 mt-30"/>
                    <div>
                        <el-button @click="createCompany()" type="primary">Добавить контрагента</el-button>
                        <el-button @click="() => companyCreateFormShow = false">Отмена</el-button>
                    </div>
                </el-form>
            </template>
        </template>
    </el-dialog>

    <el-dialog
        v-model="selectGoodDialog"
        :title="`Выберите ${this.goodType === 'mix' ? 'рецепт' : 'продукцию' }`"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <template v-if="loadingData">
            <TableDataLoader text="продукции"/>
        </template>
        <template v-else>
            <template v-if="!companyCreateFormShow">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-update-button="true"
                    :disable-create-button="true"
                    :search-focused="true"
                    create-button-label="Добавить продукцию"
                    @moduleGet="getGoodsData()"
                    @openCreateValueDrawer="() => companyCreateFormShow = true"
                >
                    <template v-slot:afterSearchAndFilter v-if="!this.integrationUnitId">
                        <el-select
                            v-model="filters.goodType"
                            placeholder="Тип товара"
                            size="large"
                            class="filterSelect"
                            style="width: 180px"
                            @change="getGoodsData()"
                            v-if="!this.goodType"
                            clearable
                        >
                            <el-option
                                v-for="item in goodTypes"
                                :key="item.key"
                                :label="item.name"
                                :value="item.key"
                            />
                        </el-select>
                        <TableIntegrationFilter
                            v-model="filters.integrationUnitId"
                            @moduleGet="getGoodsData"
                            :table="true"
                        />
                    </template>
                </TableHeadBlock>
                <IntegrationWarningBlock v-if="integrationUnitId" :integration-unit-id="integrationUnitId" :value-type="goodType ?? 'good'" />
                <el-table
                    empty-text="Информация не найдена"
                    class="mt-15 w-100 defaultTableHeight"
                    v-loading="loadingTable"
                    @current-change="handleCurrentChange"
                    :data="goodsData">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        class-name="pointer"
                        sortable>
                        <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        class-name="pointer"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium primary600 cutOverflow">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="Тип"
                        width="150"
                        class-name="pointer"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textRegular neutral400 cutOverflow">{{
                                    getTypeName(scope.row.type)
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration"
                        label="Интеграция"
                        class-name="pointer"
                        width="220px">
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <IntegrationLogo
                                    :integration-unit-id="scope.row.integration_unit_id"
                                />
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    :loading="loading"
                    @action="setPage"
                />
            </template>
            <template v-else>
                <el-form label-position="top">
                    <FormDadataINN
                        v-model="form.inn"
                        label="ИНН"
                        placeholder="ИНН или название компании"
                        @info="info"
                    />
                    <FormInput
                        v-model="form.name"
                        label="Название контрагента"
                        placeholder="Введите полное название контрагента"
                    />
                    <hr class="mb-30 mt-30"/>
                    <div>
                        <el-button @click="createCompany()" type="primary">Добавить контрагента</el-button>
                        <el-button @click="() => companyCreateFormShow = false">Отмена</el-button>
                    </div>
                </el-form>
            </template>
        </template>
    </el-dialog>

    <el-dialog
        v-model="selectContractDialog"
        title="Выберите документ"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <template v-if="loadingData">
            <TableDataLoader :text="documentType === 'contract' ? 'договоров' : 'счетов'"/>
        </template>
        <!-- <template v-else> -->
            <template v-if="!companyCreateFormShow">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-update-button="true"
                    :search-focused="true"
                    create-button-label="Добавить документ"
                    @moduleGet="getContractsData()"
                    @openCreateValueDrawer="() => companyCreateFormShow = true"
                />
                <el-table
                    empty-text="Информация не найдена"
                    class="mt-15 w-100 defaultTableHeight"
                    v-loading="loadingTable"
                    @current-change="handleCurrentChange"
                    :data="displayData">

                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        class-name="pointer"
                        sortable>
                        <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        class-name="pointer"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral900 cutOverflow">{{ scope.row.name ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="date"
                        label="Дата"
                        class-name="pointer"
                        width="140"
                    >
                        <template v-slot="scope">
                            <span class="textSM textRegular neutral400">{{
                                    scope.row.date ? getDateFormDB(scope.row.date, 'date') : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="company_id"
                        label="Контрагент"
                        v-if="!carrierCompany"
                        class-name="pointer"
                    >
                        <template v-slot="scope">
                            <span class="textRegular neutral300 cutOverflow">{{
                                    scope.row.company_id ? scope.row.company?.name : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        label="Сумма"
                        class-name="pointer"
                        width="200"
                    >
                        <template v-slot="scope">
                            <span class="textSM textMedium success400">{{
                                    (scope.row.total && scope.row.total > 0) ? (formatNumber(scope.row.total) + ' руб.') : '-'
                                }}</span>
                        </template>
                    </el-table-column>

                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    :loading="loading"
                    @action="setPage"
                />
            </template>
            <!-- <template v-else>
                <el-form label-position="top">
                    <FormInput
                        v-model="form.name"
                        label="Название"
                        placeholder="Введите название"
                    />
                    <hr class="mb-30 mt-30"/>
                    <div>
                        <el-button @click="createContract()" type="primary">Добавить документ</el-button>
                        <el-button @click="() => companyCreateFormShow = false">Отмена</el-button>
                    </div>
                </el-form>
            </template> -->
        <!-- </template> -->
    </el-dialog>

    <el-dialog
        v-model="selectAddressDialog"
        title="Выберите объект"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <template v-if="loadingData">
            <TableDataLoader text="адресов"/>
        </template>
        <template v-else>
            <template v-if="!companyCreateFormShow">

                <el-row :gutter="15">
                    <el-col :sm="16">

                        <TableHeadBlock
                            v-model="search"
                            :loading="loading"
                            :disable-filter="true"
                            :disable-update-button="true"
                            :disable-create-button="disableCreateButton"
                            :search-focused="true"
                            create-button-label="Добавить адрес"
                            @moduleGet="getAddressData()"
                            @openCreateValueDrawer="() => companyCreateFormShow = true"
                        />
                        <el-table
                            empty-text="Информация не найдена"
                            class="mt-15 w-100 defaultTableHeight"
                            v-loading="loadingTable"
                            @current-change="handleCurrentChange"
                            @cellMouseEnter="hoverAddress"
                            :data="displayData">

                            <el-table-column
                                prop="id"
                                label="#"
                                width="100"
                                class-name="pointer"
                                sortable>
                                <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                label="Название"
                                class-name="pointer"
                                sortable>
                                <template v-slot="scope">
                                    <span class="textSM textMedium neutral900">{{
                                            scope.row.name ?? '-'
                                        }}</span>
                                </template>
                            </el-table-column>
                        </el-table>

                        <TablePagination
                            v-model="pageSize"
                            :total="valueLength"
                            :loading="loading"
                            @action="setPage"
                        />
                    </el-col>
                    <el-col :sm="8">
                        <div class="logisticsInfoMainMap">
                            <div class="map">
                                <YandexMap
                                    :drag-off="true"
                                    :coords="coords"
                                />
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </template>
            <template v-else>
                <el-form label-position="top">
                    <AddressDeliveryModalCreate
                        :company-id="newItemData"
                        :company-name="additionalInfo"
                        @onAdd="handleAddAddress"
                    />
                </el-form>
            </template>
        </template>
    </el-dialog>
</template>

<script>
import NewItemOption from '../NewItemOption'
import functionsMixin from '@/mixins/functionsMixin'
import AddRoundIcon from "@/views/components/Icons/AddRoundIcon.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormAddButton from "@/views/components/Form/FormAddButton.vue";
import {httpService} from "@/services/http.service";
import {mapActions, mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import FormDadataAddressV2 from "@/views/components/Form/FormDadataAddressV2.vue";
import FormDadataINN from "@/views/components/Form/FormDadataINN.vue";
import AddressDeliveryModalCreate from "@/views/components/AddressDeliveryModalCreate.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import goodTypes from "@/mixins/goodTypes";
import YandexMap from '@/views/widgets/map/YandexMap';
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";
import companyTypes from "@/mixins/companyTypes";
import IntegrationWarningBlock from '@/views/components/Blocks/IntegrationWarningBlock.vue'

export default {
    name: 'FormSelect',
    components: {
        TableIntegrationFilter,
        TableDataLoader,
        IntegrationLogo,
        TableHeadBlock,
        TablePagination,
        FormNumber,
        FormDadataINN,
        FormDadataAddressV2,
        FormAddButton,
        FormInput,
        AddRoundIcon,
        NewItemOption,
        AddressDeliveryModalCreate, YandexMap, IntegrationWarningBlock
    },
    mixins: [functionsMixin, mobileCheckMixin, tableMixin, goodTypes, companyTypes],
    inject: ['api'],
    props: [
        'modelValue',
        'items',
        'label',
        'labelWidth',
        'placeholder',
        'newItemPath',
        'noClearable',
        'loadFromStore',
        'multiple',
        'allowCreate',
        'labelKey',
        'valueKey',
        'isRemote',
        'remoteMethod',
        'popperClass',
        'loading',
        'newItem',
        'newItemData',
        'disabled',
        'carrierCompany',
        'companyType',
        'required',
        'additionalInfo',
        'documentType',
        'goodType',
        'invoiceId',
        'contractId',
        'goodIds',
        'validationMessage',
        'integrationUnitId', // фильтр по интеграции,
        'extraClass', // дополнительные классы для стилизации,
        'disableCreateButton' // cкрытие кнопки добавления нового элемента в модалке
    ],
    data() {
        return {
            itemId: this.modelValue,
            loadingButton: false,
            dialog: false,
            filters: {
                goodType:  null,
                companyType: null,
                carrier: null,
                integrationUnitId: null
            },
            form: {
                name: null,
                phone: null,
                inn: null,
                fact_address: null,
                carrier: this.carrierCompany ?? false,
                type: this.companyType !== 'carrier' ? this.companyType : null,
                unit: null,
                brand_id: null,
                radius: null,
                height: null,
                width: null,
                season: 'all-season',
                mileage: 0,
                mileage_max: 0
            },
            brands: [],
            seasons: [{
                id: 'all-season',
                name: 'Всесезонная'
            }, {
                id: 'summer',
                name: 'Летняя'
            }, {
                id: 'winter',
                name: 'Зимняя'
            }],
            selectCompanyDialog: false,
            selectGoodDialog: false,
            loadingData: true,
            companiesData: [],
            goodsData: [],
            loadingTable: false,
            companyCreateFormShow: false,
            selectContractDialog: false,
            contractData: [],
            addressData: [],
            selectAddressDialog: false,
            mapZoom: 13,
            latitude: 0,
            longitude: 0,
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            units: 'getUnits',
            modules: 'getModules',
            integrations: 'getIntegrations',
            goodsFirstRequest: 'getGoodsFirstRequest',
            companiesFirstRequest: 'getCompaniesFirstRequest',
        }),
        coords() {
            if (this.selectAddressDialog) {
                if (!this.latitude || !this.longitude) {

                    if (this.modules.length > 0) {
                        let key = this.$route.meta.moduleKey
                        let id = this.$route.params.id
                        let module = this.modules.find((m) => m.id == id)

                        if (key === 'dispatch' || key === 'crm') {
                            module = this.modules.find((m) => m.accountModuleId == id)
                        }

                        if (module) {
                            if (module.factory) {
                                return [parseFloat(module.factory.latitude), parseFloat(module.factory.longitude)];
                            }
                        }
                    }
                    return [55.75165053565251, 37.61758420869977];
                }

                return [parseFloat(this.latitude), parseFloat(this.longitude)];
            } else {
                return [55.75165053565251, 37.61758420869977];
            }
        },
        getAddressName() {
            if (this.itemId) {
                return this.getInfo(this.itemId, this.values)
            } else {
                return null
            }
        },
    },
    created() {
        if (this.newItem === 'tire') {
            this.getBrands()
        }
        if (this.companyType) {
            this.filters.companyType = this.companyType
            this.filters.companyType === 'carrier' ? this.filters.carrier = true : false
        }
        if (this.goodType) {
            this.filters.goodType = this.goodType
        }
    },
    methods: {
        ...mapActions(useCommonStore, [
            'pullCompanies',
            'pullGoods',
            'pullIntegrations',
        ]),
        getLabel(item) {
            if (this.labelKey) {
                if (this.newItem === 'tire') {
                    let name = this.deepFind(item, 'name')
                    let sku = this.deepFind(item, 'sku')
                    let label = name + ' (' + sku + ')'
                    if (label) {
                        return label
                    }
                } else {
                    let label = this.deepFind(item, this.labelKey);
                    if (label) {
                        return label
                    }
                }
            }

            if (!this.loadFromStore) {
                return item.name
            } else {
                let param = this.loadFromStore
                return this.getData(item[param], 'good')
            }
        },
        getBrands() {

            httpService().post(this.api.tires.getBrands).then((response) => {
                let data = response.data

                if (data.success) {
                    this.brands = data.values
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        openNewItemDialog() {
            this.dialog = true
            this.itemId = null
            this.form = {
                name: null,
                phone: null,
                inn: null,
                fact_address: null,
                carrier: this.carrierCompany ?? false,
                type: this.companyType !== 'carrier' ? this.companyType : null,
                unit: null,
                brand_id: null,
                radius: null,
                height: null,
                width: null,
                season: 'all-season',
                mileage: 0,
                mileage_max: 0
            }
        },
        openSelectCompanyDialog() {
            if (!this.disabled) {
                let storeItems = this.companiesFirstRequest?.companies?.data ?? []
                if (this.page === 1 && storeItems.length > 0 && !this.companyType) {
                    this.loadingData = false
                    this.loadingTable = false
                    this.companiesData = this.companiesFirstRequest.companies.data
                    this.valueLength = this.companiesFirstRequest.companies.total
                } else {
                    this.getCompaniesData()
                }
                this.search = null
                this.filters.integrationUnitId = null
                this.selectCompanyDialog = true
            }
        },
        openSelectGoodDialog() {
            if (!this.disabled) {
                this.search = null
                this.filters.goodType = null
                this.filters.integrationUnitId = this.integrationUnitId ?? null
                if (this.goodType) {
                    this.filters.goodType = this.goodType
                }
                let storeItems = this.goodsFirstRequest?.goods?.data ?? []
                if (this.page === 1 && storeItems.length > 0 && !this.invoiceId && !this.contractId && !this.goodIds && !this.integrationUnitId && !this.goodType) {
                    this.loadingData = false
                    this.loadingTable = false
                    this.goodsData = this.goodsFirstRequest.goods.data
                    this.valueLength = this.goodsFirstRequest.goods.total
                } else {
                    this.goodsData = []
                    this.getGoodsData()
                }
                this.selectGoodDialog = true
            }
        },
        handleCurrentChange(val) {
            if (val) {
                this.selectItem(val.id)
            }
            this.$emit('getChangedValue', val)
        },
        openSelectContractDialog() {
            if (!this.disabled) {
                this.search = null
                this.page = 1
                if (this.page === 1 && this.values.length === 0) {
                    this.getContractsData()
                }
                this.selectContractDialog = true
            }
        },
        openSelectAddressDialog() {
            if (!this.disabled) {
                this.search = null
                this.page = 1
                if (this.page === 1 && this.values.length === 0) {
                    this.getAddressData()
                }

                if (this.itemId) {
                    this.values.sort((a, b) => {
                        if (a.id === this.itemId) return -1;
                        if (b.id === this.itemId) return 1;
                        return 0;
                    });
                }

                this.companyCreateFormShow = false
                this.selectAddressDialog = true
            }
        },
        getCompaniesData() {
            this.loadingTable = true
            httpService().post(this.api.company.get, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                filter: {
                    ...this.filters,
                    companyType: this.filters.companyType !== 'carrier' ? this.filters.companyType : null,
                    carrier: this.filters.companyType === 'carrier' ? true : null,
                },
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.companiesData = data.companies.data
                    this.valueLength = data.companies.total
                    this.loadingData = false
                    this.loadingTable = false
                }
            })
        },
        getGoodsData() {
            this.loadingTable = true
            httpService().post(this.api.good.get, {
                invoiceId: this.invoiceId,
                contractId: this.contractId,
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                filter: this.filters,
                goodIds: this.goodIds,
                allData: true
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.goodsData = data.goods.data
                    this.valueLength = data.goods.total
                    this.loadingData = false
                    this.loadingTable = false
                }
                //this.$emit('update:modelValue', this.goodsData.find(el => el.id === this.modelValue)?.id)
            })
        },
        getContractsData() {
            this.loadingTable = true
            httpService().post(this.api.document.getAll, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                companyId: this.carrierCompany,
                type: this.documentType,
                all: true,
                simple: true,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.items
                    this.valueLength = data.items.length
                    this.loadingData = false
                    this.loadingTable = false
                }
            })
        },
        getAddressData() {
            this.loadingTable = true
            httpService().post(this.api.company.getCompanyAddresses, {
                companyId: this.newItemData,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                full: true
            }).then((response) => {

                let data = response.data

                if (data.success) {
                    this.values = data.items
                    this.valueLength = data.items.length
                    this.loadingData = false
                    this.loadingTable = false
                }

                if (this.itemId) {
                    let elem = this.values.find(el => el.id === this.itemId)
                    if (elem) {
                        this.latitude = elem.latitude
                        this.longitude = elem.longitude
                    }
                    this.values.sort((a, b) => {
                        if (a.id === this.itemId) return -1;
                        if (b.id === this.itemId) return 1;
                        return 0;
                    });
                }
            })
        },
        selectItem(id) {
            this.itemId = id
            this.$emit('changeValue')
            this.selectCompanyDialog = false
            this.selectGoodDialog = false
            this.selectAddressDialog = false
            this.selectContractDialog = false
        },
        getValue(item) {
            if (this.valueKey) {
                let value = this.deepFind(item, this.valueKey);
                if (value) {
                    return value
                }
            }

            return item.id
        },
        filterValues() {
            if(this.newItem === 'companyAddress' && this.items) {
                this.filtered = this.items
            } else this.filtered = this.values
            if (this.search) {
                if (this.newItem === 'companyAddress') {
                    this.filtered = this.filtered.filter(data => !this.search || (data.name.toLowerCase().includes(this.search.toLowerCase()) || data.address.toLowerCase().includes(this.search.toLowerCase())))
                } else {
                    this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
                }
            }

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        getDialogTitle() {
            if (this.newItem === 'companyManager') return 'Добавление представителя контрагента'
            if (this.newItem === 'companyAddress') return 'Добавление адреса доставки'
            if (this.newItem === 'company' || this.newItem === 'companySimple') return 'Добавление контрагента'
            if (this.newItem === 'individual') return 'Добавление клиента'
            if (this.newItem === 'tire') return 'Создание шины'
            if (this.newItem === 'mixDriver' || this.newItem === 'pumpDriver') return 'Добавление водителя'
            return 'Создание нового элемента'
        },
        createCompanyManager() {
            this.loadingButton = true
            httpService().post(this.api.company.createCompanyManager, {
                companyId: this.newItemData,
                name: this.form.name,
                phone: this.form.phone
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    })
                    this.values = data.items
                    this.itemId = data.manager.id
                    this.dialog = false

                } else {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
            this.loadingButton = false
        },
        hoverAddress(e) {
            if (e.latitude && e.longitude) {
                this.latitude = e.latitude
                this.longitude = e.longitude
            }
        },
        createTire() {
            this.loadingButton = true
            httpService().post(this.api.tires.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                form: this.form,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Шина добавлена',
                        showClose: true,
                        type: 'success'
                    })
                    this.itemId = data.tire.id
                    this.values = data.values.filter(el => !el.use)
                    this.dialog = false
                    this.loadingButton = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        createDriver() {
            let type = 'mix'
            if (this.newItem === 'pumpDriver') type = 'pump'
            // из-за него убрали мидлвеер с пути
            httpService().post(this.api.dispatch.mixers.createDriver, {
                account_modules_id: this.$route.params.id,
                module_key: 'dispatch',
                form: this.form,
                type: type
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.itemId = data.driver.id
                    this.values = data.drivers
                    this.dialog = false
                    this.$message({
                        message: 'Водитель добавлен',
                        showClose: true,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        handleAddAddress({values, addressId}) {
            this.values = values;
            this.itemId = addressId;
            this.dialog = false;
            this.selectAddressDialog = false
            this.companyCreateFormShow = false
        },
        info(info) {
            if (info) {
                this.form.name = info.name.short_with_opf
            }
        },
        clearItemId() {
            this.itemId = null
            this.$emit('clearAction')
        },
        createCompany() {
            this.loadingButton = true
            httpService().post(this.api.company.create, {
                company: this.form
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    })
                    this.values = data.companies
                    this.companiesData = data.companies
                    this.itemId = data.company.id
                    this.dialog = false
                    this.companyCreateFormShow = false
                    this.pullCompanies()
                    this.selectCompanyDialog = false

                } else {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка при создании контрагента',
                    showClose: true,
                    type: 'error'
                })
            })
            this.loadingButton = false
        },
        createIndividual() {
            this.loadingButton = true
            httpService().post(this.api.company.createIndividual, {
                companyId: null,
                name: this.form.name,
                phone: this.form.phone
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Клиент добавлен',
                        showClose: true,
                        type: 'success'
                    })
                    this.itemId = data.company.id
                    this.dialog = false
                    this.pullCompanies()
                } else {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка при создании клиента',
                    showClose: true,
                    type: 'error'
                })
            })
            this.loadingButton = false
        },
        createContract() {
            this.loadingButton = true
            httpService().post(this.api.document.create, {
                details: {
                    id: null,
                    name: this.form.name,
                    company_id: this.carrierCompany,
                    type: this.documentType,
                    date: null,
                    integration_unit_id: null,
                    goods: [],
                }
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Договор добавлен',
                        showClose: true,
                        type: 'success'
                    })
                    this.getContractsData()
                    this.itemId = data.document.id
                    this.companyCreateFormShow = false
                    this.selectContractDialog = false

                } else {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка при создании договора',
                    showClose: true,
                    type: 'error'
                })
            })
            this.loadingButton = false
        },
        createGood() {
            this.loadingButton = true
            httpService().post(this.api.good.create, {
                module: {
                    name: this.form.name,
                    unit: this.form.unit
                }
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    })
                    this.itemId = data.goodId
                    this.dialog = false
                    this.pullGoods()

                } else {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
            this.loadingButton = false
        },
        getIntegrationMixName(integration_unit_id) {
            return this.integrations.find(el => el.id === integration_unit_id)?.account_module.name
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemId = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'itemId': {
            handler: function () {
                this.$emit('update:modelValue', this.itemId)
                if (this.contractId && this.itemId) {
                    let price = this.goodsData.find(el => el.id === this.itemId)?.price
                    if (price) this.$emit('updatePrice', price)
                }

                if (this.newItem === 'companyAddress' && this.itemId) {
                    this.getAddressData()
                }

                if (this.newItem === 'document' && this.itemId) {
                    this.values.sort((a, b) => {
                        if (a.id === this.itemId) return -1;
                        if (b.id === this.itemId) return 1;
                        return 0;
                    });
                }
            },
            deep: true,
            immediate: true
        },
        'carrierCompany': {
            handler: function () {
                if (this.newItem === 'document' && this.carrierCompany) {
                    this.getContractsData()
                }
            },
            deep: true,
            immediate: true
        },
        'items': {
            handler: function () {
                if (this.items) {
                    if (this.newItem === 'tire') {
                        this.values = this.items.filter(el => !el.use)
                    } if (this.newItem === 'companyAddress') {
                        if(this.items.length) {
                            this.values = this.items
                        } else this.getAddressData()
                    } else {
                        this.values = this.items
                    }
                } else if (this.newItem === 'companyAddress') {
                    this.getAddressData()
                }
            },
            deep: true,
            immediate: true
        },
        pageSize() {
            if (this.newItem === 'company') {
                this.getCompaniesData()
            } else if (this.newItem === 'good') {
                this.getGoodsData()
            }
        },
        page() {
            if (this.newItem === 'company') {
                this.getCompaniesData()
            } else if (this.newItem === 'good') {
                this.getGoodsData()
            }
        },
        search() {
            if (this.newItem === 'company') {
                this.getCompaniesData()
            } else if (this.newItem === 'good') {
                this.getGoodsData()
            }
        },
        'newItemData': {
            handler: function () {
                if (this.newItem === 'companyAddress') {
                    this.getAddressData()
                }
            },
            deep: true,
            immediate: true
        },
        'integrationUnitId': {
            handler: function (val) {
                if(val) {
                    this.filters.integrationUnitId = val
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>
.map {
    width: 100%;
    height: 100%;
}
</style>
