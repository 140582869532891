<template>
    <Auth title="Пользовательские документы" :tabs="tabs" :settings="$route.params.id">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disableFilter="true"
                    create-button-label="Создать договор"
                    @moduleGet="moduleGet"
                    @openCreateValueDrawer="handleOpenCreateDialog"
                >
                    <template v-slot:afterSearchAndFilter>
                        <ResetFormButton
                            :isVisible="isResetButtonActive"
                            @resetFilter="resetFilters" />
                    </template>
                </TableHeadBlock>

                <el-table
                    v-loading="loading"
                    :data="values"
                    v-if="!mobile"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="200"
                        prop="name"
                        label="Название"
                        sortable>
                        <template v-slot="scope">
                            <div class="textSM textMedium neutral900 maxLines3">
                                <Truncate :input-text="scope.row.name + (scope.row.doc ? ' - ' + scope.row.doc : '')"/>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="date"
                        label="Тип документа"
                    >
                        <template v-slot="scope">
                            <span class="textSM textRegular neutral400">{{getDocumentType(scope.row.type)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="60">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                @click="remove(scope.row.id)"
                                :icon="icons.delete"
                            />
                        </template>
                    </el-table-column>
                </el-table>
                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>


        <el-dialog
            v-model="createDialog"
            title="Добавить новый пользовательский документ"
            :width="dialogSize"
            :destroy-on-close="true"
        >
            <!-- @createDocument="uploadFile" -->
            <CRMCustomDocumentsCreate
                :details="details"
                :loading="loading"
                :validationMessages='validationMessages'
                @createDocument="uploadFile"
                :types='types'
            />
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import functionsMixin from "@/mixins/functionsMixin";
import iconsMixin from "@/mixins/iconsMixin";
import CRMTabs from "@/mixins/tabs/CRMTabs";
import documentStatusMixin from "@/mixins/documentStatusMixin";
import Truncate from "@/views/components/Truncate.vue";
import { mapState } from "pinia"
import { useCommonStore } from "@/store/common"
import ResetFormButton from '@/views/components/Form/ResetFormButton'
import CRMCustomDocumentsCreate from "@/views/crm/components/CRMCustomDocumentsCreate";


export default {
    name: 'CRMCustomDocuments',
    data() {
        return {
            loading: false,
            detailsDialog: false,
            valueLength: 0,
            details: {
                id: null,
                name: null,
                type: null,
                file: null,
            },
            filtered: [],
            filter: {
                type: {
                    placeholder: 'Тип документа',
                    value: null,
                },
            },
            search: '',
            createDialog: false,
            selectedContractId: null,
            validationMessages: {
                name: null,
                type: null,
                file: null
            },
            types: [{
                'id': 'invoice',
                'name': 'ТН'
            }, {
                'id': 'TTN',
                'name': 'ТТН'
            }, {
                'id': 'passport',
                'name': 'Паспорт качества'
            }, {
                'id': 'other',
                'name': 'Другое'
            }],
        };
    },
    components: {
        Truncate,
        TablePagination,
        TableHeadBlock,
        Auth,
        ResetFormButton,
        CRMCustomDocumentsCreate
    },
    mixins: [
        tableMixin, functionsMixin, iconsMixin, CRMTabs, documentStatusMixin
    ],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet();
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: "getCompanies",
        }),
        isResetButtonActive() {
            return  this.filter.type.value || !!this.search
        },
    },
    methods: {
        moduleGet() {
            this.loading = true
            // здесь что-то вроде this.api.document.getAllCustomDocuments будет, наверное
            // пока просто получение всех доков, чтобы страница не падала)
            httpService().post(this.api.customDocument.index, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                type: 'contract'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.values = data.docs
                    this.valueLength = data.length
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        handleOpenCreateDialog() {
            this.resetDetails();
            this.createDialog = true;
        },
        resetDetails() {
            this.details = {
                id: null,
                name: null,
                type: null,
                file: null
            }
        },
        setPage(val) {
            // переключает страницу пагинации
            this.page = val
        },
        resetFilters() {
            this.filter.type.value = null;
            this.search = '';
        },
        uploadFile() {
            if (this.details.type !== 'other'){
                this.details.name = this.details.type
            }

            //TODO не работала изначальная идея, если вообще не трогать форму и сразу нажать на сохранение
            let val = this.validate()
            if (val.name || val.type || val.file) {
                return
            }

            this.loading = true

            const formData = new FormData();

            formData.append('name', this.details.name);
            formData.append('file', this.details.file);
            formData.append('type', this.details.type);

            // тут поменяй на нужное, можешь заодно глянуть, как реализовано по этому эндпоинту, если с formData не работал:
            // this.api.settings.updateFactory
            httpService().post(this.api.customDocument.uploadCustomDocument, formData).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Информация сохранена',
                        showClose: true,
                        type: 'success'
                    })
                    this.loading = false;
                    this.createDialog = false;
                    this.resetDetails();
                    this.moduleGet()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            }).finally(() => {
                this.loading = false
            })
        },
        remove(id){
            this.$confirm('Вы действительно хотите удалить документ?', 'Подтвердите удаление', {
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                httpService().post(this.api.customDocument.delete, {
                    id: id
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.$message({
                            message: 'Информация сохранена',
                            showClose: true,
                            type: 'success'
                        })
                        this.resetDetails()
                        this.loading = false
                        this.moduleGet()
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                this.loading = false
                })
            })
        },
        getDocumentType(type) {
            return this.types.find((el) => el.id === type).name
        },
        validate() {
            const fields = {
                name: 'Введите название документа',
                type: 'Выберите тип документа',
                file: 'Загрузите документ',
            };

            this.validationMessages = Object.keys(fields).reduce((messages, field) => {
              if (!this.details[field]) {
                messages[field] = fields[field];
              }
              return messages;
            },
            { ...this.validationMessages });
            return this.validationMessages;
        }
    },
    watch: {
        'details.name': {
            handler(val, prevVal) {
                if(val && !prevVal) {
                    this.validationMessages = {
                        ...this.validationMessages,
                        name: null
                    }
                }
            }
        },
        'details.type': {
            handler(val , prevVal) {
                if(val && !prevVal) {
                    this.validationMessages = {
                        ...this.validationMessages,
                        type: null
                    }
                }
            }
        },
        'details.file': {
            handler(val , prevVal) {
                if(val && !prevVal) {
                    this.validationMessages = {
                        ...this.validationMessages,
                        file: null
                    }
                }
            }
        },
        'validationMessages': {
            handler(val) {
                this.isFormValid = (val.name || val.type || val.file);
            }
        },
        pageSize() {
            // this.moduleGet()
        },
        page() {
            // this.moduleGet()
        },
        search() {
            // this.moduleGet()
        },
    }
}
</script>
