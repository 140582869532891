<template>
    <el-form label-position="top">
        <template v-if="step === 1">
            <p>Выберите тип тарифа:</p>

            <div class="d-flex" style="gap: 15px">
                <el-button class="w-100" style="height: auto !important;" @click="selectType(type.id)"
                           v-for="(type, index) in serviceTypes"
                           :disabled="type.id === 'other'"
                           :key="index">
                    <div>
                        <div class="text-center mt-15 mb-15">
                            <svg
                                v-if="type.id === 'pump'"
                                xmlns="http://www.w3.org/2000/svg"
                                width="90" height="90" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
                                class="serviceIconBig"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="21" cy="46" r="3" fill="" opacity="1"></circle><circle
                                cx="50" cy="46" r="3" fill="" opacity="1"></circle><circle cx="11" cy="46" r="3" fill=""
                                                                                           opacity="1"></circle><path
                                d="m25 39 4 6h14" fill="" opacity="1"></path><path
                                d="M47 39V21h6.353a3 3 0 0 1 2.531 1.39l5.647 8.873A3 3 0 0 1 62 32.874V43.5a1.5 1.5 0 0 1-1.5 1.5h-2.669a1 1 0 0 1-.972-.767A7.276 7.276 0 0 0 52 39H8l-4 5H2V33l45-3"
                                fill="" opacity="1"></path><path
                                d="M61.87 32H52a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5.55M62 37h-2M25.27 31.45 5.52 24.14a.969.969 0 0 1-.66-.71l-.76-2.16a.857.857 0 0 1-.05-.27.662.662 0 0 1 .01-.28l.71-3.12a.987.987 0 0 1 .9-.68L50.97 15a1.029 1.029 0 0 1 .85.42 1.054 1.054 0 0 1 .11.96L50.52 21M7 21h40M4.05 21H7"
                                fill="" opacity="1"></path><path
                                d="m8 21 25.466 5.877a1.5 1.5 0 0 1 .863.562l2.431 3.241"
                                fill="" opacity="1"></path></g></g>
                            </svg>
                            <svg
                                v-if="type.id === 'mix'"
                                xmlns="http://www.w3.org/2000/svg"
                                width="90" height="90" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
                                class="serviceIconBig"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="19" cy="46" r="3" fill="" opacity="1"></circle><circle
                                cx="50" cy="46" r="3" fill="" opacity="1"></circle><circle cx="9" cy="46" r="3" fill=""
                                                                                           opacity="1"></circle><path
                                d="m2 44 4-5h46a7.274 7.274 0 0 1 4.859 5.233 1 1 0 0 0 .972.767H60.5a1.5 1.5 0 0 0 1.5-1.5V32.873a3 3 0 0 0-.469-1.61l-5.647-8.874A3 3 0 0 0 53.353 21H47"
                                fill="" opacity="1"></path><path
                                d="m22 39 5 6h16M47 39V17.621a1.5 1.5 0 0 0-.44-1.061L45 15M61.87 32H52a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5.55M62 37h-2M39.1 39l3.7-5.9a1.345 1.345 0 0 0-.125-1.6l-6.734-7.9-14.77-7.7-9.671-.893a1.649 1.649 0 0 0-1.423.571L5.23 23.106a1.426 1.426 0 0 0 .119 1.711l6.319 7.442L26.438 39"
                                fill="" opacity="1"></path><circle cx="44" cy="27" r="3" fill="" opacity="1"></circle><path
                                d="M35.933 23.593 27.493 39M21.163 15.889l-9.495 16.37M8 39V27.94" fill=""
                                opacity="1"></path></g></g>
                        </svg>
                            <svg
                                v-if="type.id === 'downtime'"
                                xmlns="http://www.w3.org/2000/svg"
                                width="90" height="90" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
                                class="serviceIconBig"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="30" cy="38" r="24" fill="" opacity="1"></circle><path
                                d="M24 2h12v5H24zM30 7v7M50.59 16.41l-4.13 4.13M47.181 13 54 19.819" fill=""
                                opacity="1"></path><circle cx="30" cy="38" r="4" fill="" opacity="1"></circle><path
                                d="M30 34V22M30 42v2M44 38h2M16 38h-2M30 52v2" fill="" opacity="1"></path></g></g>
                        </svg>
                            <svg
                                v-if="type.id === 'other'"
                                xmlns="http://www.w3.org/2000/svg"
                                width="90" height="90" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
                                class="serviceIconBig"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M10 21v39a2 2 0 0 0 2 2h40a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H24L10 16h12a2 2 0 0 0 2-2V2M20 51h24M20 39h24M20 27h24M33 15h11" fill="" opacity="1"></path></g></g>
                        </svg>
                        </div>
                        <p class="textSM textMedium primary600">
                            {{ type.name }}
                        </p>
                    </div>
                </el-button>
            </div>
        </template>

        <template v-else-if="step === 2">
            <template v-if="form.type === 'mix'">
                <p>Выберите модель расчета:</p>

                <div class="d-flex mb-15">
                    <el-button @click="selectDepends('distance')">
                        Расстояние
                    </el-button>
                    <el-button :disabled="true">
                        Геозоны
                    </el-button>
                    <el-button @click="selectDepends('other')">
                        Ручной
                    </el-button>
                </div>
            </template>
            <template v-else-if="form.type === 'pump'">
                <p>Выберите модель расчета:</p>

                <div class="d-flex mb-15">
                    <el-button @click="selectDepends('time')" :disabled="true">
                        По времени
                    </el-button>
                    <el-button @click="selectDepends('other')">
                        Ручной
                    </el-button>
                </div>
            </template>
            <template v-else-if="form.type === 'downtime'">
                <p>Выберите модель расчета:</p>

                <div class="d-flex mb-15">
                    <el-button @click="selectDepends('time')">
                        По времени
                    </el-button>
                    <el-button @click="selectDepends('other')">
                        Ручной
                    </el-button>
                </div>
            </template>
        </template>

        <template v-else-if="step === 3">
            <div v-if="form.depends_on === 'distance' || form.depends_on === 'other'" class="serviceTariffCreate__price-settings">
                <p class="serviceTariffCreate__price-settings-title textMD textMedium neutral500 m-0">Настройка стоимости:</p>
                <div class="serviceTariffCreate__price-settings-radios d-flex">
                    <el-radio-group v-model="mix_setting_type" class="serviceTariffCreate__price-settings-radio-group">
                        <el-radio value="group">По заказам</el-radio>
                        <el-radio value="volume">По рейсу</el-radio>
                    </el-radio-group>
                    <div v-if="mix_setting_type === 'volume'" class="d-flex align-center gap-12">
                        <p class="serviceTariffCreate__price-settings-radio-group-title textSM textMedium neutral400 m-0">По объему:</p>
                        <el-radio-group v-model="form.mix_volume_type" class="serviceTariffCreate__price-settings-radio-group">
                            <el-radio value="order">Продукции</el-radio>
                            <el-radio value="vehicle">Миксера</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div v-if="mixVolumeTypeCaption" class="serviceTariffCreate__price-settings-caption d-flex align-center gap-8 m-0">
                    <WarningIcon width="16px" height="16px" />
                    <p class="textSM textMedium neutral400 m-0">{{ mixVolumeTypeCaption }}</p>
                </div>
                <hr/>
            </div>
            <template v-if="form.type === 'mix' && form.depends_on === 'distance'">
                <p>
                    Вы добавляете тариф-автоматизацию, основанную на расстоянии. Например, вы можете добавить тарифы от
                    5 до 10 км от бетонного завода, с которого будет идти отгрузка. Данный тип тарифа будет
                    автоматически подставляться в услуги доставки, в зависимости от фактического расстояния
                </p>

                <FormInput
                    v-model="form.name"
                    label="Название"
                    placeholder="Введите название"
                />

                <hr/>

                <ServiceTariffMixDistanceForm
                    v-model="form"
                />

            </template>
            <template v-if="form.type === 'mix' && form.depends_on === 'other'">
                <p>
                    Вы добавляете ручной тариф, он не будет автоматически подставляться в услуги по доставке бетона, но
                    вы сможете его выбрать при создании заказа и подтверждении отгрузки
                </p>

                <FormInput
                    v-model="form.name"
                    label="Название"
                    placeholder="Введите название"
                />

                <ServiceTariffMixOtherForm
                    v-model="form"
                />
            </template>
            <template v-if="form.type === 'downtime' && form.depends_on === 'time'">
                <p>
                    Вы добавляете тариф-автоматизацию, основанную на времени. Например, вы можете добавить бесплатное
                    время до 40 минут для 8 кубового миксера, а свыше лимита требовать оплату за простой
                </p>

                <FormInput
                    v-model="form.name"
                    label="Название"
                    placeholder="Введите название"
                />

                <ServiceTariffDowntimeTimeForm
                    v-model="form"
                />
            </template>
            <template v-if="form.type === 'pump' && form.depends_on === 'other'">
                <p>
                    Вы добавляете ручной тариф, он не будет автоматически подставляться в услуги по простою
                    автобетоносмесителя на объекте, но
                    вы сможете его выбрать на странице заказа
                </p>

                <FormInput
                    v-model="form.name"
                    label="Название"
                    placeholder="Введите название"
                />

                <ServiceTariffPumpOtherForm
                    v-model="form"
                />
            </template>
            <template v-if="form.type === 'downtime' && form.depends_on === 'other'">
                <p>
                    Вы добавляете ручной тариф, он не будет автоматически подставляться в услуги по простою
                    автобетоносмесителя на объекте, но
                    вы сможете его выбрать на странице заказа
                </p>

                <FormInput
                    v-model="form.name"
                    label="Название"
                    placeholder="Введите название"
                />

                <ServiceTariffDowntimeOtherForm
                    v-model="form"
                />
            </template>

            <el-form-item label="" class="mt-10 mb-0">
                <el-checkbox v-model="form.default" label="Использовать по умолчанию" size="large"/>
            </el-form-item>
        </template>
        <hr class="mt-15 mb-15"/>
        <el-button
            :disabled="!form.type || !form.depends_on || !form.name"
            @click="create()"
            :loading="loadingButton"
            type="primary"
            size="large">Сохранить</el-button>
    </el-form>
</template>

<script>
import {httpService} from "@/services/http.service";
import FormInput from "@/views/components/Form/FormInput.vue";
import serviceInfo from "@/mixins/serviceInfo";
import iconsMixin from "@/mixins/iconsMixin";
import ServiceTariffMixOtherForm from "@/views/settings/serviceTariffs/components/ServiceTariffMixOtherForm.vue";
import ServiceTariffMixDistanceForm from "@/views/settings/serviceTariffs/components/ServiceTariffMixDistanceForm.vue";
import ServiceTariffPumpOtherForm from "@/views/settings/serviceTariffs/components/ServiceTariffPumpOtherForm.vue";
import ServiceTariffDowntimeOtherForm
    from "@/views/settings/serviceTariffs/components/ServiceTariffDowntimeOtherForm.vue";
import ServiceTariffDowntimeTimeForm
    from "@/views/settings/serviceTariffs/components/ServiceTariffDowntimeTimeForm.vue";
import { Warning } from "@element-plus/icons-vue";

export default {
    name: "ServiceTariffCreateDialog",
    components: {
        ServiceTariffDowntimeTimeForm,
        ServiceTariffDowntimeOtherForm,
        ServiceTariffPumpOtherForm,
        ServiceTariffMixDistanceForm,
        ServiceTariffMixOtherForm, FormInput,
        WarningIcon: Warning,
    },
    mixins: [serviceInfo, iconsMixin],
    inject: ['api'],
    props: ['defaultType'],
    data() {
        return {
            loadingButton: false,
            step: this.defaultType ? 2 : 1,
            mix_setting_type: 'group',
            form: {
                id: null,
                name: 'Доставка бетона',
                type: this.defaultType ?? null,
                depends_on: null,
                vat: 0,
                vat_in_price: false,
                price: null,
                full_price: null,
                price_type: null,
                volume_min: null,
                volume_max: null,
                distance_from: null,
                distance_to: null,
                childs: [{
                    id: null,
                    name: null,
                    vat: 0,
                    vat_in_price: false,
                    price: null,
                    full_price: null,
                    price_type: null,
                    volume_min: null,
                    volume_max: null,
                    minutes_min: null,
                    minutes_max: null,
                    distance_from: null,
                    distance_to: null,
                }],
                default: false,
                mix_volume_type: 'group',
            },
        }
    },
    methods: {
        addChild() {
            this.form.childs.push({
                id: null,
                name: null,
                vat: 0,
                vat_in_price: false,
                price: null,
                full_price: null,
                price_type: null,
                volume_min: null,
                volume_max: null,
                distance_from: null,
                distance_to: null,
                default: false
            })
        },
        removeChild(index) {
            this.form.childs.splice(index, 1);
        },
        updateChild(index, value) {
            this.$set(this.form.childs, index, value);
        },
        selectType(type) {
            this.form.type = type
            this.step++
        },
        selectDepends(type) {
            this.form.depends_on = type
            if (this.form.type === 'mix' && type === 'distance') this.form.name = 'Доставка бетона - по расстоянию'
            if (this.form.type === 'pump') {
                this.form.name = 'Услуги бетононасоса'
                if (type === 'time') this.form.name = 'Услуги бетононасоса по времени'
            }
            if (this.form.type === 'downtime') {
                this.form.name = 'Простой на объекте'
                if (type === 'time') this.form.name = 'Простой на объекте по времени'
            }
            this.step++
        },
        create() {
            this.loadingButton = true
            httpService().post(this.api.serviceTariff.create, {
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Информация сохранена',
                        showClose: true,
                        type: 'success'
                    })
                    this.$emit('create', data.tariff)
                    this.detailsDialog = false
                    this.form = {
                        id: null,
                        name: null
                    }
                    this.loadingButton = false
                    this.$emit('update')
                    this.$emit('close')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
    },
    computed: {
        mixVolumeTypeCaption() {
            const captions = {
                group: 'Расчет происходит по общему объему заказа.',
                order: 'Расчет происходит по объему каждой отгрузки и зависит от её объёма продукции. Пример: если 12-и кубовый миксер повезёт 8 кубов - стоимость будет за 8 кубов продукции.',
                vehicle: 'Расчет происходит по объему миксера каждой отгрузки. Пример: если 12-и кубовый миксер повезёт 8 кубов - стоимость будет за 12 кубовый миксер.',
            }
            return captions[this.form.mix_volume_type]
        }
    },
    watch: {
        'form': {
            handler: function () {
                if (this.form.price) {
                    let vat = this.form.vat ? Math.abs(this.form.vat) : 0
                    let vat_in_price = this.form.vat_in_price ?? false
                    let price = Math.abs(this.form.price)
                    let full_price = price

                    if (vat === 0) {
                        full_price = price; // Если налог равен 0, то итоговая стоимость равна цене
                    }

                    if (vat_in_price) {
                        full_price = price; // Если налог включен в стоимость, то итоговая стоимость равна цене
                    } else {
                        full_price = price + (price * (vat / 100)); // Если налог не включен, то добавляем налог к цене
                    }

                    this.form.full_price = full_price
                }
            },
            deep: true,
            immediate: false
        },
        'mix_setting_type': {
            handler: function () {
                if (this.mix_setting_type === 'group') {
                    this.form.mix_volume_type = 'group'
                } else this.form.mix_volume_type = 'order'
            },
            deep: true,
            immediate: false
        }
    }
}
</script>

<style scoped lang="scss">
.child {
    border: 1px solid #c9ccd0;
    border-radius: 12px;
    margin-bottom: 10px;
}

.deleteButton {
    position: absolute;
    right: 25px;
    top: 10px;
    height: 28px !important;
    width: 28px !important;
    z-index: 999999999;
}

.serviceTariffCreate__price-settings {
    padding: 0;

    &-title {
        margin-bottom: 4px;
    }
    &-radios {
        gap: 48px;
    }
    &-radio-group {
            &-title {
                padding-right: 24px;
                height: fit-content;
            }

            .el-radio {
                margin-right: 20px;
            }
    }
    &-caption {
        margin-block: 4px;
        min-height: 40px;
    }
}
</style>
