<template>
    <div v-if="isVisible" @click="$emit('closeOrder')" class="mix-driver-queue-modal" fullscreen>
        <div class="mix-driver-queue-modal__header textMD">
            <ArrowLeft/>
            <span class="textMD text-500 color-primary">Очередность</span>
        </div>
        <article class="mix-driver-queue-modal__body">
            <div class="mix-driver-queue-modal__body-header">
                <p class="mix-driver-queue-modal__body-header-item">#</p>
                <p class="mix-driver-queue-modal__body-header-item flex-1">
                    Очередь на загрузку
                    <span v-if="currentOrder?.mix_name || currentOrder?.mixer_name">
                        <span>{{ currentOrder.mix_name }}</span>
                        <template v-if="currentOrder.mixer_name">
                            <span v-if="currentOrder.mixer_name"> - </span>
                            <span>{{ currentOrder.mixer_name }}</span>
                        </template>
                    </span>
                </p>
                <p class="mix-driver-queue-modal__body-header-item">Начало загрузки</p>
            </div>
            <ul class="mix-driver-queue-modal__body-list">
                <li class="mix-driver-queue-modal__body-item" v-for="(order, index) in queue" :key="index">
                    <p class="mix-driver-queue-modal__body-item-content">{{ index + 1 }}</p>
                    <div class="mix-driver-queue-modal__body-item-vehicle">
                        <p>{{ getVehicleNumber(order.vehicle) }}</p>
                        <Label v-if="index === 0" :text="currentStatus(order.status)" color="blue"/>
                    </div>
                    <p>{{ getTime(order.start_at) }}</p>
                </li>

                <li class="mix-driver-queue-modal__body-item" :key="queue.length">
                    <p class="mix-driver-queue-modal__body-item-content">{{ queue.length + 1 }}</p>
                    <div class="mix-driver-queue-modal__body-item-vehicle">
                        <p>{{ getVehicleNumber(currentOrder?.vehicle_number) }}</p>
                        <Label text="Это вы" color="green"/>
                    </div>
                    <p>{{ getTime(currentOrder.start_at) }}</p>
                </li>
            </ul>
        </article>
    </div>
</template>

<script>
import moment from 'moment';
import functionsMixin from "../../../../mixins/functionsMixin";
import ArrowLeft from "@/views/components/Icons/ArrowLeft.vue";
import Label from "@/views/components/Label/Label.vue";

export default {
    name: "MixDriverTaskDetailedQueueModal",
    props: ['isVisible', 'queue', 'currentOrder'],
    emits: ['closeOrder'],
    components: {
        Label,
        ArrowLeft,
    },
    mixins: [functionsMixin],

    methods: {
        getTime(time) {
            return moment.parseZone(time).format('HH:mm')
        },
        currentStatus(status) {
            switch (status) {
                case 'new':
                    return 'Ожидает загрузки'
                case 'loading':
                    return 'Идет загрузка'
                default:
                    return ''
            }
        },
    }
};
</script>
