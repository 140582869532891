export default {
    data() {
        return {
            pumpPaymentTypes: new Map([
                ['by-hour', 'За час'],
                ['by-delivery', 'За отгрузку'],
            ])
        }
    }
}
