<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
        <path
            d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"
        ></path>
        <path
            d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "CloseRoundIcon",
    props: ["color"],
};
</script>

<style scoped></style>
