<template>
    <Auth :title="moduleName">
        <div class="card">
            <div class="card-title">
                <div class="buttonsBlock">
                    <el-button @click="moduleGet($route.params.id)"
                               type="success" icon="el-icon-refresh" :loading="loadingButton" class="mr-10" size="mini">
                        Обновить значения
                    </el-button>
                    <el-button @click="$router.push({name: 'orderCreate', params: { id: $route.params.id },})"
                               type="primary" icon="el-icon-plus" size="mini">Создать новый
                    </el-button>
                </div>
            </div>
            <div class="card-body">
                <el-table
                    v-loading="loading"
                    ref="table"
                    :data="orders"
                    style="width: 100%">
                    <el-table-column
                        prop="date"
                        label="Дата"
                        :formatter="setDate"
                        width="95">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="buyer.name"
                        label="Клиент">
                    </el-table-column>
                    <el-table-column
                        prop="buyer.email"
                        label="E-mail">
                    </el-table-column>
                    <el-table-column
                        prop="buyer.phone"
                        label="Телефон">
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        label="Сумма">
                    </el-table-column>
                    <el-table-column
                        prop="status.name"
                        label="Статус">
                    </el-table-column>
                    <el-table-column
                        prop="payment.name"
                        label="Способ оплаты">
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="120">
                        <template v-slot="scope">
                            <el-button
                                size="mini"
                                @click="toogleExpand(scope.row)">Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column type="expand" width="1">
                        <template v-slot="props">

                            <h2>{{ props.row.name }}</h2>
                            <p>Дата заказа: {{ getDate(props.row.date, 'DD.MM в HH:mm') }}</p>
                            <p>Статус: {{ props.row.status.name }}</p>

                            <el-table
                                :data="props.row.goods"
                                class="mb-10"
                                style="width: 100%">
                                <el-table-column
                                    prop="good.name"
                                    label="Товар"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="price"
                                    label="Цена (за ед.)"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="count"
                                    label="Количество"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="price"
                                    label="Итого"
                                >
                                    <template v-slot="scope">
                                        {{ scope.row.price * scope.row.count }}
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-row :gutter="20">
                                <el-col :md="18">

                                    <p><span class="text-bold">Покупатель:</span> {{ props.row.buyer.name }} /
                                        {{ props.row.buyer.email }} / {{ props.row.buyer.phone }}</p>
                                    <p><span class="text-bold">Метод доставки:</span>
                                        {{ props.row.delivery ? props.row.delivery.name : '-' }}</p>
                                    <p><span class="text-bold">Адрес доставки:</span>
                                        {{ props.row.delivery_address ? props.row.delivery_address : '-' }}</p>
                                    <p><span class="text-bold">Комментарий к заказу:</span>
                                        {{ props.row.comment ? props.row.comment : '-' }}</p>

                                </el-col>
                                <el-col :md="5">

                                    <p class="order-total">
                                        <span class="text-bold">Товары:</span>
                                        <span>{{ props.row.total_goods }}</span>
                                    </p>
                                    <p class="order-total">
                                        <span class="text-bold">Доставка:</span>
                                        <span>{{ props.row.delivery_price }}</span>
                                    </p>
                                    <p class="order-total">
                                        <span class="text-bold">Итого:</span>
                                        <span>{{ props.row.total }}</span>
                                    </p>

                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import moment from 'moment'
import { httpService } from '@/services/http.service'

export default {
    name: 'ProductionIndex',
    components: {
        Auth
    },
    data() {
        return {
            module: null,
            moduleName: null,
            loading: true,
            loadingButton: true,
            loadingTitle: true,
            orders: [],
        }
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.mixOrder.getOne, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'order'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.loadingTitle = false
                this.module = data.module
                this.moduleName = data.module.name
                this.orders = data.module.orders
                this.goods = data.goods
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        },
        setDate(row, column) {
            const newdata = row[column.property]
            if (newdata === undefined) {
                return ''
            }
            return moment.unix(newdata).format('DD.MM HH:mm')
        },
        toogleExpand(row) {
            let $table = this.$refs.table
            $table.toggleRowExpansion(row)
            this.unitId = row.id
        },
        getDate: (timestamp, format) => {
            return moment.unix(timestamp).format(format)
        },
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) this.moduleGet()
        }
    }
}
</script>

<style scoped>

</style>
