<template>
    <div>
        <template v-if="loadingData">
            <TableDataLoader text="продукции" />
        </template>
        <template v-else>
            <TableHeadBlock v-model="search"
                            :loading="loading"
                            :disable-filter="true"
                            :disable-update-button="true"
                            :disable-create-button="true"
                            create-button-label="Добавить продукцию"
                            @moduleGet="getData()"
                        >
                <template v-slot:afterSearchAndFilter>
                <TableIntegrationFilter v-model="filters.integrationUnitId"
                                        @moduleGet="getData()"
                                        :table="true" />
                </template>
            </TableHeadBlock>
            <el-table empty-text="Информация не найдена"
                      class="mt-15 w-100 defaultTableHeight"
                      v-loading="loadingTable"
                      @current-change="handleCurrentChange"
                      :data="displayData"
                      >
                <el-table-column prop="id"
                                 label="#"
                                 width="100"
                                 class-name="pointer"
                                 sortable>
                    <template v-slot="scope">
                        <span class="textSM textMedium"
                              :class="[{ neutral200: scope.row.id !== itemId }, { primary600: scope.row.id === itemId }]">
                            #{{ formatNumber(scope.row.id) }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="name"
                                 label="Название"
                                 class-name="pointer"
                                 sortable>
                    <template v-slot="scope">
                        <span class="textSM textMedium primary600 cutOverflow">{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="integration"
                                 label="Интеграция"
                                 class-name="pointer"
                                 width="220px">
                    <template v-slot="scope">
                        <template v-if="scope.row.integration_unit_id">
                            <IntegrationLogo :integration-unit-id="scope.row.integration_unit_id" />
                        </template>
                        <template v-else>
                            <span class="textRegular textSM neutral200">-</span>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
            <TablePagination v-model="pageSize"
                             :total="valueLength"
                             :loading="loading"
                             @action="setPage" />
        </template>
    </div>
</template>

<script>
import { httpService } from "@/services/http.service";

import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'SelectGoodModal',
    props: [
        'contractId', // id контракта
        'addedGoods', // список добавленных товаров, которые не должны дублироваться в списке
        'modelValue', // id выбранного товара
        'goods' // список продукции, которая должна быть выведена в таблицу
    ],
    components: {
        TableDataLoader, TableHeadBlock, TableIntegrationFilter, TablePagination, IntegrationLogo
    },
    mixins: [tableMixin, functionsMixin],
    inject: ['api'],
    data() {
        return {
            loadingData: false,
            search: '',
            filters: {
                goodType: 'good',
                integrationUnitId: ''
            },
            loading: false,
            loadingTable: false,
            pageSize: 10,
            itemId: null,
        };
    },
    methods: {
        getData() {
            this.loadingTable = true
            httpService().post(this.api.good.get, {
                contractId: this.contractId,
                paginate: false,
                search: this.search,
                filter: this.filters,
                goodIds: this.goodIds,
                allData: true
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loadingData = false
                    this.loadingTable = false
                    if(!this.addedGoods.length) {
                        this.values = data.goods
                        this.valueLength = data.goods.length;
                        return
                    }

                    // исключаем выбранные варинты
                    const filtered = data.goods.filter(item =>
                        !this.addedGoods.some(good => item.id === good.good_id)
                    );

                    this.values = filtered
                    this.valueLength = filtered.length
                }
            })
        },
        handleCurrentChange(val) {
            this.itemId = val.id;
            this.$emit('select', val)
        },
        filterValues() {
            this.filtered = this?.values;
            if (this.search) {
                this.filtered = this.filtered?.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            }

            this.valueLength = this.filtered?.length
            return this.filtered?.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
    },
    watch: {
        modelValue: {
            handler() {
                this.companyCreateFormShow = this.modelValue
            }
        },
    },
    mounted() {
        this.getData()
    }
};
</script>
